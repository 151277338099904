<template>
	<div class="contextual-navigation">
		<div class="css-4rxc6l">
			<div tabindex="-1" role="group" class="css-kq308b">
				<div class="css-79elbk" v-if="appModel.titleLeftMenu.title != ''">
					<div style="box-sizing: border-box; padding: 20px 16px;">
						<div role="presentation" class="css-7xjigh">
							<a :href="appModel.titleLeftMenu.href" :target="btn2item.target_blank ? '_blank' : '_self'" rel="nofollow noopener noreferrer" class="navigation-item-head" :class="{'active': !appModel.titleLeftMenu.href}" :content="appModel.titleLeftMenu.tooltip" v-tippy="{placement: 'bottom'}" v-if="appModel.titleLeftMenu.link_type == 'a'">
								<div class="css-1y6c4wb">
									<div style="display: inline-block; position: relative; outline: 0px; height: 44px; width: 44px;">
										<span class="kISwOO">
											<span role="img" style="background-color: transparent; background-position: center center; background-repeat: no-repeat; background-size: cover; border-radius: 3px; display: flex; flex: 1 1 100%; height: 100%; width: 100%;" :style="{backgroundImage: 'url(\''+(appModel.titleLeftMenu.img||'@/assets/img/proj-icon.png')+'\')'}"></span>
										</span>
										<span style="position: absolute; height: 15px; right: 0px; top: 0px; width: 15px;" v-if="appModel.titleLeftMenu.supicon.icon != ''">
											<span class="eFkidq">
												<span class="jNZogQ">
													<i :class="[appModel.titleLeftMenu.supicon.icon]" v-if="appModel.titleLeftMenu.supicon.type == 'icon'"></i>
													<span v-html="appModel.titleLeftMenu.supicon.icon" v-if="appModel.titleLeftMenu.supicon.type == 'svg'"></span>
													<img :src="appModel.titleLeftMenu.supicon.icon" style="width: 100%; height: 100%;" v-if="appModel.titleLeftMenu.supicon.type == 'img'" />
												</span>
											</span>
										</span>
									</div>
								</div>
								<div class="css-t44v0r">
									<div class="css-s80vt4" v-html="appModel.titleLeftMenu.title"></div>
								</div>
							</a>
							<router-link :to="appModel.titleLeftMenu.href" class="navigation-item-head" :class="{'active': !appModel.titleLeftMenu.href}" :content="appModel.titleLeftMenu.tooltip" v-tippy="{placement: 'right'}" v-if="appModel.titleLeftMenu.link_type == 'router-link'">
								<div class="css-1y6c4wb">
									<div style="display: inline-block; position: relative; outline: 0px; height: 44px; width: 44px;">
										<span class="kISwOO">
											<span role="img" style="background-color: transparent; background-position: center center; background-repeat: no-repeat; background-size: cover; border-radius: 3px; display: flex; flex: 1 1 100%; height: 100%; width: 100%;" :style="{backgroundImage: 'url(\''+(appModel.titleLeftMenu.img||'@/assets/img/proj-icon.png')+'\')'}"></span>
										</span>
										<span style="position: absolute; height: 15px; right: 0px; top: 0px; width: 15px;" v-if="appModel.titleLeftMenu.supicon.icon != ''">
											<span class="eFkidq">
												<span class="jNZogQ">
													<i :class="[appModel.titleLeftMenu.supicon.icon]" v-if="appModel.titleLeftMenu.supicon.type == 'icon'"></i>
													<span v-html="appModel.titleLeftMenu.supicon.icon" v-if="appModel.titleLeftMenu.supicon.type == 'svg'"></span>
													<img :src="appModel.titleLeftMenu.supicon.icon" style="width: 100%; height: 100%;" v-if="appModel.titleLeftMenu.supicon.type == 'img'" />
												</span>
											</span>
										</span>
									</div>
								</div>
								<div class="css-t44v0r">
									<div class="css-s80vt4" v-html="appModel.titleLeftMenu.title"></div>
								</div>
							</router-link>
							<div @click="appModel.titleLeftMenu.onclick" class="navigation-item-head" :class="{'active': !appModel.titleLeftMenu.href}" :content="appModel.titleLeftMenu.tooltip" v-tippy="{placement: 'right'}" v-if="appModel.titleLeftMenu.link_type == 'div'">
								<div class="css-1y6c4wb">
									<div style="display: inline-block; position: relative; outline: 0px; height: 44px; width: 44px;">
										<span class="kISwOO">
											<span role="img" style="background-color: transparent; background-position: center center; background-repeat: no-repeat; background-size: cover; border-radius: 3px; display: flex; flex: 1 1 100%; height: 100%; width: 100%;" :style="{backgroundImage: 'url(\''+(appModel.titleLeftMenu.img||'@/assets/img/proj-icon.png')+'\')'}"></span>
										</span>
										<span style="position: absolute; height: 15px; right: 0px; top: 0px; width: 15px;" v-if="appModel.titleLeftMenu.supicon.icon != ''">
											<span class="eFkidq">
												<span class="jNZogQ">
													<i :class="[appModel.titleLeftMenu.supicon.icon]" v-if="appModel.titleLeftMenu.supicon.type == 'icon'"></i>
													<span v-html="appModel.titleLeftMenu.supicon.icon" v-if="appModel.titleLeftMenu.supicon.type == 'svg'"></span>
													<img :src="appModel.titleLeftMenu.supicon.icon" style="width: 100%; height: 100%;" v-if="appModel.titleLeftMenu.supicon.type == 'img'" />
												</span>
											</span>
										</span>
									</div>
								</div>
								<div class="css-t44v0r">
									<div class="css-s80vt4" v-html="appModel.titleLeftMenu.title"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="css-424011">
					<div class="css-xdwmn0">
						<div class="css-1hpn4bw">
							<div class="css-1tw9lwf" :style="{paddingTop: appModel.titleLeftMenu.title == '' ? '12px' : null}">
								<div v-for="item in visibleItems(appModel.leftMenu)">
									<div class="item-group-title" aria-hidden="true" v-if="item.form == 'title'">
										<div class="item-group-title-text">
											<div class="navigation-item-group-header">
												<div class="navigation-item-group-title" v-html="item.title"></div>
											</div>
										</div>
									</div>
									<div role="presentation" class="css-7xjigh" v-else>
										<router-link custom :to="item.href" v-slot="{href, route, navigate, isActive, isExactActive}">
											<a :href="item.href" class="navigation-item" :class="[item.class, {'active': isExactActive || item.selected}]" :target="item.target_blank ? '_blank' : '_self'" rel="nofollow noopener noreferrer" :content="item.tooltip" v-tippy="{placement: 'right'}" v-if="item.link_type == 'a'">
												<div class="css-1wgdpuu" v-if="item.icon && item.icon.icon != ''">
													<span class="cNAwiw">
														<i :class="[item.icon.icon, item.icon.class]" v-if="item.icon.type == 'icon'"></i>
														<span v-html="item.icon.icon" :class="item.icon.class" v-if="item.icon.type == 'svg'"></span>
														<img :src="item.icon.icon" :class="item.icon.class" style="width: 100%; height: 100%;" v-if="item.icon.type == 'img'" />
													</span>
												</div>
												<div class="css-t44v0r">
													<div class="css-xuepxh" v-html="item.title"></div>
												</div>
												<div class="css-9gcqfy" v-if="item.icon2.icon != ''">
													<i :class="[item.icon2.icon, item.icon2.class]" v-if="item.icon2.type == 'icon'"></i>
													<span v-html="item.icon2.icon" :class="item.icon2.class" v-if="item.icon2.type == 'svg'"></span>
													<img :src="item.icon2.icon" :class="item.icon2.class" style="width: 100%; height: 100%;" v-if="item.icon2.type == 'img'" />
												</div>
											</a>
											<a :href="href" @click="navigate" class="navigation-item" :class="[item.class, {'active': isExactActive || item.selected}]" :content="item.tooltip" v-tippy="{placement: 'right'}" v-if="item.link_type == 'router-link'">
												<div class="css-1wgdpuu" v-if="item.icon && item.icon.icon != ''">
													<span class="cNAwiw">
														<i :class="[item.icon.icon, item.icon.class]" v-if="item.icon.type == 'icon'"></i>
														<span v-html="item.icon.icon" :class="item.icon.class" v-if="item.icon.type == 'svg'"></span>
														<img :src="item.icon.icon" :class="item.icon.class" style="width: 100%; height: 100%;" v-if="item.icon.type == 'img'" />
													</span>
												</div>
												<div class="css-t44v0r">
													<div class="css-xuepxh" v-html="item.title"></div>
												</div>
												<div class="css-9gcqfy" v-if="item.icon2.icon != ''">
													<i :class="[item.icon2.icon, item.icon2.class]" v-if="item.icon2.type == 'icon'"></i>
													<span v-html="item.icon2.icon" :class="item.icon2.class" v-if="item.icon2.type == 'svg'"></span>
													<img :src="item.icon2.icon" :class="item.icon2.class" style="width: 100%; height: 100%;" v-if="item.icon2.type == 'img'" />
												</div>
											</a>
											<div @click="item.onclick" class="navigation-item" :class="[item.class, {'active': isExactActive || item.selected}]" :content="item.tooltip" v-tippy="{placement: 'right'}" v-if="item.link_type == 'div'">
												<div class="css-1wgdpuu" v-if="item.icon && item.icon.icon != ''">
													<span class="cNAwiw">
														<i :class="[item.icon.icon, item.icon.class]" v-if="item.icon.type == 'icon'"></i>
														<span v-html="item.icon.icon" :class="item.icon.class" v-if="item.icon.type == 'svg'"></span>
														<img :src="item.icon.icon" :class="item.icon.class" style="width: 100%; height: 100%;" v-if="item.icon.type == 'img'" />
													</span>
												</div>
												<div class="css-t44v0r">
													<div class="css-xuepxh" v-html="item.title"></div>
												</div>
												<div class="css-9gcqfy" v-if="item.icon2.icon != ''">
													<i :class="[item.icon2.icon, item.icon2.class]" v-if="item.icon2.type == 'icon'"></i>
													<span v-html="item.icon2.icon" :class="item.icon2.class" v-if="item.icon2.type == 'svg'"></span>
													<img :src="item.icon2.icon" :class="item.icon2.class" style="width: 100%; height: 100%;" v-if="item.icon2.type == 'img'" />
												</div>
											</div>
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="css-79elbk" v-if="appModel.footerLeftMenu.enable">
					<!--
					<div style="box-sizing: border-box;">
						<div style="padding: 10px 16px;">
							<div class="progress m-0" style="height: 10px;" data-bs-toggle="popover" data-bs-container="body" data-bs-html="true" data-bs-placement="top" data-bs-content="Занято: 500 MB (1%)<br/>Свободно: 0.5 GB (50%) <br/>Размер хранилища: 1 GB" data-bs-trigger="hover">
								<div class="progress-bar progress-bar-info" style="width: 50%"></div>
							</div>
							
							<div class="advert-leftmenu-footer text-center mt-2">
								<a href="//adv.bylex.su" target="_blank" rel="nofollow noopener noreferrer" class="advert-close" content="Отключить рекламу" v-tippy="{placement: 'top'}">
									<i class="fa fa-times fa-sm"></i>
								</a>
								<a href="//adv.bylex.su" target="_blank" rel="nofollow noopener noreferrer">
									<img src="//cms.platform.bsrv.su/assets/plugins/images/cabinet/advert-order/advert-order-208x50-ru.jpg" class="advert-img" />
								</a>
							</div>
						</div>
					</div>
					-->
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {
		visibleItems(arr){
			return arr.length > 0 ? arr.filter(i => i.visible) : [];
		},
	},
	mounted(){
		/*$('.anchor-lnk').click(function(){
			var anchor_num = $(this).attr('data-num');
			var destination = $('.anchor-'+anchor_num).offset().top;
			$('html').animate({scrollTop: destination}, 500);
			return false;
		});*/
	},
}
</script>
<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal fade show" content-class="modal-dialog" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onDelete(close)">
			<div class="modal-header bg-danger text-white py-2">
				<h1 class="modal-title fs-5">Удаление дополнительного поля для задач</h1>
				<button type="button" class="btn-close" @click="$emit('cancel', close)" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="alert alert-info">{{name}}</div>
				<div>Вы действительно хотите безвозвратно удалить данную фирму-производителя?</div>
				<div class="m-t"><span style="color: #900;">Внимание!</span> При удалении данной фирмы-производителя, в ремонтах, где была выбрана эта фирма-производитель, будет установлено значение фирмы-производителя: "Фирма-производитель не выбрана".</div>
				<div class="alert alert-warning alert-dismissible fade show mt-2 mb-0" v-if="error">
					{{error}}
					<button type="button" class="btn-close" aria-label="Close" @click="error = ''"></button>
				</div>
			</div>
			<div class="modal-footer p-1">
				<button type="submit" class="btn btn-danger" :disabled="loading">Удалить</button>
				<button type="button" class="btn btn-default" @click="$emit('cancel', close)">Отмена</button>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		name: '',
		
		error: '',
		loading: false,
	}),
	methods: {
		async onDelete(close){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doDeleteTasksCustomField(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.tasksCustomField.id);
			if(ret.success){
				this.$emit('delete', close);
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
	},
	mounted(){
		this.name = this.$vfm.modals[0].params._value.tasksCustomField.name;
	},
}
</script>
<template>
	<div>
		Настройки...
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Настройки | Tasker'});
	},
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){
		window.scrollTo(0, 0);
		
		this.appModel.doSetLeftMenu1(this.$route.params.virtualspace_id);
		
		this.appModel.enableBreadcrumbs = true;
		this.appModel.breadcrumbs = [
			{
				title: 'Tasker',
				tooltip: '',
				link_type: 'router-link', // router-link, a, span
				onclick: () => {},
				href: {path: '/'},
				icon: '',
			},
			{
				title: this.authModel.getVirtualSpace(this.$route.params.virtualspace_id).virtualspace_name,
				tooltip: '',
				link_type: 'router-link', // router-link, a, span
				onclick: () => {},
				href: {path: '/vs/'+this.$route.params.virtualspace_id},
				icon: '',
			},
			{
				title: 'Настройки',
				tooltip: '',
				link_type: 'router-link', // router-link, a, span
				onclick: () => {},
				href: {path: '/vs/'+this.$route.params.virtualspace_id+'/settings'},
				icon: '',
			},
		];
		
		this.appModel.titleCabinet.icon.type = '';
		this.appModel.titleCabinet.icon.icon = '';
		this.appModel.titleCabinet.title = 'Настройки';
		this.appModel.titleCabinet.buttons = [];
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {},
}
</script>
<template>
	<div class="row">
		<div class="col-md-6 offset-md-3">
			<div class="list-group">
				<router-link v-for="vs in authModel.getVirtualSpaces()" :to="{path: '/vs/'+vs.virtualspace_id}" class="list-group-item list-group-item-action">{{vs.virtualspace_name}}</router-link>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Выбор виртуального пространства | Tasker'});
	},
	data: () => ({
		
	}),
	methods: {},
	mounted(){
		window.scrollTo(0, 0);
		
		this.appModel.enableLeftMenu = false;
		this.appModel.enableSidebar = false;
		
		this.appModel.enableBreadcrumbs = true;
		this.appModel.breadcrumbs = [
			{
				title: 'Tasker',
				tooltip: '',
				link_type: 'router-link', // router-link, a, span
				onclick: () => {},
				href: {path: '/'},
				target_blank: false,
				icon: '',
			},
			{
				title: 'Выбор виртуального пространства',
				tooltip: '',
				link_type: 'router-link', // router-link, a, span
				onclick: () => {},
				href: {path: '/vs'},
				target_blank: false,
				icon: '',
			},
		];
		
		this.appModel.titleCabinet.icon.type = '';
		this.appModel.titleCabinet.icon.icon = '';
		this.appModel.titleCabinet.title = 'Выбор виртуального пространства';
		this.appModel.titleCabinet.buttons = [
			{
				type: 'btn', // btn, group-btn
				title: 'Создать',
				tooltip: 'Создать новое виртуальное пространство',
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '//id.bylex.su',
				target_blank: false,
				visible: true,
				selected: false,
				btnclass: 'btn-primary',
				class: '',
				subitems: [],
			},
		];
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	computed: {},
	components: {},
}
</script>
import { createI18n } from 'vue-i18n';
import ru from '@/locales/ru.json';
import en from '@/locales/en.json';
//import axios from 'axios';

import AuthModel from '@/models/AuthModel.js';

export default class AppModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.hcaptcha_sitekey = '';
		
		this.langs = ['ru', 'en'];
		this.langsName = {
			ru: 'Русский',
			en: 'English',
		};
		this.title = '';
		this.i18n = createI18n({
			legacy: false,
			locale: 'ru',
			messages: { ru, en },
		});
		
		this.auth = new AuthModel();
		
		this.enableMainLeftMenu = true;
		this.titleMainLeftMenu = {
			logo: '',
			link_type: 'router-link', // router-link, a, div
			onclick: () => {},
			href: '',
			tooltip: '',
		};
		this.mainLeftMenu = [
			/*{
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z" fill="currentColor" fill-rule="evenodd"></path></svg>',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'xxx',
				class: '',
				visible: true,
			},
			{
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M5 15h14v2H5zm0-8h14v2H5zm0 4h14v2H5z" fill="currentColor" fill-rule="evenodd"></path></svg>',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'xxx',
				class: '',
				visible: true,
			},
			{
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M13 11V3.993A.997.997 0 0 0 12 3c-.556 0-1 .445-1 .993V11H3.993A.997.997 0 0 0 3 12c0 .557.445 1 .993 1H11v7.007c0 .548.448.993 1 .993.556 0 1-.445 1-.993V13h7.007A.997.997 0 0 0 21 12c0-.556-.445-1-.993-1H13z" fill="currentColor" fill-rule="evenodd"></path></svg>',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: '/options',
				target_blank: false,
				tooltip: 'xxx',
				class: '',
				visible: true,
			},
			{
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'fa fa-envelope',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'Форум',
				class: '',
				visible: true,
			},
			{
				icon: {
					type: 'img', // icon, img, svg
					icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAyAAAAMgAEB7AknAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAKnSURBVHja7JhdSFNhHMZ/Ro42RafOdVZObeTHVMqhRankKEuURWAEhdBVwfCmoBUIFbvrot0YYV5IF4okREElq4hGhRPSVX5QWtlUFF1LmRO/0Iu68rCDH7mo9OI8d+d9X97zO8//ff4vnIi8S49/som1hU0uGVAGlAFlQBlQBpQBN1Zb15rURiuoOpaGpcAgjs3OL+J6P8yLzlHc3gCFhjhqqooY8k1x0vEabbQCp71UfP5ngNpoBQ0XD6FRKyXjqm2RWAoMCPEq3N6OjXPwyoksNGolvYMT3LjfxafvMwAUGuI4mrsDIV4FgNsbIN/25P8D7tLFAlDn7BXhloDc3oDEaae9FE+fj0ZXPzVVRQCkCDF4HMcBJOW2laVTkpcsVsbT5+N6cxf+6YXwQjIwFgQgJ1n919xwVOZy+kiG5NjkZwrcsR4M30FX9yhmk57zlhyKcnR8Gw2KwVhNbm+AcvvzFUNyZn8SZpN+2ZFxVOZiNumxlaXjePpl/YDOHj/B2las5UaMqQkYUxPENL/6MIytqTMs9/LSNAASOABbUydvjAK7d8aG32bc3gDu222iAxlJsRzI0mE26alTRmKtX3+Kl850w+XDK84nqlXhA4bqXvsItI/Aw488u1pCfqaANlqxsY36d9JERTI+s7iutQNjQVKEGM7edElK/MdX3QNbMfaKbLK2Ry1LokatZHxybsUX+acXmJ1fJFGtlDj87us4ANWn9lJoiJO0KXtFNnXn9oXnYKJaiaXAILnmQnXrUfeqX/1jco4UIQanvVTSB4v36MjPFMReGSpPny88B6vvvqWlzcvs/OKyjS7UtuLs8a8KeK3RQ+/gRAjwLADW+g6aX35myDclWd/S5qXR1b/iXhHyrw8ZUAaUAWVAGVAGlAHX0q8BAFFC80NTjcTYAAAAAElFTkSuQmCC',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: '/options',
				target_blank: false,
				tooltip: 'Форум',
				class: '',
				visible: true,
			},*/
		];
		
		this.enableLeftMenu = false;
		this.titleLeftMenu = {
			title: '',
			link_type: 'router-link', // router-link, a, div
			onclick: () => {},
			href: '',
			target_blank: false,
			img: '',
			tooltip: '',
			supicon: {
				type: '',
				icon: '',
				
				/*type: 'svg',
				icon: (
					'<svg height="100%" version="1.1" viewBox="0 0 8 8" width="100%" xmlns="http://www.w3.org/2000/svg"><circle class="cKJIje" cx="4" cy="4" r="4"></circle><path class="ctvqXy" d="M4.13074827,1.21766493 L4.10368158,1.21766493 C3.36340745,1.21766493 2.76388015,1.80793503 2.76388015,2.5367787 L2.76388015,3.21632216 L3.44054754,3.21632216 L3.44054754,2.54344089 C3.44054754,2.17901906 3.74031119,1.88388401 4.11044825,1.88388401 L4.1239816,1.88388401 C4.49411866,1.88388401 4.79388232,2.17901906 4.79388232,2.54344089 L4.79388232,3.21632216 L5.47054971,3.21632216 L5.47054971,2.5367787 C5.47054971,1.80793503 4.8710224,1.21766493 4.13074827,1.21766493 M2.76388015,3.21632216 L3.44054754,3.21632216 L3.44054754,3.88254123 L2.76388015,3.88254123 L2.76388015,3.21632216 Z M4.79388232,3.21632216 L5.47054971,3.21632216 L5.47054971,3.88254123 L4.79388232,3.88254123 L4.79388232,3.21632216 Z M4.79401765,3.88254123 L3.44068287,3.88254123 L2.76401548,3.88254123 C2.39049508,3.88254123 2.08734809,4.18100738 2.08734809,4.54876031 L2.08734809,5.54808892 C2.08734809,6.10000287 2.53735205,6.54741753 3.09094491,6.54741753 L5.14375561,6.54741753 C5.69802683,6.54741753 6.14735243,6.10385072 6.14735243,5.54808892 L6.14735243,4.54876031 C6.14735243,4.18100738 5.84420544,3.88254123 5.47068504,3.88254123 L4.79401765,3.88254123 Z"></path></svg>'
				),*/
			},
		};
		this.leftMenu = [
			/*{
				form: 'title',
				title: 'domains',
				visible: true,
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'fa fa-globe',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: '#',
				title: 'Домены сайта',
				title2: 'Домены сайта',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
			},
			{
				form: 'title',
				title: 'extend',
				visible: true,
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'fa fa-dollar-sign',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: '#',
				title: 'Расширенное',
				title2: 'Расширенные возможности',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M18 15.2V11c0-1.7-1.3-3-3-3h-1.9l.8-.8c.3-.3.3-.8 0-1.1-.3-.3-.8-.3-1.1 0l-2.3 2.3c-.3.4-.3.9 0 1.2l2.3 2.3c.2.2.3.2.5.2s.4-.1.6-.3c.3-.3.3-.8 0-1.1l-.7-.7H15c.6 0 1 .4 1 1v4.2c-1.2.4-2 1.5-2 2.8 0 1.7 1.3 3 3 3s3-1.3 3-3c0-1.3-.8-2.4-2-2.8zM17 19c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zM7 3C5.4 3 4 4.3 4 6c0 1.3.8 2.4 2 2.8V20c0 .6.4 1 1 1s1-.4 1-1V8.8c1.2-.4 2-1.5 2-2.8 0-1.7-1.3-3-3-3zm0 4c-.5 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" fill="currentColor" fill-rule="evenodd"></path></svg>',
					class: '',
				},
				icon2: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><g fill="currentColor"><path d="M16 11V9h-2V7.002A2.001 2.001 0 0 0 12 5c-1.102 0-2 .898-2 2.002V9H8v2H7v8h10v-8h-1zm-2 0h-4V9h4v2zM8 9V7.002A4.004 4.004 0 0 1 12 3a4 4 0 0 1 4 4.002V9h.994A2.01 2.01 0 0 1 19 11.009v7.982c0 1.11-.897 2.009-2.006 2.009H7.006A2.009 2.009 0 0 1 5 18.991V11.01C5 9.899 5.897 9 7.006 9H8zm0 0h2v2H8V9zm6 0h2v2h-2V9z"></path><circle cx="12" cy="15" r="2"></circle></g></svg>',
					class: '',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: '#',
				title: 'Item',
				title2: '',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
			},*/
		];
		this.footerLeftMenu = {
			enable: true,
		};
		
		this.enableSidebar = false;
		this.sidebarMenu = [
			/*{
				icon: {
					type: 'img', // icon, img, svg
					icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAyAAAAMgAEB7AknAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAKnSURBVHja7JhdSFNhHMZ/Ro42RafOdVZObeTHVMqhRankKEuURWAEhdBVwfCmoBUIFbvrot0YYV5IF4okREElq4hGhRPSVX5QWtlUFF1LmRO/0Iu68rCDH7mo9OI8d+d9X97zO8//ff4vnIi8S49/som1hU0uGVAGlAFlQBlQBpQBN1Zb15rURiuoOpaGpcAgjs3OL+J6P8yLzlHc3gCFhjhqqooY8k1x0vEabbQCp71UfP5ngNpoBQ0XD6FRKyXjqm2RWAoMCPEq3N6OjXPwyoksNGolvYMT3LjfxafvMwAUGuI4mrsDIV4FgNsbIN/25P8D7tLFAlDn7BXhloDc3oDEaae9FE+fj0ZXPzVVRQCkCDF4HMcBJOW2laVTkpcsVsbT5+N6cxf+6YXwQjIwFgQgJ1n919xwVOZy+kiG5NjkZwrcsR4M30FX9yhmk57zlhyKcnR8Gw2KwVhNbm+AcvvzFUNyZn8SZpN+2ZFxVOZiNumxlaXjePpl/YDOHj/B2las5UaMqQkYUxPENL/6MIytqTMs9/LSNAASOABbUydvjAK7d8aG32bc3gDu222iAxlJsRzI0mE26alTRmKtX3+Kl850w+XDK84nqlXhA4bqXvsItI/Aw488u1pCfqaANlqxsY36d9JERTI+s7iutQNjQVKEGM7edElK/MdX3QNbMfaKbLK2Ry1LokatZHxybsUX+acXmJ1fJFGtlDj87us4ANWn9lJoiJO0KXtFNnXn9oXnYKJaiaXAILnmQnXrUfeqX/1jco4UIQanvVTSB4v36MjPFMReGSpPny88B6vvvqWlzcvs/OKyjS7UtuLs8a8KeK3RQ+/gRAjwLADW+g6aX35myDclWd/S5qXR1b/iXhHyrw8ZUAaUAWVAGVAGlAHX0q8BAFFC80NTjcTYAAAAAElFTkSuQmCC',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'Форум',
				class: '',
				visible: true,
				selected: false,
			},
			{
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'fa fa-envelope',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'Форум',
				class: '',
				visible: true,
				selected: false,
			},
			{
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><g fill-rule="evenodd"><path d="M2 12c0 5.523 4.477 10 10 10s10-4.477 10-10S17.523 2 12 2 2 6.477 2 12z" fill="currentColor"></path><rect fill="inherit" x="11" y="10" width="2" height="7" rx="1"></rect><circle fill="inherit" cx="12" cy="8" r="1"></circle></g></svg>',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'Форум',
				class: '',
				visible: true,
				selected: false,
			},
			{
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><g fill="currentColor" fill-rule="evenodd"><path d="M14 17h4V7h-4v10zM12 6.007C12 5.45 12.453 5 12.997 5h6.006c.55 0 .997.45.997 1.007v11.986c0 .556-.453 1.007-.997 1.007h-6.006c-.55 0-.997-.45-.997-1.007V6.007z" fill-rule="nonzero"></path><rect x="4" y="5" width="6" height="2" rx="1"></rect><rect x="4" y="9" width="6" height="2" rx="1"></rect><rect x="4" y="13" width="6" height="2" rx="1"></rect><rect x="4" y="17" width="6" height="2" rx="1"></rect></g></svg>',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'Help',
				class: '',
				visible: true,
				selected: false,
			},
			{
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><g fill="currentColor" fill-rule="evenodd"><path d="M8 6.003v2.995a1 1 0 1 0 2 0V5.102C10 4.494 9.507 4 8.9 4H5a1 1 0 0 0 0 2.003h3z" fill-rule="nonzero"></path><path d="M9.428 18.018C7.351 16.989 6 14.807 6 12.37c0-2.266 1.167-4.319 3.02-5.425.48-.286.646-.922.371-1.421a.979.979 0 0 0-1.364-.386C5.557 6.611 4 9.35 4 12.37c0 3.248 1.802 6.158 4.57 7.529.498.247 1.095.026 1.332-.493.237-.52.025-1.141-.474-1.388z" fill-rule="nonzero"></path><path d="M14 15.002v3.896c0 .608.493 1.102 1.1 1.102H19a1 1 0 0 0 0-2.003h-3v-2.995a1 1 0 1 0-2 0z"></path><path d="M14.097 4.596c-.237.52-.025 1.14.474 1.387 2.077 1.029 3.428 3.21 3.428 5.647 0 2.266-1.167 4.32-3.021 5.425a1.063 1.063 0 0 0-.37 1.42c.274.5.885.673 1.364.387 2.47-1.473 4.027-4.212 4.027-7.232 0-3.248-1.802-6.158-4.57-7.528A.957.957 0 0 0 15.002 4a1 1 0 0 0-.905.596z"></path></g></svg>',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'Help',
				class: '',
				visible: true,
				selected: false,
			},*/
		];
		
		this.enableBreadcrumbs = false;
		this.breadcrumbs = [
			/*{
				title: 'CMS',
				tooltip: '',
				link_type: 'router-link', // router-link, a, span
				onclick: () => {},
				href: '#',
				target_blank: false,
				icon: '',
			},
			{
				title: 'cms site',
				tooltip: 'cms site',
				link_type: 'router-link', // router-link, a, span
				onclick: () => {},
				href: '#',
				target_blank: false,
				icon: '',
			},
			{
				title: 'Информация (детали) о сайте',
				tooltip: '',
				link_type: 'router-link', // router-link, a, span
				onclick: () => {},
				href: '#',
				target_blank: false,
				icon: '',
			},*/
		];
		
		this.titleCabinet = {
			icon: {
				icon: '', // 'fa fa-tachometer-alt'
				type: '', // icon, img, svg
			},
			title: '',
			buttons: [
				/*{
					type: 'btn', // btn, group-btn
					title: 'К сайту',
					tooltip: 'Перейти на главную сайта',
					link_type: 'router-link', // router-link, a, div
					onclick: () => {},
					href: '#',
					target_blank: false,
					visible: true,
					selected: false,
					btnclass: 'btn-light',
					class: '',
					subitems: [],
				},
				{
					type: 'btn', // btn, group-btn
					title: 'Настройки',
					tooltip: 'Настройки сайта',
					link_type: 'router-link', // router-link, a, div
					onclick: () => {},
					href: '#',
					target_blank: false,
					visible: true,
					selected: false,
					btnclass: 'btn-light',
					class: '',
					subitems: [],
				},
				{
					type: 'btn', // btn, group-btn
					title: '',
					tooltip: '',
					link_type: 'router-link', // router-link, a, div
					onclick: () => {},
					href: '',
					target_blank: false,
					visible: true,
					selected: false,
					btnclass: 'btn-light',
					class: '',
					subitems: [
						{
							type: 'item',
							title: 'Скопировать сайт',
							tooltip: 'Создать копию сайта',
							link_type: 'router-link', // router-link, a, div
							onclick: () => {},
							href: '#',
							target_blank: false,
							visible: true,
							selected: false,
							class: '',
						},
						{
							type: 'item',
							title: 'Удалить сайт',
							tooltip: '',
							link_type: 'router-link', // router-link, a, div
							onclick: () => {},
							href: '#',
							target_blank: false,
							visible: true,
							selected: false,
							class: '',
						},
					],
				},*/
			],
		};
	}
	
	/**
	 * Set page title
	 */
	setPageTitle(title)
	{
		this.title = title;
		document.title = title;
	}
	
	/**
	 * Set app lang
	 */
	setLang(lang = 'ru')
	{
		this.i18n.global.locale = lang;
		//axios.defaults.headers.common['Accept-Language'] = lang;
		document.querySelector('html').setAttribute('lang', lang);
		return lang;
	}
	
	/**
	 * Returns lang
	 */
	getLang()
	{
		//return this.i18n.locale;
		return this.i18n.global.locale;
	}
	
	pageReload()
	{
		document.location.reload();
	}
	
	/**
	 * Установка левого меню после выбора виртуального пространства
	 */
	doSetLeftMenu1(virtualspace_id)
	{
		this.enableLeftMenu = true;
		this.titleLeftMenu.title = this.auth.getVirtualSpace(virtualspace_id).virtualspace_name;
		this.titleLeftMenu.link_type = 'router-link';
		this.titleLeftMenu.onclick = () => {};
		this.titleLeftMenu.href = {path: '/vs/' + virtualspace_id};
		this.titleLeftMenu.img = '/assets/img/proj.png';
		this.titleLeftMenu.tooltip = '';
		this.titleLeftMenu.supicon.type = '';
		this.titleLeftMenu.supicon.icon = '';
		this.leftMenu = [
			{
				form: 'item', // item, title
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'fa fa-tasks',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: {path: '/vs/' + virtualspace_id + '/tasks'},
				title: 'Задачи',
				tooltip: '',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
				is_anchor: false,
				anchor_num: '',
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'fa fa-rocket',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: {path: '/vs/' + virtualspace_id + '/projects'},
				title: 'Проекты',
				tooltip: '',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
				is_anchor: false,
				anchor_num: '',
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'fa fa-crosshairs',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: {path: '/vs/' + virtualspace_id + '/targets'},
				title: 'Цели задач',
				tooltip: '',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
				is_anchor: false,
				anchor_num: '',
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'fa fa-hashtag',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: {path: '/vs/' + virtualspace_id + '/tags'},
				title: 'Теги задач',
				tooltip: '',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
				is_anchor: false,
				anchor_num: '',
			},
			{
				form: 'title',
				title: 'Настройки',
				visible: true,
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'fa fa-genderless',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: {path: '/vs/' + virtualspace_id + '/settings/tasks-types'},
				title: 'Типы задач',
				tooltip: '',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
				is_anchor: false,
				anchor_num: '',
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'far fa-file-alt',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: {path: '/vs/' + virtualspace_id + '/settings/document-templates'},
				title: 'Шаблоны задач',
				tooltip: '',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
				is_anchor: false,
				anchor_num: '',
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'fa fa-genderless',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: {path: '/vs/' + virtualspace_id + '/settings/tasks-custom-fields'},
				title: 'Доп. поля для задач',
				tooltip: 'Дополнительные поля для задач',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
				is_anchor: false,
				anchor_num: '',
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'fa fa-genderless',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: {path: '/vs/' + virtualspace_id + '/settings/tasks-priorities'},
				title: 'Приоритеты задач',
				tooltip: '',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
				is_anchor: false,
				anchor_num: '',
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'fa fa-genderless',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: {path: '/vs/' + virtualspace_id + '/settings/tasks-statuses-types'},
				title: 'Типы статусов задач',
				tooltip: '',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
				is_anchor: false,
				anchor_num: '',
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'fa fa-genderless',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'router-link', // router-link, a, div
				onclick: () => {},
				href: {path: '/vs/' + virtualspace_id + '/settings/tasks-statuses'},
				title: 'Статусы задач',
				tooltip: '',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
				is_anchor: false,
				anchor_num: '',
			},
		];
		this.footerLeftMenu.enable = true;
		this.enableSidebar = false;
	}
	
	
	/**
	 * Получение списка пользователей виртуального пространства
	 */
	async doGetUsers(virtualspace_id)
	{
		return await fetch('https://id.bylex.su/api/users/' + virtualspace_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Получение ссылки на аватар пользователя
	 */
	async getUserAvatarLink(user_id)
	{
		return await fetch('https://id.bylex.su/api/users-avatar/' + user_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return {
				smallAvaLink: 'https://id.bylex.su' + data.avatar.smallAvaLink,
				originalAvaLink: 'https://id.bylex.su' + data.avatar.originalAvaLink,
			};
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	
	/**
	 * Получение списка задач
	 */
	async doGetTasks(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Получение данных о задаче по её id
	 */
	async doGetTask(virtualspace_id, task_id)
	{
		return await fetch('/api/tasks/' + task_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление задачи
	 */
	async doAddTask(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о задаче по её id
	 */
	async doEditTask(virtualspace_id, task_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks/' + task_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление задачи по её id
	 */
	async doDeleteTask(virtualspace_id, task_id)
	{
		return await fetch('/api/tasks/' + task_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение позиции у всех задач определеного статуса/колонки (для tasks-board)
	 */
	async doEditTasksPos(virtualspace_id, ids_pos)
	{
		return await fetch('/api/tasks-pos', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify({
				virtualspace_id,
				lang: this.getLang(),
				ids_pos,
			}),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	
	/**
	 * Получение списка исполнителей задачи по её id
	 */
	async doGetTaskUsersAssigned(virtualspace_id, task_id)
	{
		return await fetch('/api/tasks/' + task_id + '/users-assigned?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о исполнителе из задачи по его id
	 */
	async doGetTaskUserAssigned(virtualspace_id, task_id, user_assigned_id)
	{
		return await fetch('/api/tasks/' + task_id + '/users-assigned/' + user_assigned_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление исполнителя в задачу
	 */
	async doAddTaskUserAssigned(virtualspace_id, task_id, user_id)
	{
		return await fetch('/api/tasks/' + task_id + '/users-assigned/' + user_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление исполнителя из задачи по его id
	 */
	async doDeleteTaskUserAssigned(virtualspace_id, task_id, user_assigned_id)
	{
		return await fetch('/api/tasks/' + task_id + '/users-assigned/' + user_assigned_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	
	/**
	 * Получение списка тегов задачи по её id
	 */
	async doGetTaskTags(virtualspace_id, task_id)
	{
		return await fetch('/api/tasks/' + task_id + '/tags?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о теге из задачи по его id
	 */
	async doGetTaskTag(virtualspace_id, task_id, task_tag_id)
	{
		return await fetch('/api/tasks/' + task_id + '/tags/' + task_tag_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление тега в задачу
	 */
	async doAddTaskTag(virtualspace_id, task_id, tag_id)
	{
		return await fetch('/api/tasks/' + task_id + '/tags/' + tag_id+ '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление тега из задачи по его id
	 */
	async doDeleteTaskTag(virtualspace_id, task_id, task_tag_id)
	{
		return await fetch('/api/tasks/' + task_id + '/tags/' + task_tag_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	
	/**
	 * Получение списка целей задачи по её id
	 */
	async doGetTaskTargets(virtualspace_id, task_id)
	{
		return await fetch('/api/tasks/' + task_id + '/targets?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о цели из задачи по её id
	 */
	async doGetTaskTarget(virtualspace_id, task_id, task_target_id)
	{
		return await fetch('/api/tasks/' + task_id + '/targets/' + task_target_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление цели в задачу
	 */
	async doAddTaskTarget(virtualspace_id, task_id, target_id)
	{
		return await fetch('/api/tasks/' + task_id + '/targets/' + target_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление цели из задачи по её id
	 */
	async doDeleteTaskTarget(virtualspace_id, task_id, task_target_id)
	{
		return await fetch('/api/tasks/' + task_id + '/targets/' + task_target_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	
	/**
	 * Получение списка пунктов чек-листа задачи по её id
	 */
	async doGetTaskСhecklist(virtualspace_id, task_id)
	{
		return await fetch('/api/tasks/' + task_id + '/checklists?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о пункте из чек-листа задачи по его id
	 */
	async doGetTaskChecklistItem(virtualspace_id, task_id, checklist_item_id)
	{
		return await fetch('/api/tasks/' + task_id + '/checklists/' + checklist_item_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление пункта в чек-лист задачи
	 */
	async doAddTaskChecklistItem(virtualspace_id, task_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks/' + task_id + '/checklists', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных пункта из чек-листа задачи по его id
	 */
	async doEditTaskChecklistItem(virtualspace_id, task_id, checklist_item_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks/' + task_id + '/checklists/' + checklist_item_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление пункта из чек-листа задачи по его id
	 */
	async doDeleteTaskChecklistItem(virtualspace_id, task_id, checklist_item_id)
	{
		return await fetch('/api/tasks/' + task_id + '/checklists/' + checklist_item_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	
	/**
	 * Получение списка вложений задачи по её id
	 */
	async doGetTaskAttachments(virtualspace_id, task_id)
	{
		return await fetch('/api/tasks/' + task_id + '/attachments?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о вложении задачи по его id
	 */
	async doGetTaskAttachment(virtualspace_id, task_id, attachment_id)
	{
		return await fetch('/api/tasks/' + task_id + '/attachments/' + attachment_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных вложения задачи по его id
	 */
	async doEditTaskAttachment(virtualspace_id, task_id, attachment_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks/' + task_id + '/attachments/' + attachment_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление вложения задачи по его id
	 */
	async doDeleteTaskAttachment(virtualspace_id, task_id, attachment_id)
	{
		return await fetch('/api/tasks/' + task_id + '/attachments/' + attachment_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	
	/**
	 * Получение списка комментариев задачи по её id
	 */
	async doGetTaskComments(virtualspace_id, task_id)
	{
		return await fetch('/api/tasks/' + task_id + '/comments?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных о комментарии из задачи по его id
	 */
	async doGetTaskComment(virtualspace_id, task_id, comment_id)
	{
		return await fetch('/api/tasks/' + task_id + '/comments/' + comment_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление комментария в задачу
	 */
	async doAddTaskComment(virtualspace_id, task_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks/' + task_id + '/comments', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных комментария из задачи по его id
	 */
	async doEditTaskComment(virtualspace_id, task_id, comment_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks/' + task_id + '/comments/' + comment_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление комментария из задачи по его id
	 */
	async doDeleteTaskComment(virtualspace_id, task_id, comment_id)
	{
		return await fetch('/api/tasks/' + task_id + '/comments/' + comment_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	
	/**
	 * Получение списка действий в задаче по её id
	 */
	async doGetTaskActions(virtualspace_id, task_id)
	{
		return await fetch('/api/tasks/' + task_id + '/actions?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	
	/**
	 * Получение списка статусов проектов
	 */
	async doGetProjectsStatuses()
	{
		return await fetch('/api/projects-statuses?' + new URLSearchParams({
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Получение списка проектов
	 */
	async doGetProjects(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/projects?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Получение данных о проекте по его id
	 */
	async doGetProject(virtualspace_id, project_id)
	{
		return await fetch('/api/projects/' + project_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление проекта
	 */
	async doAddProject(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/projects', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о проекте по его id
	 */
	async doEditProject(virtualspace_id, project_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/projects/' + project_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление проекта по его id
	 */
	async doDeleteProject(virtualspace_id, project_id)
	{
		return await fetch('/api/projects/' + project_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	
	/**
	 * Получение списка целей задач
	 */
	async doGetTargets(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/targets?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Получение данных о цели задач по её id
	 */
	async doGetTarget(virtualspace_id, target_id)
	{
		return await fetch('/api/targets/' + target_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление цели задач
	 */
	async doAddTarget(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/targets', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о цели задач по её id
	 */
	async doEditTarget(virtualspace_id, target_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/targets/' + target_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление цели задач по её id
	 */
	async doDeleteTarget(virtualspace_id, target_id)
	{
		return await fetch('/api/targets/' + target_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	
	/**
	 * Получение списка шаблонов задач
	 */
	async doGetDocumentTemplates(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/document-templates?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Получение данных о шаблоне задач по его id
	 */
	async doGetDocumentTemplate(virtualspace_id, document_template_id)
	{
		return await fetch('/api/document-templates/' + document_template_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление шаблона задач
	 */
	async doAddDocumentTemplate(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/document-templates', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о шаблоне задач по его id
	 */
	async doEditDocumentTemplate(virtualspace_id, document_template_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/document-templates/' + document_template_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление шаблона задач по его id
	 */
	async doDeleteDocumentTemplate(virtualspace_id, document_template_id)
	{
		return await fetch('/api/document-templates/' + document_template_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	
	/**
	 * Получение списка тегов задач
	 */
	async doGetTags(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tags?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Получение данных о теге задач по его id
	 */
	async doGetTag(virtualspace_id, tag_id)
	{
		return await fetch('/api/tags/' + tag_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление тега задач
	 */
	async doAddTag(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tags', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о теге задач по его id
	 */
	async doEditTag(virtualspace_id, tag_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tags/' + tag_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление тега задач по его id
	 */
	async doDeleteTag(virtualspace_id, tag_id)
	{
		return await fetch('/api/tags/' + tag_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	
	/**
	 * Получение списка дополнительных полей задач
	 */
	async doGetTasksCustomFields(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks-custom-fields?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Получение списка дополнительных полей задач по типу задачи
	 */
	async doGetTasksCustomFieldsByTasksType(virtualspace_id, type_id)
	{
		return await fetch('/api/tasks-custom-fields-by-tasks-type/' + type_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Получение данных о дополнительного поля задач по его id
	 */
	async doGetTasksCustomField(virtualspace_id, tasks_custom_field_id)
	{
		return await fetch('/api/tasks-custom-fields/' + tasks_custom_field_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение значений дополнительных полей задач по id задачи
	 */
	async doGetTasksCustomFieldsValuesByTask(virtualspace_id, task_id)
	{
		return await fetch('/api/tasks-custom-fields-values/' + task_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Добавление дополнительного поля задач
	 */
	async doAddTasksCustomField(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks-custom-fields', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о дополнительном поле задач по его id
	 */
	async doEditTasksCustomField(virtualspace_id, tasks_custom_field_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks-custom-fields/' + tasks_custom_field_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление дополнительного поля задач по его id
	 */
	async doDeleteTasksCustomField(virtualspace_id, tasks_custom_field_id)
	{
		return await fetch('/api/tasks-custom-fields/' + tasks_custom_field_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	
	/**
	 * Получение списка приоритетов задач
	 */
	async doGetTasksPriorities(virtualspace_id, params, full = false)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		params['full'] = !!full ? 1 : 0;
		
		return await fetch('/api/tasks-priorities?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Получение данных о приоритете задач по его id
	 */
	async doGetTasksPriority(virtualspace_id, tasks_priority_id)
	{
		return await fetch('/api/tasks-priorities/' + tasks_priority_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление приоритета задач
	 */
	async doAddTasksPriority(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks-priorities', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о приоритете задач по его id
	 */
	async doEditTasksPriority(virtualspace_id, tasks_priority_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks-priorities/' + tasks_priority_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление приоритета задач по его id
	 */
	async doDeleteTasksPriority(virtualspace_id, tasks_priority_id)
	{
		return await fetch('/api/tasks-priorities/' + tasks_priority_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	
	/**
	 * Получение списка статусов задач
	 */
	async doGetTasksStatuses(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks-statuses?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Получение списка статусов задач по типу задачи
	 */
	async doGetTasksStatusesByTasksType(virtualspace_id, type_id)
	{
		return await fetch('/api/tasks-statuses-by-tasks-type/' + type_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Получение данных о статусе задач по его id
	 */
	async doGetTasksStatus(virtualspace_id, tasks_status_id)
	{
		return await fetch('/api/tasks-statuses/' + tasks_status_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление статуса задач
	 */
	async doAddTasksStatus(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks-statuses', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о статусе задач по его id
	 */
	async doEditTasksStatus(virtualspace_id, tasks_status_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks-statuses/' + tasks_status_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление статуса задач по его id
	 */
	async doDeleteTasksStatus(virtualspace_id, tasks_status_id)
	{
		return await fetch('/api/tasks-statuses/' + tasks_status_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	
	/**
	 * Получение списка типов задач
	 */
	async doGetTasksTypes(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks-types?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Получение данных о типе задач по его id
	 */
	async doGetTasksType(virtualspace_id, tasks_type_id)
	{
		return await fetch('/api/tasks-types/' + tasks_type_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление типа задач
	 */
	async doAddTasksType(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks-types', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о типе задач по его id
	 */
	async doEditTasksType(virtualspace_id, tasks_type_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks-types/' + tasks_type_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление типа задач по его id
	 */
	async doDeleteTasksType(virtualspace_id, tasks_type_id)
	{
		return await fetch('/api/tasks-types/' + tasks_type_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	
	/**
	 * Получение списка типов статусов задач
	 */
	async doGetTasksStatusesTypes(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks-statuses-types?' + new URLSearchParams(params), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Получение данных о типе статусов задач по его id
	 */
	async doGetTasksStatusesType(virtualspace_id, tasks_statuses_type_id)
	{
		return await fetch('/api/tasks-statuses-types/' + tasks_statuses_type_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление типа статусов задач
	 */
	async doAddTasksStatusesType(virtualspace_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks-statuses-types', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение данных о типе статусов задач по его id
	 */
	async doEditTasksStatusesType(virtualspace_id, tasks_statuses_type_id, params)
	{
		params = params || {};
		params['virtualspace_id'] = virtualspace_id;
		params['lang'] = this.getLang();
		
		return await fetch('/api/tasks-statuses-types/' + tasks_statuses_type_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление типа статусов задач по его id
	 */
	async doDeleteTasksStatusesType(virtualspace_id, tasks_statuses_type_id)
	{
		return await fetch('/api/tasks-statuses-types/' + tasks_statuses_type_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	
	/**
	 * Получение связей типов задач со статусами задач по типу задач
	 */
	async doGetTasksTypesStatusesByType(virtualspace_id, type_id)
	{
		return await fetch('/api/tasks-types-statuses-by-type/' + type_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Получение связей типов задач со статусами задач по статусу задач
	 */
	async doGetTasksTypesStatusesByStatus(virtualspace_id, status_id)
	{
		return await fetch('/api/tasks-types-statuses-by-status/' + status_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Сохранение изменений в связях типов задач со статусами задач
	 */
	async doSaveTasksTypesStatuses(virtualspace_id, type_id, status_id)
	{
		return await fetch('/api/tasks-types-statuses/' + type_id + '/' + status_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	
	/**
	 * Получение связей типов задач с дополнительными полями для задач по типу задач
	 */
	async doGetTasksTypesCustomFieldsByType(virtualspace_id, type_id)
	{
		return await fetch('/api/tasks-types-custom-fields-by-type/' + type_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Получение связей типов задач с дополнительными полями для задач по дополнительному полю для задач
	 */
	async doGetTasksTypesCustomFieldsByCustomField(virtualspace_id, custom_field_id)
	{
		return await fetch('/api/tasks-types-custom-fields-by-custom-field/' + custom_field_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				message: error,
			};
		});
	}
	
	/**
	 * Сохранение изменений в связях типов задач с дополнительными полями для задач
	 */
	async doSaveTasksTypesCustomFields(virtualspace_id, type_id, custom_field_id)
	{
		return await fetch('/api/tasks-types-custom-fields/' + type_id + '/' + custom_field_id + '?' + new URLSearchParams({
			virtualspace_id,
			lang: this.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			headers: {
				Authorization: 'Bearer '+this.auth.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
}

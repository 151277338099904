<template>
	<div class="page-navigation">
		<div class="spacer-inner" :style="{width: appModel.enableLeftMenu ? '304px' : '64px'}">
			<div class="navigation-fixed-container">
				<div class="global-navigation">
					<div class="css-1sqfy18" style="margin-left: 64px;"></div>
					<div class="css-1rawbl">
						<div class="css-sea5qf">
							<div class="css-4cxybv">
								<div role="presentation" class="css-7xjigh">
									<div class="css-1baulvz" :content="appModel.titleMainLeftMenu.tooltip" v-tippy="{placement: 'right'}">
										<a :href="appModel.titleMainLeftMenu.href" class="product-logo-global-item" v-if="appModel.titleMainLeftMenu.link_type == 'a'">
											<div class="css-1ixbp0l">
												<span class="fYDpDm" v-if="appModel.titleMainLeftMenu.logo != ''" v-html="appModel.titleMainLeftMenu.logo"></span>
												<span class="fYDpDm" v-else>
													<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="32" height="32" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" viewBox="0 0 1224 1637" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path fill="#fff" d="M681 169c7,-50 3,-130 -16,-169 -14,31 -13,52 -38,98 -142,266 -572,436 -606,787 -5,56 5,127 18,166 24,73 23,72 58,147 -29,-14 -75,-32 -97,-11 63,30 102,76 150,121 101,95 214,179 356,191 54,4 99,6 151,-13 6,-2 12,-5 18,-7 -13,38 -20,52 -15,89 2,19 15,53 35,69 10,-29 18,-60 34,-87 14,-24 31,-42 49,-63 36,-37 95,-77 136,-110 73,-62 143,-112 174,-208 20,-58 20,-115 -5,-167 -4,-10 -6,-12 -6,-23 43,24 94,63 147,53 -63,-52 -79,-118 -104,-191 -78,-231 -155,-198 -172,-228 52,16 99,21 140,-19 -45,-5 -92,-23 -139,-36 -123,-35 -259,-45 -368,6 -155,73 -251,272 -204,436 29,-75 26,-123 107,-196 39,-35 https://rpi3.bsrv.su/ex/atlassian-design/2/index.html113,-80 165,-79 -8,0 -37,18 -48,25 -54,35 -98,84 -117,149 8,-4 11,-7 18,-12l52 -37c31,-15 52,-22 86,-28 55,-11 128,1 172,37 -61,-22 -161,-24 -197,15 15,6 33,4 50,7 168,30 165,180 139,208 -10,-27 -18,-85 -69,-102 22,39 26,147 7,164 -26,-6 -31,-44 -38,-65 -13,42 -8,80 -2,93 -22,24 -55,21 -137,-23 8,27 41,55 65,78 -20,5 -28,7 -54,-5 -16,-7 -33,-20 -47,-32 -29,-25 -43,-47 -59,-73 -32,74 1,144 2,177 -113,-73 -209,-227 -205,-365 4,-129 42,-219 127,-315l131 -148c73,-88 142,-190 156,-304z"/></g></svg>
												</span>
											</div>
										</a>
										<router-link :to="appModel.titleMainLeftMenu.href" class="product-logo-global-item" v-if="appModel.titleMainLeftMenu.link_type == 'router-link'">
											<div class="css-1ixbp0l">
												<span class="fYDpDm" v-if="appModel.titleMainLeftMenu.logo != ''" v-html="appModel.titleMainLeftMenu.logo"></span>
												<span class="fYDpDm" v-else>
													<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="32" height="32" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" viewBox="0 0 1224 1637" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path fill="#fff" d="M681 169c7,-50 3,-130 -16,-169 -14,31 -13,52 -38,98 -142,266 -572,436 -606,787 -5,56 5,127 18,166 24,73 23,72 58,147 -29,-14 -75,-32 -97,-11 63,30 102,76 150,121 101,95 214,179 356,191 54,4 99,6 151,-13 6,-2 12,-5 18,-7 -13,38 -20,52 -15,89 2,19 15,53 35,69 10,-29 18,-60 34,-87 14,-24 31,-42 49,-63 36,-37 95,-77 136,-110 73,-62 143,-112 174,-208 20,-58 20,-115 -5,-167 -4,-10 -6,-12 -6,-23 43,24 94,63 147,53 -63,-52 -79,-118 -104,-191 -78,-231 -155,-198 -172,-228 52,16 99,21 140,-19 -45,-5 -92,-23 -139,-36 -123,-35 -259,-45 -368,6 -155,73 -251,272 -204,436 29,-75 26,-123 107,-196 39,-35 https://rpi3.bsrv.su/ex/atlassian-design/2/index.html113,-80 165,-79 -8,0 -37,18 -48,25 -54,35 -98,84 -117,149 8,-4 11,-7 18,-12l52 -37c31,-15 52,-22 86,-28 55,-11 128,1 172,37 -61,-22 -161,-24 -197,15 15,6 33,4 50,7 168,30 165,180 139,208 -10,-27 -18,-85 -69,-102 22,39 26,147 7,164 -26,-6 -31,-44 -38,-65 -13,42 -8,80 -2,93 -22,24 -55,21 -137,-23 8,27 41,55 65,78 -20,5 -28,7 -54,-5 -16,-7 -33,-20 -47,-32 -29,-25 -43,-47 -59,-73 -32,74 1,144 2,177 -113,-73 -209,-227 -205,-365 4,-129 42,-219 127,-315l131 -148c73,-88 142,-190 156,-304z"/></g></svg>
												</span>
											</div>
										</router-link>
										<div @click="appModel.titleMainLeftMenu.onclick" class="product-logo-global-item" v-if="appModel.titleMainLeftMenu.link_type == 'div'">
											<div class="css-1ixbp0l">
												<span class="fYDpDm" v-if="appModel.titleMainLeftMenu.logo != ''" v-html="appModel.titleMainLeftMenu.logo"></span>
												<span class="fYDpDm" v-else>
													<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="32" height="32" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" viewBox="0 0 1224 1637" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path fill="#fff" d="M681 169c7,-50 3,-130 -16,-169 -14,31 -13,52 -38,98 -142,266 -572,436 -606,787 -5,56 5,127 18,166 24,73 23,72 58,147 -29,-14 -75,-32 -97,-11 63,30 102,76 150,121 101,95 214,179 356,191 54,4 99,6 151,-13 6,-2 12,-5 18,-7 -13,38 -20,52 -15,89 2,19 15,53 35,69 10,-29 18,-60 34,-87 14,-24 31,-42 49,-63 36,-37 95,-77 136,-110 73,-62 143,-112 174,-208 20,-58 20,-115 -5,-167 -4,-10 -6,-12 -6,-23 43,24 94,63 147,53 -63,-52 -79,-118 -104,-191 -78,-231 -155,-198 -172,-228 52,16 99,21 140,-19 -45,-5 -92,-23 -139,-36 -123,-35 -259,-45 -368,6 -155,73 -251,272 -204,436 29,-75 26,-123 107,-196 39,-35 https://rpi3.bsrv.su/ex/atlassian-design/2/index.html113,-80 165,-79 -8,0 -37,18 -48,25 -54,35 -98,84 -117,149 8,-4 11,-7 18,-12l52 -37c31,-15 52,-22 86,-28 55,-11 128,1 172,37 -61,-22 -161,-24 -197,15 15,6 33,4 50,7 168,30 165,180 139,208 -10,-27 -18,-85 -69,-102 22,39 26,147 7,164 -26,-6 -31,-44 -38,-65 -13,42 -8,80 -2,93 -22,24 -55,21 -137,-23 8,27 41,55 65,78 -20,5 -28,7 -54,-5 -16,-7 -33,-20 -47,-32 -29,-25 -43,-47 -59,-73 -32,74 1,144 2,177 -113,-73 -209,-227 -205,-365 4,-129 42,-219 127,-315l131 -148c73,-88 142,-190 156,-304z"/></g></svg>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<span v-for="item in visibleItems(appModel.mainLeftMenu)">
								<div class="css-2rlxtj">
									<div role="presentation" class="css-7xjigh">
										<div class="css-1baulvz" :content="item.tooltip" v-tippy="{placement: 'right'}">
											<a :href="item.href" :target="item.target_blank ? '_blank' : '_self'" rel="nofollow noopener noreferrer" v-if="item.link_type == 'a'">
												<button class="global-navigation-item" :class="[item.class]">
													<div class="css-1ixbp0l">
														<span class="eHDVBw">
															<span v-if="item.icon && item.icon.icon != ''">
																<i :class="[item.icon.icon]" style="font-size: 20px;" v-if="item.icon.type == 'icon'"></i>
																<span class="css-noix33" v-if="item.icon.type == 'svg'">
																	<div>
																		<span class="hWgvbO" v-html="item.icon.icon"></span>
																	</div>
																</span>
																<img :src="item.icon.icon" style="width: 22px; height: 22px; -webkit-border-radius: 50%; -moz-border-radius: 50%; border-radius: 50%;" v-if="item.icon.type == 'img'" />
															</span>
															<i class="fa fa-check-square m-l-mini m-r-mini" style="font-size: 20px;" v-else></i>
														</span>
													</div>
												</button>
											</a>
											<router-link :to="item.href" v-if="item.link_type == 'router-link'">
												<button class="global-navigation-item" :class="[item.class]">
													<div class="css-1ixbp0l">
														<span class="eHDVBw">
															<span v-if="item.icon && item.icon.icon != ''">
																<i :class="[item.icon.icon]" style="font-size: 20px;" v-if="item.icon.type == 'icon'"></i>
																<span class="css-noix33" v-if="item.icon.type == 'svg'">
																	<div>
																		<span class="hWgvbO" v-html="item.icon.icon"></span>
																	</div>
																</span>
																<img :src="item.icon.icon" style="width: 22px; height: 22px; -webkit-border-radius: 50%; -moz-border-radius: 50%; border-radius: 50%;" v-if="item.icon.type == 'img'" />
															</span>
															<i class="fa fa-check-square m-l-mini m-r-mini" style="font-size: 20px;" v-else></i>
														</span>
													</div>
												</button>
											</router-link>
											<div @click="item.onclick" v-if="item.link_type == 'div'">
												<button class="global-navigation-item" :class="[item.class]">
													<div class="css-1ixbp0l">
														<span class="eHDVBw">
															<span v-if="item.icon && item.icon.icon != ''">
																<i :class="[item.icon.icon]" style="font-size: 20px;" v-if="item.icon.type == 'icon'"></i>
																<span class="css-noix33" v-if="item.icon.type == 'svg'">
																	<div>
																		<span class="hWgvbO" v-html="item.icon.icon"></span>
																	</div>
																</span>
																<img :src="item.icon.icon" style="width: 22px; height: 22px; -webkit-border-radius: 50%; -moz-border-radius: 50%; border-radius: 50%;" v-if="item.icon.type == 'img'" />
															</span>
															<i class="fa fa-check-square m-l-mini m-r-mini" style="font-size: 20px;" v-else></i>
														</span>
													</div>
												</button>
											</div>
										</div>
									</div>
								</div>
							</span>
						</div>
						<div class="css-isqeq9">
							<div class="css-2rlxtj">
								<div role="presentation" class="css-7xjigh">
									<div class="css-1baulvz btn-group dropend">
										<button class="global-navigation-item" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" content="Сервисы" v-tippy="{placement: 'right'}">
											<div class="css-1ixbp0l">
												<span class="eHDVBw">
													<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path fill="currentColor" fill-rule="evenodd" d="M4 5.01C4 4.451 4.443 4 5.01 4h1.98C7.549 4 8 4.443 8 5.01v1.98C8 7.549 7.557 8 6.99 8H5.01C4.451 8 4 7.557 4 6.99V5.01zm0 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C8 13.549 7.557 14 6.99 14H5.01C4.451 14 4 13.557 4 12.99v-1.98zm6-6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C14 7.549 13.557 8 12.99 8h-1.98C10.451 8 10 7.557 10 6.99V5.01zm0 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98zm6-6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C20 7.549 19.557 8 18.99 8h-1.98C16.451 8 16 7.557 16 6.99V5.01zm0 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98zm-12 6c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98C8 19.549 7.557 20 6.99 20H5.01C4.451 20 4 19.557 4 18.99v-1.98zm6 0c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98zm6 0c0-.558.443-1.01 1.01-1.01h1.98c.558 0 1.01.443 1.01 1.01v1.98c0 .558-.443 1.01-1.01 1.01h-1.98c-.558 0-1.01-.443-1.01-1.01v-1.98z"></path></svg>
												</span>
											</div>
											<div class="css-v2uvap"></div>
										</button>
										<ul class="dropdown-menu float-end">
											<!--<li><h6 class="dropdown-header">header</h6></li>-->
											<li><a class="dropdown-item" href="//cms.bylex.su">CMS</a></li>
											<li><a class="dropdown-item" href="//cab.bylex.su">Cabinet</a></li>
											<!--<li><hr class="dropdown-divider"></li>-->
										</ul>
									</div>
								</div>
							</div>
							<div class="css-2rlxtj">
								<div role="presentation" class="css-7xjigh">
									<div class="css-1baulvz btn-group dropend">
										<button class="global-navigation-item" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" content="Помощь" v-tippy="{placement: 'right'}">
											<div class="css-1ixbp0l">
												<span class="eHDVBw">
													<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><g fill-rule="evenodd"><circle fill="currentColor" cx="12" cy="12" r="10"></circle><circle fill="inherit" cx="12" cy="18" r="1"></circle><path d="M15.89 9.05a3.975 3.975 0 0 0-2.957-2.942C10.321 5.514 8.017 7.446 8 9.95l.005.147a.992.992 0 0 0 .982.904c.552 0 1-.447 1.002-.998a2.004 2.004 0 0 1 4.007-.002c0 1.102-.898 2-2.003 2H12a1 1 0 0 0-1 .987v2.014a1.001 1.001 0 0 0 2.004 0v-.782c0-.217.145-.399.35-.472A3.99 3.99 0 0 0 15.89 9.05" fill="inherit"></path></g></svg>
												</span>
											</div>
											<div class="css-v2uvap"></div>
										</button>
										<ul class="dropdown-menu float-end">
											<li><h6 class="dropdown-header">ПОМОЩЬ</h6></li>
											<li><a class="dropdown-item" href="//help.bylex.su">База знаний (Wiki)</a></li>
											<li><a class="dropdown-item" href="//ticket.bylex.su">Служба поддержки</a></li>
											<li><h6 class="dropdown-header">ИНФОРМАЦИЯ</h6></li>
											<li><a class="dropdown-item" href="//blog.bylex.su">Блог</a></li>
											<li><h6 class="dropdown-header">LEGAL</h6></li>
											<li><a class="dropdown-item" href="//id.bylex.su/info/terms">Условия и правила исп.</a></li>
											<li><a class="dropdown-item" href="//id.bylex.su/info/privacy">Политика конфиденц.</a></li>
											<!--<li><hr class="dropdown-divider"></li>-->
										</ul>
									</div>
								</div>
							</div>
							<div class="css-2rlxtj" v-if="authModel.isAuth()">
								<div role="presentation" class="css-7xjigh">
									<div class="css-1baulvz btn-group dropend">
										<button class="global-navigation-item" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" content="Ваш профиль и настройки" v-tippy="{placement: 'right'}">
											<div class="css-1ixbp0l">
												<div style="display: inline-block; position: relative; outline: 0px; height: 28px; width: 28px;">
													<span class="edKWVb">
														<span role="img" style="background-color: transparent; background-position: center center; background-repeat: no-repeat; background-size: cover; border-radius: 50%; display: flex; flex: 1 1 100%; height: 100%; width: 100%;" :style="{backgroundImage: 'url(\''+authModel.getAvatarLink()+'\')'}"></span>
													</span>
												</div>
											</div>
										</button>
										<ul class="dropdown-menu float-end">
											<li><h6 class="dropdown-header">{{authModel.getFname().toUpperCase()}} {{authModel.getSname().toUpperCase()}}</h6></li>
											<!--<li><a class="dropdown-item" href="//platform.bsrv.su/profile">Профиль</a></li>-->
											<!--<li><a class="dropdown-item" href="//platform.bsrv.su/profile/settings">Настройки</a></li>-->
											<li><a class="dropdown-item" href="//id.bylex.su/settings">Настройки</a></li>
											<li><router-link :to="{path: '/logout'}" class="dropdown-item">Выйти</router-link></li>
											<!--<li><hr class="dropdown-divider"></li>-->
										</ul>
									</div>
								</div>
							</div>
							<div class="css-2rlxtj" v-else>
								<div role="presentation" class="css-7xjigh">
									<div class="css-1baulvz btn-group dropend">
										<button class="global-navigation-item" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" content="Вход или регистрация" v-tippy="{placement: 'right'}">
											<div class="css-1ixbp0l">
												<div style="display: inline-block; position: relative; outline: 0px; height: 28px; width: 28px;">
													<span class="styled-cache-styled-span">
														<svg viewBox="0 0 128 128" version="1.1" xmlns="http://www.w3.org/2000/svg" role="img" style="background-color: transparent; border-radius: 50%; height: 100%; width: 100%;"><g class="avatar-image-shape-group" mode="light"><circle cx="64" cy="64" r="64"></circle><g><path d="M103,102.1388 C93.094,111.92 79.3504,118 64.1638,118 C48.8056,118 34.9294,111.768 25,101.7892 L25,95.2 C25,86.8096 31.981,80 40.6,80 L87.4,80 C96.019,80 103,86.8096 103,95.2 L103,102.1388 Z"></path><path d="M63.9961647,24 C51.2938136,24 41,34.2938136 41,46.9961647 C41,59.7061864 51.2938136,70 63.9961647,70 C76.6985159,70 87,59.7061864 87,46.9961647 C87,34.2938136 76.6985159,24 63.9961647,24"></path></g></g></svg>
													</span>
												</div>
											</div>
										</button>
										<ul class="dropdown-menu float-start">
											<!--<li><h6 class="dropdown-header">header</h6></li>-->
											<li><router-link :to="{path: '/login'}" class="dropdown-item">Войти</router-link></li>
											<li><a class="dropdown-item" href="//id.bylex.su/register">Регистрация</a></li>
											<!--<li><hr class="dropdown-divider"></li>-->
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<left-menu v-if="appModel.enableLeftMenu"></left-menu>
			</div>
		</div>
		<div>
			<div class="styled"></div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

import LeftMenu from '@/components/LeftMenu.vue';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {
		visibleItems(arr){
			return arr.length > 0 ? arr.filter(i => i.visible) : [];
		},
	},
	components: {
		LeftMenu,
	},
}
</script>
<template>
	<tasks-list v-if="view == 'list'" />
	<tasks-board v-if="view == 'board'" />
	<tasks-week v-if="view == 'week'" />
	<tasks-month v-if="view == 'month'" />
	<tasks-gantt v-if="view == 'gantt'" />
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import TasksList from '@/components/pages/tasks/TasksList.vue';
import TasksBoard from '@/components/pages/tasks/TasksBoard.vue';
import TasksWeek from '@/components/pages/tasks/TasksWeek.vue';
import TasksMonth from '@/components/pages/tasks/TasksMonth.vue';
import TasksGantt from '@/components/pages/tasks/TasksGantt.vue';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Задачи | Tasker'});
	},
	data: () => ({
		view: 'list',
	}),
	methods: {},
	async mounted(){
		window.scrollTo(0, 0);
		
		this.appModel.doSetLeftMenu1(this.$route.params.virtualspace_id);
		
		this.appModel.enableBreadcrumbs = true;
		this.appModel.breadcrumbs = [
			{
				title: 'Tasker',
				tooltip: '',
				link_type: 'router-link', // router-link, a
				onclick: () => {},
				href: {path: '/'},
				icon: '',
			},
			{
				title: this.authModel.getVirtualSpace(this.$route.params.virtualspace_id).virtualspace_name,
				tooltip: '',
				link_type: 'router-link', // router-link, a
				onclick: () => {},
				href: {path: '/vs/'+this.$route.params.virtualspace_id},
				icon: '',
			},
			{
				title: 'Задачи',
				tooltip: '',
				link_type: 'router-link', // router-link, a
				onclick: () => {},
				href: {path: '/vs/'+this.$route.params.virtualspace_id+'/tasks'},
				icon: '',
			},
		];
		
		if(this.$route.query.view) this.view = this.$route.query.view;
		else {
			this.$router.push({query: Object.assign({}, this.$route.query, {
				view: this.view,
			})});
		}
	},
	async beforeRouteUpdate(to, from, next){
		if(!to.query.view){
			this.$router.push({query: Object.assign({}, this.$route.query, {
				view: 'list',
			})});
		}
		else if(to.query.view != this.view) this.view = to.query.view;
		window.scrollTo(0, 0);
		next();
	},
	computed: {},
	components: {
		TasksList,
		TasksBoard,
		TasksWeek,
		TasksMonth,
		TasksGantt,
	},
}
</script>
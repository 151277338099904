<template>
	<div class="container">
		<div class="row">
			<div class="col-6">
				<h3>Draggable 1</h3>
				<draggable
					class="list-group"
					:list="list1"
					group="people"
					:data-status-id="1"
					:data-name="list1"
					:move="onMove"
					@change="log"
					item-key="name"
				>
					<div class="list-group-item" v-for="(element, index) in list1">{{ element.name }} {{ index }}</div>
				</draggable>
			</div>
			<div class="col-6">
				<h3>Draggable 2</h3>
				<draggable
					class="list-group"
					:list="list2"
					group="people"
					:data-status-id="2"
					:data-name="list2"
					:move="onMove"
					@change="log"
					item-key="name"
				>
					<div class="list-group-item" v-for="(element, index) in list2">{{ element.name }} {{ index }}</div>
				</draggable>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';
import { VueDraggableNext } from 'vue-draggable-next';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Test | Tasker'});
	},
	data: () => ({
		list: [
			{ status_id: 1, name: "John", id: 1 },
			{ status_id: 1, name: "Joao", id: 2 },
			{ status_id: 1, name: "Jean", id: 3 },
			{ status_id: 1, name: "Gerard", id: 4 },
			{ status_id: 2, name: "Juan", id: 5 },
			{ status_id: 2, name: "Edgard", id: 6 },
			{ status_id: 2, name: "Johnson", id: 7 },
		],
		list1: [],
		list2: [],
		/*list1: [
			{ name: "John", id: 1 },
			{ name: "Joao", id: 2 },
			{ name: "Jean", id: 3 },
			{ name: "Gerard", id: 4 },
		],
		list2: [
			{ name: "Juan", id: 5 },
			{ name: "Edgard", id: 6 },
			{ name: "Johnson", id: 7 },
		],*/
		move_status_id: '',
	}),
	computed: {},
	methods: {
		onMove(evt){
			console.log(
				'move from section: ' +
				evt.from.dataset.name +
				' index: ' +
				evt.draggedContext.index +
				' to section: ' +
				evt.to.dataset.name +
				' index: ' +
				evt.draggedContext.futureIndex
			);
			this.move_status_id = parseInt(evt.to.dataset.statusId);
		},
		log(evt, originalEvent){
			if(Object.keys(evt).includes('added')){
				evt.added.element.status_id = this.move_status_id;
				this.move_status_id = '';
			}
			console.log(evt);
		},
	},
	mounted(){
		window.scrollTo(0, 0);
		
		this.list1 = this.list.filter((el) => el.status_id == 1);
		this.list2 = this.list.filter((el) => el.status_id == 2);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		//Navbar,
		draggable: VueDraggableNext,
	},
}
</script>
<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal fade show" content-class="modal-dialog" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onAdd(close)">
			<div class="modal-header py-2">
				<h1 class="modal-title fs-5">Добавление тега задач</h1>
				<button type="button" class="btn-close" @click="$emit('cancel', close)" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="text-center" v-if="loading">
					<loading-spinner
						:overlay="loading"
						text="ЗАГРУЗКА ДАННЫХ..."
					></loading-spinner>
				</div>
				<div v-else>
					<div class="mb-3">
						<label for="input-name" class="form-label">Наименование:</label>
						<input id="input-name" ref="name" type="text" placeholder="Например: Тег" class="form-control" v-model="name" required />
					</div>
				</div>
				<div class="alert alert-warning alert-dismissible fade show mt-2 mb-0" v-if="error">
					{{error}}
					<button type="button" class="btn-close" aria-label="Close" @click="error = ''"></button>
				</div>
			</div>
			<div class="modal-footer p-1">
				<button type="submit" class="btn btn-primary" :disabled="loading">Добавить</button>
				<button type="button" class="btn btn-light" @click="$emit('cancel', close)">Отмена</button>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';

import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		name: '',
		
		error: '',
		loading: false,
	}),
	methods: {
		async onAdd(close){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doAddTag(this.$vfm.modals[0].params._value.virtualspace_id, {
				name: this.name,
			});
			if(ret.success){
				this.$emit('add', close);
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
	},
	async mounted(){
		setTimeout(() => {
			this.$refs.name.focus();
		}, 300);
	},
	components: {
		LoadingSpinner,
	},
}
</script>
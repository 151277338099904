<template>
	<div class="alert alert-warning text-center fade show mt-2" role="alert">
		В разработке!
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({}),
	computed: {},
	methods: {},
	async mounted(){
		window.scrollTo(0, 0);
		
		this.appModel.titleCabinet.icon.type = '';
		this.appModel.titleCabinet.icon.icon = '';
		this.appModel.titleCabinet.title = 'Задачи (месяц)';
		this.appModel.titleCabinet.buttons = [
			{
				type: 'group-btn', // btn, group-btn
				buttons: [
					{
						type: 'btn', // btn, group-btn
						title: '<i class="fa fa-list"></i>',
						tooltip: 'Список',
						link_type: 'router-link', // router-link, a, div
						onclick: () => {},
						href: {path: '/vs/'+this.$route.params.virtualspace_id+'/tasks', query: {view: 'list'}},
						target_blank: false,
						visible: true,
						selected: false,
						btnclass: 'btn-light',
						class: '',
						subitems: [],
					},
					{
						type: 'btn', // btn, group-btn
						title: '<i class="fa fa-columns"></i>',
						tooltip: 'Доска',
						link_type: 'router-link', // router-link, a, div
						onclick: () => {},
						href: {path: '/vs/'+this.$route.params.virtualspace_id+'/tasks', query: {view: 'board'}},
						target_blank: false,
						visible: true,
						selected: false,
						btnclass: 'btn-light',
						class: '',
						subitems: [],
					},
					{
						type: 'btn', // btn, group-btn
						title: '<i class="fa fa-calendar-week"></i>',
						tooltip: 'Неделя',
						link_type: 'router-link', // router-link, a, div
						onclick: () => {},
						href: {path: '/vs/'+this.$route.params.virtualspace_id+'/tasks', query: {view: 'week'}},
						target_blank: false,
						visible: true,
						selected: false,
						btnclass: 'btn-light',
						class: '',
						subitems: [],
					},
					{
						type: 'btn', // btn, group-btn
						title: '<i class="fa fa-calendar-alt"></i>',
						tooltip: 'Месяц',
						link_type: 'router-link', // router-link, a, div
						onclick: () => {},
						href: {path: '/vs/'+this.$route.params.virtualspace_id+'/tasks', query: {view: 'month'}},
						target_blank: false,
						visible: true,
						selected: true,
						btnclass: 'btn-light',
						class: '',
						subitems: [],
					},
					{
						type: 'btn', // btn, group-btn
						title: '<svg width="16" height="16" viewBox="0 0 16 16" focusable="false" role="presentation"><path fill="currentColor" fill-rule="evenodd" d="M0,2.4C0,2.4,0.1,2.4,0,2.4c0.1,0,0.1,0,0.2,0c2,0,4.1,0,6.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1 c0-0.7,0-1.4,0-2.1c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0C7,0,7.4,0,7.9,0C7.9,0,8,0,8,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1 c0,5.2,0,10.5,0,15.7c0,0,0,0.1,0,0.1c0,0,0,0,0,0c-0.2,0-1.5,0-1.5,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-1,0-1.9,0-2.9c0,0,0-0.1,0-0.1 c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-1,0-1.9,0-2.9,0c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c0-0.3,0-0.6,0-1c0-0.4,0-0.8,0-1.2 c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c1,0,2,0,2.9,0c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3 c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-1.5,0-3,0-4.5,0c0,0-0.1,0-0.1,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1 c0-0.7,0-1.4,0-2.2c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c1.5,0,3,0,4.5,0c0,0,0.1,0,0.1,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1 c0-0.5,0-0.9,0-1.4c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-2,0-4.1,0-6.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0-0.1 C0,4.4,0,2.5,0,2.4z"/><path fill="currentColor" fill-rule="evenodd" d="M9.6,12.8c0-0.1,0-1.7,0-2.3c0,0,0,0,0-0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0c2,0,4.1,0,6.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1 c0,0,0,0,0,0.1c0,0.7,0,1.4,0,2.1c0,0,0,0.1,0,0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-2,0-4.1,0-6.1,0C9.7,12.8,9.7,12.8,9.6,12.8z"/><path fill="currentColor" fill-rule="evenodd" d="M13.6,8.8C13.6,8.8,13.6,8.8,13.6,8.8c-1.4,0-2.7,0-4,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0-0.7,0-1.4,0-2.1 c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0c0.3,0,3.8,0,3.9,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.7,0,1.4,0,2.2C13.6,8.7,13.6,8.7,13.6,8.8z"/><path fill="currentColor" fill-rule="evenodd" d="M12,4.8c-0.1,0-2,0-2.3,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.7,0-1.5,0-2.2c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0 c0,0,0,0,0.1,0c0.7,0,1.4,0,2.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.7,0,1.4,0,2.2C12,4.7,12,4.8,12,4.8z"/></svg>',
						tooltip: 'Гант',
						link_type: 'router-link', // router-link, a, div
						onclick: () => {},
						href: {path: '/vs/'+this.$route.params.virtualspace_id+'/tasks', query: {view: 'gantt'}},
						target_blank: false,
						visible: true,
						selected: false,
						btnclass: 'btn-light',
						class: '',
						subitems: [],
					},
				],
			},
		];
	},
	components: {},
}
</script>
<template>
	<div style="margin-top: 50px;" class="text-center">
		<div style="font-size: 200px; font-weight: bold;">404</div>
		<div style="font-size: 30px;">Страница либо файл не существует</div>
		<router-link :to="{path: '/'}" class="btn btn-lg btn-primary mt-5">На главную</router-link>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Ошибка 404 | Tasker'});
	},
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){
		window.scrollTo(0, 0);
		
		this.appModel.enableMainLeftMenu = true;
		this.appModel.enableLeftMenu = false;
		this.appModel.enableSidebar = false;
		this.appModel.enableBreadcrumbs = false;
		
		this.appModel.titleCabinet.icon.type = '';
		this.appModel.titleCabinet.icon.icon = '';
		this.appModel.titleCabinet.title = '';
		this.appModel.titleCabinet.buttons = [];
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {},
}
</script>
<template>
	<div class="row">
		<div class="col-md-8">
			<div class="card">
				<div class="card-header bg-white">
					<div class="float-end">
						<div class="badge bg-secondary me-2">{{filter.length}}</div>
						<a class="btn btn-sm btn-white" @click="filterShow = !filterShow">
							<i class="fa" :class="{'fa-chevron-down': !filterShow, 'fa-chevron-up': filterShow}"></i>
						</a>
					</div>
					<div class="mt-1">Фильтр</div>
				</div>
				<div class="card-body" v-if="filterShow">
					<div class="input-group mb-3" v-for="(fi, index) in filter">
						<label class="input-group-text">{{getStructParam(fi.field).label}}</label>
						<select class="form-select" v-model="fi.operator" style="max-width: 130px;">
							<option :value="op.operator" v-for="op in getFilterOperatorsByType(getStructParam(fi.field).type).operators">{{op.label}}</option>
						</select>
						<input type="text" class="form-control" v-model="fi.value" v-if="getStructParam(fi.field).type == 'input' || getStructParam(fi.field).type == 'text' || getStructParam(fi.field).type == 'phonenumber'" />
						<input type="number" class="form-control" @wheel="$event.target.blur()" v-model.number="fi.value" v-if="getStructParam(fi.field).type == 'number'" />
						<input type="date" class="form-control" v-model="fi.value" v-if="getStructParam(fi.field).type == 'date'" />
						<input type="datetime-local" class="form-control" v-model="fi.value" v-if="getStructParam(fi.field).type == 'datepicker'" />
						<input type="email" class="form-control" v-model="fi.value" v-if="getStructParam(fi.field).type == 'email'" />
						<select class="form-select" v-model="fi.value" v-if="getStructParam(fi.field).type == 'select' || getStructParam(fi.field).type == 'boolean'">
							<option value=""></option>
							<option :value="opt.value" v-for="opt in getStructParam(fi.field).options">{{opt.label}}</option>
						</select>
						<div class="btn btn-danger" @click="onDelFilterParam(index)" content="Удалить параметр фильтра" v-tippy="{placement: 'top'}">
							<i class="fa fa-times"></i>
						</div>
					</div>
					<div class="input-group">
						<label class="input-group-text">Фильтр по</label>
						<select class="form-select" v-model="filterAddField" @change="onAddFilterParam">
							<option value="" disabled="disabled"></option>
							<option :value="elem.field" v-for="elem in structParamsFilter">{{elem.label}}</option>
						</select>
						<button type="button" class="btn btn-primary" :disabled="loading" content="Применить фильтр" v-tippy="{placement: 'top'}" @click="page = 1; loadData();">
							<i class="fa" :class="{'fa-check': !loading, 'fa-spinner fa-spin': loading}"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-4">
			<div class="card">
				<div class="card-header bg-white">
					<div class="float-end">
						<div class="badge bg-secondary me-2">{{sort.length}}</div>
						<a class="btn btn-sm btn-white" @click="sortShow = !sortShow">
							<i class="fa" :class="{'fa-chevron-down': !sortShow, 'fa-chevron-up': sortShow}"></i>
						</a>
					</div>
					<div class="mt-1">Сортировка</div>
				</div>
				<div class="card-body" v-if="sortShow">
					<div class="input-group mb-3" v-for="(si, index) in sort">
						<label class="input-group-text">{{getStructParam(si.field).label}}</label>
						<select class="form-select" v-model="si.direction" @change="page = 1; loadData();">
							<option value="asc">а-я</option>
							<option value="desc">я-а</option>
						</select>
						<div class="btn btn-danger" :class="{'btn-block m-t': $device.isMobile}" @click="onDelSortParam(index)" content="Удалить параметр сортировки" v-tippy="{placement: 'top'}">
							<i class="fa fa-times"></i>
						</div>
					</div>
					<div class="input-group">
						<label class="input-group-text">Сортировка по</label>
						<select class="form-select" v-model="sortAddField" @change="onAddSortParam">
							<option value="" disabled="disabled"></option>
							<option :value="elem.field" v-for="elem in structParamsSort">{{elem.label}}</option>
						</select>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="alert alert-warning alert-dismissible fade show mt-2" role="alert" v-if="error">
		{{error}}
		<button type="button" class="btn-close" aria-label="Close" @click="error = ''"></button>
	</div>
	<div class="table-responsive mt-2">
		<table class="table table-hover" :class="{'table-light': loading}">
			<thead>
				<tr>
					<th style="min-width: 400px;">Наименование</th>
					<th style="min-width: 150px;" width="150" class="text-end">Операции</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="documentTemplate in documentTemplates" :key="documentTemplate.id">
					<td class="align-middle">
						<i class="fa fa-genderless me-2"></i>
						<span>{{documentTemplate.name}}</span>
					</td>
					<td class="text-end align-middle">
						<div class="btn-group ms-1">
							<button type="button" @click="onShowEditModal(documentTemplate.id)" class="btn btn-primary" content="Изменить шаблон задач" v-tippy="{placement: 'top'}">
								<i class="fa fa-edit"></i>
							</button>
							<button type="button" @click="onShowDeleteModal(documentTemplate.id)" class="btn btn-danger" content="Удалить шаблон задач цель" v-tippy="{placement: 'top'}">
								<i class="fa fa-times"></i>
							</button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="text-center mt-2">
		<nav aria-label="Page navigation">
			<ul class="pagination justify-content-center mb-0">
				<li class="page-item" :class="{'disabled': page == 1}">
					<a class="page-link" href="javascript://" @click="gotoPage(page - 1)" aria-label="Previous">
						<i class="fa fa-chevron-left"></i>
					</a>
				</li>
				<li class="page-item" :class="{'active': page == 1}">
					<a class="page-link" href="javascript://" @click="gotoPage(1)">1</a>
				</li>
				<li class="page-item disabled" v-if="page - paginationAlpha > 2">
					<span class="page-link">...</span>
				</li>
				<li class="page-item" :class="{'active': page == i}" v-for="i in range(paginationA1, paginationA2 + 1)" :key="i" v-if="paginationA2 >= paginationA1">
					<a class="page-link" href="javascript://" @click="gotoPage(i)">{{i}}</a>
				</li>
				<li class="page-item disabled" v-if="page + paginationAlpha < getPagesCount() - 1">
					<span class="page-link">...</span>
				</li>
				<li class="page-item" :class="{'active': page == getPagesCount()}" v-if="getPagesCount() > 1">
					<a class="page-link" href="javascript://" @click="gotoPage(getPagesCount())">{{getPagesCount()}}</a>
				</li>
				<li class="page-item" :class="{'disabled': page == getPagesCount()}">
					<a class="page-link" href="javascript://" @click="gotoPage(page + 1)" aria-label="Next">
						<i class="fa fa-chevron-right"></i>
					</a>
				</li>
			</ul>
		</nav>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import DocumentTemplateAddModal from '@/components/modals/settings/documentTemplates/DocumentTemplateAddModal.vue';
import DocumentTemplateEditModal from '@/components/modals/settings/documentTemplates/DocumentTemplateEditModal.vue';
import DocumentTemplateDeleteModal from '@/components/modals/settings/documentTemplates/DocumentTemplateDeleteModal.vue';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Шаблоны задач | Tasker'});
	},
	data: () => ({
		structParams: [],
		
		documentTemplates: [],
		documentTemplates_count: 0,
		
		search: '',
		
		filter: [],
		filterAddField: '',
		filterShow: false,
		
		sort: [
			{field: 'name', direction: 'asc'},
		],
		sortAddField: '',
		sortShow: false,
		
		page: 1,
		limit: 25,
		loading: true,
		error: '',
		
		paginationAlpha: 3,
	}),
	computed: {
		structParamsFilter(){
			return this.structParams.filter((el) => el.filter);
		},
		structParamsSort(){
			return this.structParams.filter((el) => el.sort && !this.sort.map(el => el.field).includes(el.field));
		},
		
		paginationA1(){
			return Math.max(this.page - this.paginationAlpha, 2);
		},
		paginationA2(){
			return Math.min(this.page + this.paginationAlpha, this.getPagesCount() - 1);
		},
	},
	methods: {
		range: lib.range,
		
		async loadData(){
			this.loading = true;
			this.error = '';
			this.$router.push({query: Object.assign({}, this.$route.query, {
				filter: JSON.stringify(this.filter),
				sort: JSON.stringify(this.sort),
				page: this.page,
			})});
			let params = {
				start: (this.page - 1) * this.limit,
				limit: this.limit,
			};
			for(let [index, el] of Object.entries(this.filter)){
				let el_val = el.value;
				if(el.operator == 'like') el_val = '%'+el.value+'%';
				params['filter['+index+'][field]'] = el.field;
				params['filter['+index+'][operator]'] = el.operator;
				params['filter['+index+'][value]'] = el_val;
			}
			for(let [index, el] of Object.entries(this.sort)){
				params['sort['+index+'][field]'] = el.field;
				params['sort['+index+'][direction]'] = el.direction;
			}
			let ret = await this.appModel.doGetDocumentTemplates(this.$route.params.virtualspace_id, params);
			if(ret.success){
				this.documentTemplates = ret.list;
				this.documentTemplates_count = ret.count;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		onShowAddModal(){
			let that = this;
			this.$vfm.show({
				component: DocumentTemplateAddModal,
				bind: {
					styles: {
						display: 'block',
					},
				},
				on: {
					add(close){
						that.loadData();
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					virtualspace_id: this.$route.params.virtualspace_id,
				},
			});
		},
		onShowEditModal(id){
			let that = this;
			this.$vfm.show({
				component: DocumentTemplateEditModal,
				bind: {
					styles: {
						display: 'block',
					},
				},
				on: {
					edit(close){
						that.loadData();
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					virtualspace_id: this.$route.params.virtualspace_id,
					documentTemplate: that.documentTemplates.find((el) => el.id == id),
				},
			});
		},
		onShowDeleteModal(id){
			let that = this;
			this.$vfm.show({
				component: DocumentTemplateDeleteModal,
				bind: {
					styles: {
						display: 'block',
					},
				},
				on: {
					delete(close){
						that.loadData();
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					virtualspace_id: this.$route.params.virtualspace_id,
					documentTemplate: that.documentTemplates.find((el) => el.id == id),
				},
			});
		},
		
		getStructParam(field){
			return this.structParams.find((el) => el.field == field);
		},
		getFilterOperatorsByType(type){
			let operators = [], def = '';
			if(type == 'input' || type == 'text' || type == 'email' || type == 'phonenumber'){
				operators = [
					{operator: 'like', label: 'Содержит'},
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
				];
				if(type == 'phonenumber'){
					def = '=';
				} else {
					def = 'like';
				}
			} else if(type == 'select' || type == 'boolean'){
				operators = [
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
				];
				def = '=';
			} else if(type == 'number' || type == 'datepicker'){
				operators = [
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
					{operator: '>', label: 'Больше'},
					{operator: '>=', label: 'Больше или равно'},
					{operator: '<', label: 'Меньше'},
					{operator: '<=', label: 'Меньше или равно'},
				];
				def = '=';
			} else if(type == 'date'){
				operators = [
					{operator: 'like', label: 'Равно'},
					{operator: 'not like', label: 'Не равно'},
					{operator: '>', label: 'Больше'},
					{operator: '>=', label: 'Больше или равно'},
					{operator: '<', label: 'Меньше'},
					{operator: '<=', label: 'Меньше или равно'},
				];
				def = 'like';
			}
			return {
				operators,
				def,
			};
		},
		onAddFilterParam(){
			if(this.filterAddField != ''){
				this.filter.push({
					field: this.filterAddField,
					operator: this.getFilterOperatorsByType(this.getStructParam(this.filterAddField).type).def,
					value: '',
				});
				this.filterAddField = '';
			}
		},
		onDelFilterParam(index){
			this.filter.splice(index, 1);
		},
		onAddSortParam(){
			if(this.sortAddField != ''){
				this.sort.push({
					field: this.sortAddField,
					direction: 'asc',
				});
				this.sortAddField = '';
				this.page = 1;
				this.loadData();
			}
		},
		onDelSortParam(index){
			this.sort.splice(index, 1);
			this.page = 1;
			this.loadData();
		},
		
		gotoPage(page){
			if(page >= 1 && page <= this.getPagesCount() && page != this.page){
				this.page = page;
				this.loadData();
				window.scrollTo(0, 0);
			}
		},
		// получение количества страниц
		getPagesCount(){
			return Math.ceil(this.documentTemplates_count / this.limit);
		},
	},
	mounted(){
		window.scrollTo(0, 0);
		
		this.appModel.doSetLeftMenu1(this.$route.params.virtualspace_id);
		
		this.appModel.enableBreadcrumbs = true;
		this.appModel.breadcrumbs = [
			{
				title: 'Tasker',
				tooltip: '',
				link_type: 'router-link', // router-link, a, span
				onclick: () => {},
				href: {path: '/'},
				icon: '',
			},
			{
				title: this.authModel.getVirtualSpace(this.$route.params.virtualspace_id).virtualspace_name,
				tooltip: '',
				link_type: 'router-link', // router-link, a, span
				onclick: () => {},
				href: {path: '/vs/'+this.$route.params.virtualspace_id},
				icon: '',
			},
			{
				title: 'Настройки',
				tooltip: '',
				link_type: 'router-link', // router-link, a, span
				onclick: () => {},
				href: {path: '/vs/'+this.$route.params.virtualspace_id+'/settings'},
				icon: '',
			},
			{
				title: 'Шаблоны задач',
				tooltip: '',
				link_type: 'router-link', // router-link, a, span
				onclick: () => {},
				href: {path: '/vs/'+this.$route.params.virtualspace_id+'/settings/document-templates'},
				icon: '',
			},
		];
		
		this.appModel.titleCabinet.icon.type = '';
		this.appModel.titleCabinet.icon.icon = '';
		this.appModel.titleCabinet.title = 'Шаблоны задач';
		this.appModel.titleCabinet.buttons = [
			{
				type: 'btn', // btn, group-btn
				title: 'Добавить',
				tooltip: 'Добавить шаблон задач',
				link_type: 'div', // router-link, a, div
				onclick: () => {
					this.onShowAddModal();
				},
				href: '',
				target_blank: false,
				visible: true,
				selected: false,
				btnclass: 'btn-primary',
				class: '',
				subitems: [],
			},
		];
		
		this.structParams = [
			{
				type: 'input',
				field: 'name',
				label: 'Наименование',
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 'description',
				label: 'Описание',
				filter: true,
				sort: true,
			},
			/*{
				type: 'number',
				field: 'lai.id',
				label: 'ID ремонта',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'lai.client_id',
				label: 'ID клиента',
				filter: true,
				sort: false,
			},
			{
				type: 'input',
				field: 'lcl.fio',
				label: 'ФИО клиента',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'lai.instrument_type_id',
				label: 'Тип инструмента',
				filter: false,
				sort: true,
			},
			{
				type: 'number',
				field: 'lai.instrument_size_id',
				label: 'Размер инструмента',
				filter: false,
				sort: true,
			},
			{
				type: 'input',
				field: 'lai.instrument_serial_number',
				label: 'Серийный номер инструмента',
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 'lai.instrument_color',
				label: 'Цвет инструмента',
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 'lai.instrument_model',
				label: 'Модель инструмента',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'lai.amount_paid',
				label: 'Предполагаемая сумма оплаты',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'lai.amount_services',
				label: 'Сумма за услуги',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'lai.amount_materials',
				label: 'Сумма за материалы',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'ls.discount',
				label: 'Сумма скидки',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'lai.fullamount',
				label: 'Общая сумма',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 'lai.amount_payed',
				label: 'Внесенная сумма',
				filter: true,
				sort: true,
			},
			{
				type: 'date',
				field: 'lai.dateadd',
				label: 'Дата добавления (начала)',
				filter: true,
				sort: true,
			},
			{
				type: 'date',
				field: 'lai.dateend',
				label: 'Дата завершения',
				filter: true,
				sort: true,
			},*/
		];
		
		if(this.$route.query.page) this.page = parseInt(this.$route.query.page || 1);
		if(this.$route.query.filter) this.filter = JSON.parse(this.$route.query.filter || '[]');
		if(this.$route.query.sort) this.sort = JSON.parse(this.$route.query.sort || '[]');
		
		this.loadData();
	},
	beforeRouteUpdate(to, from, next){
		window.scrollTo(0, 0);
		next();
	},
	components: {},
}
</script>
<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal fade show" content-class="modal-dialog modal-xl" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onEdit(close)">
			<div class="modal-header py-2">
				<h1 class="modal-title fs-5">Задача #{{id}}</h1>
				<button type="button" class="btn-close" @click="$emit('cancel', close)" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="text-center" v-if="loading">
					<loading-spinner
						:overlay="loading"
						text="ЗАГРУЗКА ДАННЫХ..."
					></loading-spinner>
				</div>
				<div v-else>
					<div class="row">
						<div class="col-8">
							<div class="mb-3">
								<textarea ref="title" placeholder="Заголовок задачи" class="form-control" v-model="title" rows="1" required></textarea>
							</div>
							<div class="mb-3">
								<div class="card">
									<div class="card-header bg-white">
										<ul class="nav nav-tabs card-header-tabs">
											<li class="nav-item">
												<a class="nav-link" :class="{'active': tab1 == 'description'}" @click="tab1 = 'description'" href="javascript://">Описание</a>
											</li>
											<li class="nav-item">
												<a class="nav-link" :class="{'active': tab1 == 'solutions'}" @click="tab1 = 'solutions'" href="javascript://">
													<span>Пути решения</span>
													<i class="far ms-1" :class="{'fa-circle': !solutions, 'fa-check-circle': solutions}"></i>
												</a>
											</li>
											<li class="nav-item">
												<a class="nav-link" :class="{'active': tab1 == 'result_plan'}" @click="tab1 = 'result_plan'" href="javascript://">
													<span>Результат по плану</span>
													<i class="far ms-1" :class="{'fa-circle': !result_plan, 'fa-check-circle': result_plan}"></i>
												</a>
											</li>
											<li class="nav-item">
												<a class="nav-link" :class="{'active': tab1 == 'result_fact'}" @click="tab1 = 'result_fact'" href="javascript://">
													<span>Результат по факту</span>
													<i class="far ms-1" :class="{'fa-circle': !result_fact, 'fa-check-circle': result_fact}"></i>
												</a>
											</li>
										</ul>
									</div>
									<div class="card-body" v-show="tab1 == 'description'">
										<textarea ref="description" rows="6" class="form-control" v-model="description"></textarea>
									</div>
									<div class="card-body" v-show="tab1 == 'solutions'">
										<textarea ref="solutions" rows="6" class="form-control" v-model="solutions"></textarea>
									</div>
									<div class="card-body" v-show="tab1 == 'result_plan'">
										<textarea ref="result_plan" rows="6" class="form-control" v-model="result_plan"></textarea>
									</div>
									<div class="card-body" v-show="tab1 == 'result_fact'">
										<textarea ref="result_fact" rows="6" class="form-control" v-model="result_fact"></textarea>
									</div>
								</div>
							</div>
							<div class="mb-3" v-for="cf in custom_fields" :key="cf.id">
								<div class="input-group" v-if="cf.type == 'text'">
									<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
									<input type="text" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values[cf.id]" />
								</div>
								<div class="input-group" v-if="cf.type == 'textarea'">
									<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
									<textarea :id="'item-custom-field-'+cf.id" rows="3" class="form-control" v-model="custom_fields_values[cf.id]"></textarea>
								</div>
								<div class="input-group" v-if="cf.type == 'checkbox'">
									<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
									<input type="checkbox" :id="'item-custom-field-'+cf.id" class="form-check-input" v-model="custom_fields_values[cf.id]" />
								</div>
								<div v-if="cf.type == 'number'">
									<!-- cf.data.decimal_places -->
									<div class="input-group">
										<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
										<input type="number" :id="'item-custom-field-'+cf.id" class="form-control" v-model.number="custom_fields_values[cf.id]" />
									</div>
								</div>
								<div v-if="cf.type == 'currency'">
									<!-- cf.data.decimal_places -->
									<div class="input-group">
										<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
										<span class="input-group-text">{{cf.data.symbol}}</span>
										<input type="number" :id="'item-custom-field-'+cf.id" class="form-control" v-model.number="custom_fields_values[cf.id]" />
									</div>
								</div>
								<div v-if="cf.type == 'percentage'">
									<!-- data.decimal_places -->
									<div class="input-group">
										<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
										<input type="number" :id="'item-custom-field-'+cf.id" class="form-control" v-model.number="custom_fields_values[cf.id]" />
									</div>
								</div>
								<div class="input-group" v-if="cf.type == 'date'">
									<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
									<input type="date" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values[cf.id]" />
								</div>
								<div class="input-group" v-if="cf.type == 'dropdown'">
									<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
									<select :id="'item-custom-field-'+cf.id" class="form-select" v-model="custom_fields_values[cf.id]">
										<option :value="option.text" :style="{backgroundColor: '#'+option.color}" v-for="option in sortedByPos(cf.data)">{{option.text}}</option>
									</select>
								</div>
								<div class="input-group" v-if="cf.type == 'multiselect'">
									<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
									<select :id="'item-custom-field-'+cf.id" multiple class="form-select" v-model="custom_fields_values[cf.id]">
										<option :value="option.text" :style="{backgroundColor: '#'+option.color}" v-for="option in sortedByPos(cf.data)">{{option.text}}</option>
									</select>
								</div>
								<div class="input-group" v-if="cf.type == 'rating'">
									<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}} ({{custom_fields_values[cf.id]}}):</label>
									<input type="range" :id="'item-custom-field-'+cf.id" min="0" :max="cf.data.scale" class="form-control form-range" v-model.number="custom_fields_values[cf.id]" />
								</div>
								<div class="input-group" v-if="cf.type == 'email'">
									<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
									<input type="email" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values[cf.id]" />
								</div>
								<div class="input-group" v-if="cf.type == 'phone'">
									<div class="input-group">
										<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
										<input type="text" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values[cf.id]" />
									</div>
								</div>
								<div class="input-group" v-if="cf.type == 'link'">
									<div class="input-group">
										<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
										<input type="text" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values[cf.id]" />
									</div>
								</div>
								<div v-if="cf.type == 'user'">
									<div class="input-group">
										<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
										<input type="number" :id="'item-custom-field-'+cf.id" class="form-control" v-model.number="custom_fields_values[cf.id]" />
									</div>
								</div>
							</div>
							<div class="mb-3">
								<div class="card">
									<div class="card-header">
										<div class="float-end" v-if="checklistEnable">
											<div class="form-check form-switch m-0 p-0">
												<input class="form-check-input ms-0" type="checkbox" role="switch" content="Скрывать отмеченные пункты" v-tippy="{placement: 'left'}" v-model="checklistHideChecked" />
											</div>
										</div>
										<span>Чек-лист</span>
										<small class="text-dark-gray ms-1" v-if="checklistEnable"> ({{checklist_checked.length}} из {{checklist.length}})</small>
									</div>
									<draggable class="list-group list-group-flush" :list="checklist" handle=".handle">
										<div class="list-group-item handle" :class="{'py-1': cli.edit}" v-for="cli in checklist_filtered" :key="cli.id">
											<div class="row align-items-center">
												<div class="col-1">
													<input :id="'checklist_item_'+cli.id" type="checkbox" class="form-check-input" v-model="cli.checked" @change="onCheckChecklistItem(cli.id)" :disabled="cli.edit_loading || cli.delete_loading" />
												</div>
												<div :class="{'col-9': !cli.edit, 'col-11': cli.edit, 'px-0': cli.edit}">
													<label :for="'checklist_item_'+cli.id" v-if="!cli.edit">
														<span v-if="!cli.checked">{{cli.name}}</span>
														<s v-if="cli.checked">{{cli.name}}</s>
													</label>
													<form v-if="cli.edit" @submit.prevent="onEditChecklistItem(cli.id)">
														<div class="input-group">
															<input class="form-control" v-model="cli.edit_name" required :disabled="cli.edit_loading" />
															<button class="btn btn-primary" type="submit" content="Сохранить изменения" v-tippy="{placement: 'top'}" :disabled="cli.edit_loading">
																<i class="fa" :class="{'fa-save': !cli.edit_loading, 'fa-spinner fa-spin': cli.edit_loading}"></i>
															</button>
															<button class="btn btn-secondary" type="button" @click="cli.edit = false" content="Отменить изменения" v-tippy="{placement: 'top'}" :disabled="cli.edit_loading">
																<i class="fa fa-times"></i>
															</button>
														</div>
													</form>
												</div>
												<div class="col-2 text-end" v-if="!cli.edit">
													<div class="btn-group btn-group-sm">
														<button type="button" class="btn btn-primary" content="Изменить пункт" v-tippy="{placement: 'top'}" @click="cli.edit_name = cli.name; cli.edit = true;" :disabled="cli.delete_loading">
															<i class="fa fa-edit"></i>
														</button>
														<button type="button" class="btn btn-danger" content="Удалить пункт" v-tippy="{placement: 'top'}" @click="onDelChecklistItem(cli.id)" :disabled="cli.delete_loading">
															<i class="fa" :class="{'fa-trash': !cli.delete_loading, 'fa-spinner fa-spin': cli.delete_loading}"></i>
														</button>
													</div>
												</div>
											</div>
										</div>
										<li class="list-group-item">
											<form @submit.prevent="onAddChecklistItem()">
												<div class="row align-items-center">
													<div class="col-1">
														<i class="fa fa-plus"></i>
													</div>
													<div class="col-11">
														<div class="input-group input-group-sm">
															<input type="text" class="form-control" placeholder="Название пункта" v-model="checklist_add_name" required :disabled="checklist_add_loading" />
															<button class="btn btn-primary" type="submit" content="Добавить пункт" v-tippy="{placement: 'top'}" :disabled="checklist_add_loading">
																<i class="fa" :class="{'fa-plus': !checklist_add_loading, 'fa-spinner fa-spin': checklist_add_loading}"></i>
															</button>
														</div>
													</div>
												</div>
											</form>
										</li>
									</draggable>
								</div>
							</div>
							<div class="mb-3">
								<div class="card">
									<div class="card-header">
										<ul class="nav nav-tabs card-header-tabs">
											<li class="nav-item">
												<a class="nav-link" :class="{'active': tab2 == 'comments'}" @click="tab2 = 'comments'" href="javascript://">
													<i class="fa fa-comments me-2"></i>
													<span>Комментарии</span>
													<span class="ms-1" v-if="!loadingComments">({{comments.length}})</span>
													<span class="ms-1" v-else>(<i class="fa fa-spinner fa-spin"></i>)</span>
												</a>
											</li>
											<li class="nav-item">
												<a class="nav-link" :class="{'active': tab2 == 'attachments'}" @click="tab2 = 'attachments'" href="javascript://">
													<span>Вложения</span>
													<span class="ms-1" v-if="!loadingAttachments">({{attachments.length}})</span>
													<span class="ms-1" v-else>(<i class="fa fa-spinner fa-spin"></i>)</span>
												</a>
											</li>
											<li class="nav-item">
												<a class="nav-link" :class="{'active': tab2 == 'actions'}" @click="tab2 = 'actions'" href="javascript://">
													<i class="fa fa-bars me-2"></i>
													Действия
												</a>
											</li>
										</ul>
									</div>
									<div class="card-body" v-show="tab2 == 'comments'">
										<form @submit.prevent="onAddComment()">
											<div class="input-group mb-3">
												<textarea ref="comment" placeholder="Напишите комментарий..." class="form-control" v-model="comment_add" rows="1" @keypress.ctrl.enter="$refs.btnAddComment.click()" required :disabled="comment_add_loading"></textarea>
												<button class="btn btn-primary" type="submit" ref="btnAddComment" content="Оставить комментарий" v-tippy="{placement: 'top'}" :disabled="comment_add_loading">
													<i class="fa" :class="{'fa-paper-plane': !comment_add_loading, 'fa-spinner fa-spin': comment_add_loading}"></i>
												</button>
											</div>
										</form>
										<div class="text-center" v-if="loadingComments">
											<loading-spinner
												:overlay="loadingComments"
												text=""
											></loading-spinner>
										</div>
										<div v-else>
											<div class="text-center" v-if="comments.length <= 0">Комментариев нет</div>
											<div class="d-flex text-muted" :class="{'pt-3': index != 0, 'pb-2': index != comments.length - 1, 'border-bottom': index != comments.length - 1}" v-for="(comment, index) in comments" :key="comment.id">
												<img :src="comment.user_avatar" class="avatar avatar-xs rounded-circle me-2" />
												<div class="lh-sm w-100">
													<div class="small">
														<div class="float-end" v-if="!comment.edit">
															<div class="btn-group btn-group-sm">
																<button type="button" class="btn btn-primary" content="Изменить комментарий" v-tippy="{placement: 'top'}" @click="comment.edit_text = comment.text; comment.edit = true;" v-if="comment.user_id == authModel.getUserId()" :disabled="comment.delete_loading">
																	<i class="fa fa-edit"></i>
																</button>
																<button type="button" class="btn btn-danger" content="Удалить комментарий" v-tippy="{placement: 'top'}" @click="onDelComment(comment.id)" :disabled="comment.delete_loading">
																	<i class="fa" :class="{'fa-trash': !comment.delete_loading, 'fa-spinner fa-spin': comment.delete_loading}"></i>
																</button>
															</div>
														</div>
														<strong>{{comment.user_fname}} {{comment.user_sname}} @{{comment.user_login}}</strong>
														<span>&nbsp;</span>
														<small class="text-dark-gray">
															<span>{{this.formatDate(comment.date_created, 'DD.MM.YYYY в HH:mm')}}</span>
															<span v-if="comment.date_created != comment.date_last_updated"> (изменен)</span>
														</small>
													</div>
													<div v-if="!comment.edit" v-html="comment.text"></div>
													<form v-if="comment.edit" @submit.prevent="onEditComment(comment.id)">
														<div class="input-group mt-1">
															<textarea class="form-control" v-model="comment.edit_text" rows="3" @keypress.ctrl.enter="$refs['btnEditComment'+comment.id].click()" style="resize: none;" required :disabled="comment.edit_loading"></textarea>
															<button class="btn btn-primary" type="submit" :ref="'btnEditComment'+comment.id" content="Сохранить изменения" v-tippy="{placement: 'top'}" :disabled="comment.edit_loading">
																<i class="fa" :class="{'fa-save': !comment.edit_loading, 'fa-spinner fa-spin': comment.edit_loading}"></i>
															</button>
															<button class="btn btn-secondary" type="button" @click="comment.edit = false" content="Отменить изменения" v-tippy="{placement: 'top'}" :disabled="comment.edit_loading">
																<i class="fa fa-times"></i>
															</button>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
									<div class="card-body" v-show="tab2 == 'attachments'">
										<div v-if="showAttachmentUpload">
											<div class="bigUploadContainer">
												<div class="position-absolute top-0 end-0">
													<button class="btn btn-sm btn-secondary" type="button" content="Скрыть форму загрузки файлов" v-tippy="{placement: 'top'}" @click="showAttachmentUpload = false">
														<i class="fa fa-times"></i>
													</button>
												</div>
												<h4>Загрузка файлов</h4>
												<big-upload
													inputFileAccept="image/*,audio/*,video/*,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.txt,.pdf,.rtf,.csv,.svg,capture=camera,capture=camcorder,capture=microphone,capture=filesystem"
													:scriptPath="'/api/tasks/'+id+'/attachments/upload-chunk'"
													:scriptPathParams="biguploadScriptPathParams"
													:onSuccess="onBiguploadAttachmentSuccess"
												></big-upload>
											</div>
											<hr/>
										</div>
										<div class="text-end mb-3" v-if="!showAttachmentUpload">
											<button class="btn btn-secondary" type="button" @click="showAttachmentUpload = true">
												<i class="fa fa-upload me-2"></i>
												<span>Загрузить</span>
											</button>
										</div>
										<div class="text-center" v-if="loadingAttachments">
											<loading-spinner
												:overlay="loadingAttachments"
												text=""
											></loading-spinner>
										</div>
										<div v-else>
											<div class="text-center" v-if="attachments.length <= 0">Вложений нет</div>
											<div class="row" v-else>
												<div class="col-4" v-for="attachment in attachments" :key="attachment.id">
													<div class="card mb-3 text-center">
														<div class="position-absolute end-0">
															<button type="button" class="btn btn-sm btn-danger" content="Удалить вложение" v-tippy="{placement: 'top'}" @click="onDelAttachment(attachment.id)" :disabled="attachment.delete_loading || attachment.delete_loading">
																<i class="fa" :class="{'fa-trash': !attachment.delete_loading, 'fa-spinner fa-spin': attachment.delete_loading}"></i>
															</button>
														</div>
														<div class="card-body p-0 text-gray d-flex align-items-center cursor-pointer" style="height: 125px;" @click="onShowAttachment(attachment)">
															<img :src="attachment.url" class="rounded mx-auto d-block" style="max-height: 100%;" v-if="contains(attachment.extension, ['jpg', 'jpeg', 'gif', 'bmp', 'png', 'svg', 'webp'], true)" />
															<!-- <i class="fa fa-5x fa-image mx-auto d-block" v-if="contains(attachment.extension, ['jpg', 'jpeg', 'gif', 'bmp', 'png', 'svg', 'webp'], true)"></i> -->
															<i class="fa fa-5x fa-music mx-auto d-block" v-else-if="contains(attachment.extension, ['mp3', 'amr', 'wav', 'ogg', 'm4a'], true)"></i>
															<i class="fa fa-5x fa-film mx-auto d-block" v-else-if="contains(attachment.extension, ['mp4', 'avi', 'wmv', 'mov', 'mkv', 'mpg', 'mpeg', 'mpeg4', '3gp', 'webm', 'ogv', 'm4v'], true)"></i>
															<i class="fa fa-5x fa-file mx-auto d-block" v-else></i>
														</div>
														<div class="input-group input-group-sm" v-if="!attachment.edit">
															<input type="text" class="form-control" v-model="attachment.name" :title="attachment.name" disabled />
															<button class="btn btn-primary" type="button" content="Переименовать файл" v-tippy="{placement: 'top'}" @click="attachment.edit_name = attachment.name; attachment.edit = true;" :disabled="attachment.delete_loading">
																<i class="fa fa-edit"></i>
															</button>
														</div>
														<form @submit.prevent="onEditAttachment(attachment.id)" v-if="attachment.edit">
															<div class="input-group input-group-sm">
																<input type="text" class="form-control" v-model="attachment.edit_name" required :disabled="attachment.edit_loading || attachment.delete_loading" />
																<button class="btn btn-primary" type="submit" content="Сохранить изменения" v-tippy="{placement: 'top'}" :disabled="attachment.edit_loading || attachment.delete_loading">
																	<i class="fa" :class="{'fa-save': !attachment.edit_loading, 'fa-spinner fa-spin': attachment.edit_loading}"></i>
																</button>
															</div>
														</form>
														<div class="card-footer p-0">
															<small v-if="attachment.date_created != '0000-00-00'">{{formatDate(attachment.date_created, 'DD.MM.YYYY в HH:mm')}}</small>
															<small v-else>???</small>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="card-body" v-show="tab2 == 'actions'">
										<div class="text-center" v-if="loadingActions">
											<loading-spinner
												:overlay="loadingActions"
												text=""
											></loading-spinner>
										</div>
										<div v-else>
											<div class="text-center" v-if="actions.length <= 0">Действий еще не совершалось</div>
											<div class="d-flex text-muted" :class="{'pt-2': index != 0, 'pb-2': index != action.length - 1, 'border-bottom': index != action.length - 1}" v-for="(action, index) in actions" :key="action.id">
												<div class="lh-sm w-100">
													<div class="small">
														<strong>{{action.user_fname}} {{action.user_sname}} @{{action.user_login}}</strong>
														<span>&nbsp;</span>
														<small class="text-dark-gray">
															<span>{{this.formatDate(action.date_created, 'DD.MM.YYYY в HH:mm')}}</span>
														</small>
													</div>
													<div v-html="action.message"></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-4">
							<div class="mb-3">
								<label for="select-project-id" class="form-label">Проект, в котором выполняется:</label>
								<select id="select-project-id" ref="project_id" class="form-select" v-model="project_id" required>
									<option :value="p.id" :key="p.id" v-for="p in projects">{{p.name}}</option>
								</select>
							</div>
							<div class="mb-3">
								<label for="select-for-project-id" class="form-label">Проект, для выполнения которого создана:</label>
								<select id="select-for-project-id" ref="for_project_id" class="form-select" v-model="for_project_id" required>
									<option :value="p.id" :key="p.id" v-for="p in projects">{{p.name}}</option>
								</select>
							</div>
							<div class="input-group mb-3">
								<label for="select-type-id" class="input-group-text">Тип:</label>
								<select id="select-type-id" ref="type_id" class="form-select" v-model="type_id" required>
									<option :value="t.id" :key="t.id" v-for="t in types">{{t.name}}</option>
								</select>
							</div>
							<div class="input-group mb-3">
								<label for="select-status-id" class="input-group-text">Статус:</label>
								<select id="select-status-id" ref="status_id" class="form-select text-white" :style="{backgroundColor: status_color}" v-model="status_id" :disabled="!type_id" required>
									<option class="text-white" :style="{backgroundColor: s.status_type_color}" :value="s.id" :key="s.id" v-for="s in statuses">{{s.name}}</option>
								</select>
							</div>
							<div class="input-group mb-3">
								<label for="select-priority-id" class="input-group-text">Приоритет:</label>
								<select id="select-priority-id" ref="priority_id" class="form-select text-white" :style="{backgroundColor: priority_color}" v-model="priority_id" required>
									<option class="text-white" :style="{backgroundColor: p.color}" :value="p.id" :key="p.id" v-for="p in priorities">{{p.weight}}: {{p.name}}</option>
								</select>
							</div>
							<div class="input-group mb-3">
								<label for="input-estimate" class="input-group-text">Оценка времени в часах:</label>
								<input id="input-estimate" ref="estimate" type="number" min="0" class="form-control" required v-model.number="estimate" />
							</div>
							<div class="mb-3" v-if="!checklistEnable">
								<label for="input-progress" class="form-label">Прогресс выполнения ({{progress}}%):</label>
								<input id="input-progress" ref="progress" type="range" min="0" max="100" step="5" class="form-range" v-model.number="progress" />
							</div>
							<div class="mb-3" v-if="checklistEnable">
								<label class="form-label">Прогресс выполнения ({{checklist_progress}}%):</label>
								<div class="progress">
									<div class="progress-bar" role="progressbar" aria-label="Task progress" :style="{width: checklist_progress+'%'}" :aria-valuenow="checklist_progress" aria-valuemin="0" aria-valuemax="100"></div>
								</div>
							</div>
							<div class="mb-3">
								<label class="form-label">Даты по плану (начало / дедлайн):</label>
								<div class="input-group">
									<input ref="date_start_plan" type="date" class="form-control" v-model="date_start_plan" />
									<input ref="date_end_plan" type="date" class="form-control" v-model="date_end_plan" />
								</div>
							</div>
							<div class="mb-3">
								<label class="form-label">Даты по факту (начато / завершено):</label>
								<div class="input-group">
									<input ref="date_start_fact" type="date" class="form-control" v-model="date_start_fact" />
									<input ref="date_end_fact" type="date" class="form-control" v-model="date_end_fact" />
								</div>
							</div>
							<div class="mb-3">
								<div class="card">
									<div class="input-group">
										<label for="user_assigned_add_id" class="input-group-text">Исполнители ({{users_assigned.length}}):</label>
										<select id="user_assigned_add_id" ref="user_assigned_add_id" class="form-select" v-model="user_assigned_add_id" :disabled="users_not_assigned.length <= 0 || user_assigned_add_loading">
											<option value=""></option>
											<option :value="user.id" v-for="user in users_not_assigned" :key="user.id">{{user.fname}} {{user.sname}} @{{user.login}}</option>
										</select>
									</div>
									<ul class="list-group list-group-flush">
										<li class="list-group-item" v-if="users_assigned.length <= 0">Не указаны</li>
										<li class="list-group-item d-flex align-items-center" v-for="user in users_assigned" :key="user.id">
											<div class="d-flex align-items-center w-100 small multi-user">
												<img :src="user.avatar" class="avatar avatar-xs rounded-circle me-2" />
												<span>{{user.fname}} {{user.sname}} @{{user.login}}</span>
											</div>
											<div class="flex-shrink-1" style="margin-top: -4px; margin-right: -12px; margin-bottom: -4px;">
												<button type="button" class="btn btn-sm btn-danger" content="Убрать исполнителя" v-tippy="{placement: 'top'}" @click="onDelUserAssigned(user.id)" :disabled="user.delete_loading">
													<i class="fa" :class="{'fa-times': !user.delete_loading, 'fa-spinner fa-spin': user.delete_loading}"></i>
												</button>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<div class="mb-3" v-if="tags.length > 0">
								<div class="card">
									<div class="input-group">
										<label for="tag_add_id" class="input-group-text">Теги ({{task_tags.length}}):</label>
										<select id="tag_add_id" ref="tag_add_id" class="form-select" v-model="tag_add_id" :disabled="tags_not_added.length <= 0 || tag_add_loading">
											<option value=""></option>
											<option :value="tag.id" v-for="tag in tags_not_added" :key="tag.id">{{tag.name}}</option>
										</select>
									</div>
									<ul class="list-group list-group-flush">
										<li class="list-group-item" v-if="task_tags.length <= 0">Не добавлены</li>
										<li class="list-group-item d-flex align-items-center" v-for="tag in task_tags" :key="tag.id">
											<div class="w-100">
												<i class="fa fa-hashtag me-2"></i>
												<span>{{tag.name}}</span>
											</div>
											<div class="flex-shrink-1" style="margin-top: -4px; margin-right: -12px; margin-bottom: -4px;">
												<button type="button" class="btn btn-sm btn-danger" content="Убрать тег" v-tippy="{placement: 'top'}" @click="onDelTag(tag.id)" :disabled="tag.delete_loading">
													<i class="fa" :class="{'fa-times': !tag.delete_loading, 'fa-spinner fa-spin': tag.delete_loading}"></i>
												</button>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<div class="mb-3" v-if="targets.length > 0">
								<div class="card">
									<div class="input-group">
										<label for="target_add_id" class="input-group-text">Цели ({{task_targets.length}}):</label>
										<select id="target_add_id" ref="target_add_id" class="form-select" v-model="target_add_id" :disabled="targets_not_added.length <= 0 || target_add_loading">
											<option value=""></option>
											<option :value="target.id" v-for="target in targets_not_added" :key="target.id">{{target.name}}</option>
										</select>
									</div>
									<ul class="list-group list-group-flush">
										<li class="list-group-item" v-if="task_targets.length <= 0">Не добавлены</li>
										<li class="list-group-item d-flex align-items-center" v-for="target in task_targets" :key="target.id">
											<div class="w-100">
												<i class="fa fa-crosshairs me-2"></i>
												<span>{{target.name}}</span>
											</div>
											<div class="flex-shrink-1" style="margin-top: -4px; margin-right: -12px; margin-bottom: -4px;">
												<button type="button" class="btn btn-sm btn-danger" content="Убрать цель" v-tippy="{placement: 'top'}" @click="onDelTarget(target.id)" :disabled="target.delete_loading">
													<i class="fa" :class="{'fa-times': !target.delete_loading, 'fa-spinner fa-spin': target.delete_loading}"></i>
												</button>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<div class="mb-3">
								<div class="card">
									<div class="card-header bg-white">
										<div class="float-end">
											<div class="form-check form-switch m-0 p-0">
												<input id="checkbox-is-archived" ref="is_archived" class="form-check-input ms-0" type="checkbox" role="switch" v-model="is_archived" />
											</div>
										</div>
										<label for="checkbox-is-archived" class="form-check-label">В архиве</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="alert alert-warning alert-dismissible fade show mt-2 mb-0" v-if="error">
					{{error}}
					<button type="button" class="btn-close" aria-label="Close" @click="error = ''"></button>
				</div>
			</div>
			<div class="modal-footer p-1">
				<button type="button" class="btn btn-danger position-absolute start-0 ms-2" :disabled="loading || loading2" @click="onShowDeleteModal(close)">Удалить</button>
				<button type="submit" class="btn btn-primary" :disabled="loading || loading2">Сохранить изменения</button>
				<button type="button" class="btn btn-light" @click="$emit('cancel', close)">Отмена</button>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>
.bigUploadContainer {
	position: relative;
	max-width: 350px;
	padding: 20px;
	margin: 0 auto;
	background-color: #fff;
	border: 1px solid #e5e5e5;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,.05);
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05);
	box-shadow: 0 1px 2px rgba(0,0,0,.05);
	text-align: center;
}
</style>

<script>
import lib from '@/lib';
import autosize from 'autosize';
import moment from 'moment-timezone';
import { VueDraggableNext } from 'vue-draggable-next';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import BigUpload from '@/components/BigUpload.vue';

import TaskDeleteModal2 from '@/components/modals/tasks/board/TaskDeleteModal2.vue';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		tab1: 'description',
		tab2: 'comments',
		
		showAttachmentUpload: false,
		checklistHideChecked: false,
		
		id: '',
		parent_id: '',
		project_id: '',
		for_project_id: '',
		pos: '',
		title: '',
		description: '',
		solutions: '',
		result_plan: '',
		result_fact: '',
		type_id: '',
		status_id: '',
		priority_id: '',
		estimate: '',
		progress: 0,
		is_archived: false,
		date_start_plan: '',
		date_start_fact: '',
		date_end_plan: '',
		date_end_fact: '',
		
		users_assigned: [],
		user_assigned_add_id: '',
		user_assigned_add_loading: false,
		
		task_targets: [],
		target_add_id: '',
		target_add_loading: false,
		
		task_tags: [],
		tag_add_id: '',
		tag_add_loading: false,
		
		comments: [],
		comment_add: '',
		comment_add_loading: false,
		
		actions: [],
		
		attachments: [],
		
		checklist: [],
		checklist_add_name: '',
		checklist_add_loading: false,
		
		projects: [],
		users: [],
		types: [],
		statuses: [],
		priorities: [],
		custom_fields: [],
		custom_fields_values: {},
		tags: [],
		targets: [],
		
		error: '',
		loading: false,
		loading2: false,
		loadingComments: false,
		loadingAttachments: false,
		loadingActions: false,
	}),
	computed: {
		users_not_assigned(){
			return this.users.filter((el) => !this.users_assigned.map(el2 => el2.user_id).includes(el.id));
		},
		tags_not_added(){
			return this.tags.filter((el) => !this.task_tags.map(el2 => el2.tag_id).includes(el.id));
		},
		targets_not_added(){
			return this.targets.filter((el) => !this.task_targets.map(el2 => el2.target_id).includes(el.id));
		},
		checklist_progress(){
			return this.checklist.length > 0 ? Math.floor((this.checklist.filter((el) => el.checked).length / this.checklist.length) * 100) : 0;
		},
		checklist_filtered(){
			return this.checklistHideChecked ? this.checklist.filter((el) => !el.checked) : this.checklist;
		},
		checklist_checked(){
			return this.checklist.filter((el) => el.checked);
		},
		checklist_not_checked(){
			return this.checklist.filter((el) => !el.checked);
		},
		checklistEnable(){
			return this.checklist.length > 0;
		},
		status_color(){
			return this.status_id && this.statuses.length > 0 ? this.statuses.find((el) => el.id == this.status_id).status_type_color || 'inherit' : 'inherit';
		},
		priority_color(){
			return this.priority_id && this.priorities.length > 0 ? this.priorities.find((el) => el.id == this.priority_id).color || 'inherit' : 'inherit';
		},
		
		biguploadScriptPathParams(){
			return new URLSearchParams({
				virtualspace_id: this.$vfm.modals[0].params._value.virtualspace_id,
				lang: this.appModel.getLang(),
			});
		},
	},
	watch: {
		async type_id(val, oldVal){
			if(val != ''){
				this.loading2 = true;
				await this.loadStatuses();
				await this.loadCustomFields();
				await this.loadCustomFieldsValues();
				this.loading2 = false;
			}
		},
		async user_assigned_add_id(val, oldVal){
			if(val != ''){
				// добавление исполнителя
				this.user_assigned_add_loading = true;
				this.error = '';
				let ret = await this.appModel.doAddTaskUserAssigned(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id, val);
				if(ret.success){
					this.user_assigned_add_id = '';
					this.$emit('reload');
					await this.loadTaskUsersAssigned();
					await this.loadTaskActions();
				} else {
					this.error = ret.message;
				}
				this.user_assigned_add_loading = false;
			}
		},
		async tag_add_id(val, oldVal){
			if(val != ''){
				// добавление тега
				this.tag_add_loading = true;
				this.error = '';
				let ret = await this.appModel.doAddTaskTag(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id, val);
				if(ret.success){
					this.tag_add_id = '';
					this.$emit('reload');
					await this.loadTaskTags();
					await this.loadTaskActions();
				} else {
					this.error = ret.message;
				}
				this.tag_add_loading = false;
			}
		},
		async target_add_id(val, oldVal){
			if(val != ''){
				// добавление цели
				this.target_add_loading = true;
				this.error = '';
				let ret = await this.appModel.doAddTaskTarget(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id, val);
				if(ret.success){
					this.target_add_id = '';
					this.$emit('reload');
					await this.loadTaskTargets();
					await this.loadTaskActions();
				} else {
					this.error = ret.message;
				}
				this.target_add_loading = false;
			}
		},
	},
	methods: {
		contains: lib.contains,
		
		sortedByPos(arr){
			return arr.sort((a, b) => {
				return a.pos > b.pos ? 1 : (a.pos < b.pos ? -1 : 0);
			});
		},
		
		async loadProjects(){
			this.error = '';
			let ret = await this.appModel.doGetProjects(this.$vfm.modals[0].params._value.virtualspace_id);
			if(ret.success){
				this.projects = ret.list;
			} else {
				this.error = ret.message;
			}
		},
		async loadTypes(){
			this.error = '';
			let ret = await this.appModel.doGetTasksTypes(this.$vfm.modals[0].params._value.virtualspace_id);
			if(ret.success){
				this.types = ret.list;
			} else {
				this.error = ret.message;
			}
		},
		async loadStatuses(){
			this.error = '';
			let ret = await this.appModel.doGetTasksStatusesByTasksType(this.$vfm.modals[0].params._value.virtualspace_id, this.type_id);
			if(ret.success){
				this.statuses = ret.list;
			} else {
				this.error = ret.message;
			}
		},
		async loadPriorities(){
			this.error = '';
			let ret = await this.appModel.doGetTasksPriorities(this.$vfm.modals[0].params._value.virtualspace_id);
			if(ret.success){
				this.priorities = ret.list;
			} else {
				this.error = ret.message;
			}
		},
		async loadCustomFields(){
			this.error = '';
			let ret = await this.appModel.doGetTasksCustomFieldsByTasksType(this.$vfm.modals[0].params._value.virtualspace_id, this.type_id);
			if(ret.success){
				this.custom_fields = ret.list.map((cf) => {
					cf.data = JSON.parse(cf.data||'[]');
					return cf;
				});
			} else {
				this.error = ret.message;
			}
		},
		async loadUsers(){
			this.error = '';
			let ret = await this.appModel.doGetUsers(this.$vfm.modals[0].params._value.virtualspace_id);
			if(ret.success){
				this.users = ret.list.map((el) => {
					el.id = el.user_id;
					delete el.user_id;
					return el;
				});
			} else {
				this.error = ret.message;
			}
		},
		async loadTags(){
			this.error = '';
			let ret = await this.appModel.doGetTags(this.$vfm.modals[0].params._value.virtualspace_id);
			if(ret.success){
				this.tags = ret.list;
			} else {
				this.error = ret.message;
			}
		},
		async loadTargets(){
			this.error = '';
			let ret = await this.appModel.doGetTargets(this.$vfm.modals[0].params._value.virtualspace_id);
			if(ret.success){
				this.targets = ret.list;
			} else {
				this.error = ret.message;
			}
		},
		
		async loadCustomFieldsValues(){
			this.error = '';
			let ret = await this.appModel.doGetTasksCustomFieldsValuesByTask(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id);
			if(ret.success){
				ret.list.forEach((cfv) => {
					this.custom_fields_values[cfv.custom_field_id] = cfv.value;
				});
			} else {
				this.error = ret.message;
			}
		},
		async loadTaskUsersAssigned(){
			this.error = '';
			let ret = await this.appModel.doGetTaskUsersAssigned(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id);
			if(ret.success){
				this.users_assigned = ret.list.map((el) => {
					el.delete_loading = false;
					return el;
				});
			} else {
				this.error = ret.message;
			}
		},
		async loadTaskTags(){
			this.error = '';
			let ret = await this.appModel.doGetTaskTags(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id);
			if(ret.success){
				this.task_tags = ret.list.map((el) => {
					el.delete_loading = false;
					return el;
				});
			} else {
				this.error = ret.message;
			}
		},
		async loadTaskTargets(){
			this.error = '';
			let ret = await this.appModel.doGetTaskTargets(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id);
			if(ret.success){
				this.task_targets = ret.list.map((el) => {
					el.delete_loading = false;
					return el;
				});
			} else {
				this.error = ret.message;
			}
		},
		async loadTaskAttachments(){
			this.loadingAttachments = true;
			this.error = '';
			let ret = await this.appModel.doGetTaskAttachments(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id);
			if(ret.success){
				this.attachments = ret.list.map((el) => {
					el.edit = false;
					el.edit_loading = false;
					el.edit_name = '';
					el.delete_loading = false;
					return el;
				});
			} else {
				this.error = ret.message;
			}
			this.loadingAttachments = false;
		},
		async loadTaskChecklist(){
			this.error = '';
			let ret = await this.appModel.doGetTaskСhecklist(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id);
			if(ret.success){
				this.checklist = ret.list.map((el) => {
					el.checked = !!el.checked;
					el.edit = false;
					el.edit_loading = false;
					el.edit_name = '';
					el.delete_loading = false;
					return el;
				});
			} else {
				this.error = ret.message;
			}
		},
		async loadTaskComments(){
			this.loadingComments = true;
			this.error = '';
			let ret = await this.appModel.doGetTaskComments(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id);
			if(ret.success){
				this.comments = ret.list.map((el) => {
					el.edit = false;
					el.edit_loading = false;
					el.edit_text = '';
					el.delete_loading = false;
					return el;
				});
			} else {
				this.error = ret.message;
			}
			this.loadingComments = false;
		},
		async loadTaskActions(){
			this.loadingActions = true;
			this.error = '';
			let ret = await this.appModel.doGetTaskActions(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id);
			if(ret.success){
				this.actions = ret.list;
			} else {
				this.error = ret.message;
			}
			this.loadingActions = false;
		},
		
		// добавление пункта чек-листа
		async onAddChecklistItem(){
			this.checklist_add_loading = true;
			this.error = '';
			let ret = await this.appModel.doAddTaskChecklistItem(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id, {
				name: this.checklist_add_name,
			});
			if(ret.success){
				this.$emit('reload');
				this.checklist_add_name = '';
				await this.loadTaskChecklist();
				await this.loadTaskActions();
			} else {
				this.error = ret.message;
			}
			this.checklist_add_loading = false;
		},
		// изменение параметра checked у пункта чек-листа
		async onCheckChecklistItem(checklist_item_id){
			let item = this.checklist.find((el) => el.id == checklist_item_id);
			this.error = '';
			let ret = await this.appModel.doEditTaskChecklistItem(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id, checklist_item_id, {
				checked: item.checked ? 1 : 0,
			});
			if(ret.success){
				this.$emit('reload');
				await this.loadTaskActions();
			} else {
				this.error = ret.message;
				item.checked = !item.checked;
			}
		},
		// изменение названия пункта чек-листа
		async onEditChecklistItem(checklist_item_id){
			let item = this.checklist.find((el) => el.id == checklist_item_id);
			item.edit_loading = true;
			this.error = '';
			let ret = await this.appModel.doEditTaskChecklistItem(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id, checklist_item_id, {
				name: item.edit_name,
			});
			if(ret.success){
				item.name = item.edit_name;
				item.edit = false;
				await this.loadTaskActions();
			} else {
				this.error = ret.message;
			}
			item.edit_loading = false;
		},
		// удаление пункта чек-листа
		async onDelChecklistItem(checklist_item_id){
			if(confirm('Вы уверены?')){
				let item = this.checklist.find((el) => el.id == checklist_item_id);
				item.delete_loading = true;
				this.error = '';
				let ret = await this.appModel.doDeleteTaskChecklistItem(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id, checklist_item_id);
				if(ret.success){
					this.$emit('reload');
					await this.loadTaskChecklist();
					await this.loadTaskActions();
				} else {
					this.error = ret.message;
				}
				item.delete_loading = false;
			}
		},
		
		// добавление комментария
		async onAddComment(){
			this.comment_add_loading = true;
			this.error = '';
			let ret = await this.appModel.doAddTaskComment(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id, {
				text: this.comment_add,
			});
			if(ret.success){
				this.comment_add = '';
				//autosize.update(this.$refs.comment);
				await this.loadTaskComments();
				await this.loadTaskActions();
			} else {
				this.error = ret.message;
			}
			this.comment_add_loading = false;
		},
		// изменение текста комментария
		async onEditComment(comment_id){
			let item = this.comments.find((el) => el.id == comment_id);
			item.edit_loading = true;
			this.error = '';
			let ret = await this.appModel.doEditTaskComment(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id, comment_id, {
				text: item.edit_text,
			});
			if(ret.success){
				item.text = item.edit_text;
				item.edit = false;
				await this.loadTaskActions();
			} else {
				this.error = ret.message;
			}
			item.edit_loading = false;
		},
		// удаление комментария
		async onDelComment(comment_id){
			if(confirm('Вы уверены?')){
				let item = this.comments.find((el) => el.id == comment_id);
				item.delete_loading = true;
				this.error = '';
				let ret = await this.appModel.doDeleteTaskComment(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id, comment_id);
				if(ret.success){
					await this.loadTaskComments();
					await this.loadTaskActions();
				} else {
					this.error = ret.message;
				}
				item.delete_loading = false;
			}
		},
		
		// успешная загрузка вложения
		async onBiguploadAttachmentSuccess(response){
			if(response.success || false){
				await this.loadTaskAttachments();
				await this.loadTaskActions();
			}
		},
		// изменение названия вложения
		async onEditAttachment(attachment_id){
			let item = this.attachments.find((el) => el.id == attachment_id);
			item.edit_loading = true;
			this.error = '';
			let ret = await this.appModel.doEditTaskAttachment(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id, attachment_id, {
				name: item.edit_name,
			});
			if(ret.success){
				item.name = item.edit_name;
				item.edit = false;
				await this.loadTaskActions();
			} else {
				this.error = ret.message;
			}
			item.edit_loading = false;
		},
		onShowAttachment(attachment){
			window.open(attachment.url, '_blank');
		},
		// удаление вложения
		async onDelAttachment(attachment_id){
			if(confirm('Вы уверены?')){
				let item = this.attachments.find((el) => el.id == attachment_id);
				item.delete_loading = true;
				this.error = '';
				let ret = await this.appModel.doDeleteTaskAttachment(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id, attachment_id);
				if(ret.success){
					await this.loadTaskAttachments();
					await this.loadTaskActions();
				} else {
					this.error = ret.message;
				}
				item.delete_loading = false;
			}
		},
		
		// удаление исполнителя
		async onDelUserAssigned(task_user_id){
			let item = this.users_assigned.find((el) => el.id == task_user_id);
			item.delete_loading = true;
			this.error = '';
			let ret = await this.appModel.doDeleteTaskUserAssigned(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id, task_user_id);
			if(ret.success){
				this.$emit('reload');
				await this.loadTaskUsersAssigned();
				await this.loadTaskActions();
			} else {
				this.error = ret.message;
			}
			item.delete_loading = false;
		},
		
		// удаление тега
		async onDelTag(task_tag_id){
			let item = this.task_tags.find((el) => el.id == task_tag_id);
			item.delete_loading = true;
			this.error = '';
			let ret = await this.appModel.doDeleteTaskTag(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id, task_tag_id);
			if(ret.success){
				this.$emit('reload');
				await this.loadTaskTags();
				await this.loadTaskActions();
			} else {
				this.error = ret.message;
			}
			item.delete_loading = false;
		},
		
		// удаление цели
		async onDelTarget(task_target_id){
			let item = this.task_targets.find((el) => el.id == task_target_id);
			item.delete_loading = true;
			this.error = '';
			let ret = await this.appModel.doDeleteTaskTarget(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id, task_target_id);
			if(ret.success){
				this.$emit('reload');
				await this.loadTaskTargets();
				await this.loadTaskActions();
			} else {
				this.error = ret.message;
			}
			item.delete_loading = false;
		},
		
		async onEdit(close){
			this.loading2 = true;
			this.error = '';
			let ret = await this.appModel.doEditTask(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.task.id, {
				//parent_id: this.parent_id,
				project_id: this.project_id,
				for_project_id: this.for_project_id,
				//pos: this.pos,
				title: this.title,
				description: this.description,
				solutions: this.solutions,
				result_plan: this.result_plan,
				result_fact: this.result_fact,
				type_id: this.type_id,
				status_id: this.status_id,
				priority_id: this.priority_id,
				estimate: this.estimate,
				progress: this.checklist.length > 0 ? this.checklist_progress : this.progress,
				is_archived: this.is_archived ? 1 : 0,
				date_start_plan: this.date_start_plan ? moment.tz(this.date_start_plan, 'Asia/Almaty').utc().format('YYYY-MM-DD') : '',
				date_start_fact: this.date_start_fact ? moment.tz(this.date_start_fact, 'Asia/Almaty').utc().format('YYYY-MM-DD') : '',
				date_end_plan: this.date_end_plan ? moment.tz(this.date_end_plan, 'Asia/Almaty').utc().format('YYYY-MM-DD') : '',
				date_end_fact: this.date_end_fact ? moment.tz(this.date_end_fact, 'Asia/Almaty').utc().format('YYYY-MM-DD') : '',
				custom_fields_values: Object.entries(this.custom_fields_values).map((el) => ({
					custom_field_id: el[0],
					value: el[1],
				})),
			});
			if(ret.success){
				this.$emit('edit', close);
			} else {
				this.error = ret.message;
			}
			this.loading2 = false;
		},
		onShowDeleteModal(close){
			let that = this;
			this.$vfm.show({
				component: TaskDeleteModal2,
				bind: {
					styles: {
						display: 'block',
					},
				},
				on: {
					delete(close2){
						close2();
						that.$emit('reload');
						close();
					},
					cancel(close2){
						close2();
					},
				},
				params: {
					virtualspace_id: that.$route.params.virtualspace_id,
					task: that.$vfm.modals[0].params._value.task,
				},
			});
		},
		
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	async mounted(){
		this.id = this.$vfm.modals[0].params._value.task.id;
		
		this.loading = true;
		await this.loadProjects();
		await this.loadTypes();
		await this.loadPriorities();
		await this.loadUsers();
		await this.loadTags();
		await this.loadTargets();
		
		await this.loadTaskUsersAssigned();
		await this.loadTaskTags();
		await this.loadTaskTargets();
		await this.loadTaskChecklist();
		this.loading = false;
		
		this.parent_id = this.$vfm.modals[0].params._value.task.parent_id;
		this.project_id = this.$vfm.modals[0].params._value.task.project_id;
		this.for_project_id = this.$vfm.modals[0].params._value.task.for_project_id;
		this.pos = this.$vfm.modals[0].params._value.task.pos;
		this.title = this.$vfm.modals[0].params._value.task.title;
		this.description = this.$vfm.modals[0].params._value.task.description;
		this.solutions = this.$vfm.modals[0].params._value.task.solutions;
		this.result_plan = this.$vfm.modals[0].params._value.task.result_plan;
		this.result_fact = this.$vfm.modals[0].params._value.task.result_fact;
		this.type_id = this.$vfm.modals[0].params._value.task.type_id;
		this.status_id = this.$vfm.modals[0].params._value.task.status_id;
		this.priority_id = this.$vfm.modals[0].params._value.task.priority_id;
		this.estimate = this.$vfm.modals[0].params._value.task.estimate;
		this.progress = this.$vfm.modals[0].params._value.task.progress;
		this.is_archived = !!this.$vfm.modals[0].params._value.task.is_archived;
		this.date_start_plan = this.$vfm.modals[0].params._value.task.date_start_plan != '0000-00-00' ? this.formatDate(this.$vfm.modals[0].params._value.task.date_start_plan, 'YYYY-MM-DD') : '';
		this.date_start_fact = this.$vfm.modals[0].params._value.task.date_start_fact != '0000-00-00' ? this.formatDate(this.$vfm.modals[0].params._value.task.date_start_fact, 'YYYY-MM-DD') : '';
		this.date_end_plan = this.$vfm.modals[0].params._value.task.date_end_plan != '0000-00-00' ? this.formatDate(this.$vfm.modals[0].params._value.task.date_end_plan, 'YYYY-MM-DD') : '';
		this.date_end_fact = this.$vfm.modals[0].params._value.task.date_end_fact != '0000-00-00' ? this.formatDate(this.$vfm.modals[0].params._value.task.date_end_fact, 'YYYY-MM-DD') : '';
		
		this.loadTaskComments();
		this.loadTaskAttachments();
		this.loadTaskActions();
		
		setTimeout(() => {
			this.$refs.title.focus();
			
			autosize(this.$refs.title);
			autosize(this.$refs.description);
			autosize(this.$refs.solutions);
			autosize(this.$refs.result_plan);
			autosize(this.$refs.result_fact);
			autosize(this.$refs.comment);
		}, 300);
	},
	components: {
		LoadingSpinner,
		BigUpload,
		draggable: VueDraggableNext,
	},
}
</script>
<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal fade show" content-class="modal-dialog modal-lg" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onEdit(close)">
			<div class="modal-header py-2">
				<h1 class="modal-title fs-5">Изменение шаблона задач</h1>
				<button type="button" class="btn-close" @click="$emit('cancel', close)" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="text-center" v-if="loading">
					<loading-spinner
						:overlay="loading"
						text="ЗАГРУЗКА ДАННЫХ..."
					></loading-spinner>
				</div>
				<div v-else>
					<div class="mb-3">
						<label for="input-name" class="form-label">Наименование:</label>
						<input id="input-name" ref="name" type="text" placeholder="Например: В процессе" class="form-control" v-model="name" required />
					</div>
					<div class="mb-3">
						<label for="input-description" class="form-label">Описание:</label>
						<textarea id="input-description" ref="description" rows="3" class="form-control" v-model="description"></textarea>
					</div>
					<div class="mb-3">
						<label for="input-template" class="form-label">Шаблон задачи (html):</label>
						<textarea id="input-template" ref="template" rows="10" class="form-control" v-model="template"></textarea>
					</div>
				</div>
				<div class="alert alert-warning alert-dismissible fade show mt-2 mb-0" v-if="error">
					{{error}}
					<button type="button" class="btn-close" aria-label="Close" @click="error = ''"></button>
				</div>
			</div>
			<div class="modal-footer p-1">
				<button type="submit" class="btn btn-primary" :disabled="loading">Сохранить изменения</button>
				<button type="button" class="btn btn-light" @click="$emit('cancel', close)">Отмена</button>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';
import autosize from 'autosize';

import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		name: '',
		description: '',
		template: '',
		
		error: '',
		loading: false,
	}),
	methods: {
		async onEdit(close){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doEditDocumentTemplate(this.$vfm.modals[0].params._value.virtualspace_id, this.$vfm.modals[0].params._value.documentTemplate.id, {
				name: this.name,
				description: this.description,
				template: this.template,
			});
			if(ret.success){
				this.$emit('edit', close);
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
	},
	async mounted(){
		this.name = this.$vfm.modals[0].params._value.documentTemplate.name;
		this.description = this.$vfm.modals[0].params._value.documentTemplate.description;
		this.template = this.$vfm.modals[0].params._value.documentTemplate.template;
		
		setTimeout(() => {
			this.$refs.name.focus();
			
			autosize(this.$refs.description);
			autosize(this.$refs.template);
		}, 300);
	},
	components: {
		LoadingSpinner,
	},
}
</script>
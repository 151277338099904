<template>
	<div class="row">
		<div class="col-md-2">
			<div class="card">
				<div class="card-header bg-white p-1">
					<select class="form-select" v-model="type_id">
						<option value="" disabled>Тип задач</option>
						<option :value="type.id" v-for="type in tasksTypes">{{type.name}}</option>
					</select>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="card">
				<div class="card-header bg-white">
					<div class="float-end">
						<div class="badge bg-secondary me-2">{{filter.length}}</div>
						<a class="btn btn-sm btn-white" @click="filterShow = !filterShow">
							<i class="fa" :class="{'fa-chevron-down': !filterShow, 'fa-chevron-up': filterShow}"></i>
						</a>
					</div>
					<div class="mt-1">Фильтр</div>
				</div>
				<div class="card-body" v-if="filterShow">
					<div class="input-group mb-3" v-for="(fi, index) in filter">
						<label class="input-group-text">{{getStructParam(fi.field).label}}</label>
						<select class="form-select" v-model="fi.operator" style="max-width: 130px;">
							<option :value="op.operator" v-for="op in getFilterOperatorsByType(getStructParam(fi.field).type).operators">{{op.label}}</option>
						</select>
						<input type="text" class="form-control" v-model="fi.value" v-if="getStructParam(fi.field).type == 'input' || getStructParam(fi.field).type == 'text' || getStructParam(fi.field).type == 'phonenumber'" />
						<input type="number" class="form-control" @wheel="$event.target.blur()" v-model.number="fi.value" v-if="getStructParam(fi.field).type == 'number'" />
						<input type="date" class="form-control" v-model="fi.value" v-if="getStructParam(fi.field).type == 'date'" />
						<input type="datetime-local" class="form-control" v-model="fi.value" v-if="getStructParam(fi.field).type == 'datepicker'" />
						<input type="email" class="form-control" v-model="fi.value" v-if="getStructParam(fi.field).type == 'email'" />
						<select class="form-select" v-model="fi.value" v-if="getStructParam(fi.field).type == 'select' || getStructParam(fi.field).type == 'boolean'">
							<option value=""></option>
							<option :value="opt.value" v-for="opt in getStructParam(fi.field).options">{{opt.label}}</option>
						</select>
						<div class="btn btn-danger" @click="onDelFilterParam(index)" content="Удалить параметр фильтра" v-tippy="{placement: 'top'}">
							<i class="fa fa-times"></i>
						</div>
					</div>
					<div class="input-group">
						<label class="input-group-text">Фильтр по</label>
						<select class="form-select" v-model="filterAddField" @change="onAddFilterParam">
							<option value="" disabled="disabled"></option>
							<option :value="elem.field" v-for="elem in structParamsFilter">{{elem.label}}</option>
						</select>
						<button type="button" class="btn btn-primary" :disabled="loading" content="Применить фильтр" v-tippy="{placement: 'top'}" @click="page = 1; loadData();">
							<i class="fa" :class="{'fa-check': !loading, 'fa-spinner fa-spin': loading}"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-4">
			<div class="card">
				<div class="card-header bg-white">
					<div class="float-end">
						<div class="badge bg-secondary me-2">{{sort.length}}</div>
						<a class="btn btn-sm btn-white" @click="sortShow = !sortShow">
							<i class="fa" :class="{'fa-chevron-down': !sortShow, 'fa-chevron-up': sortShow}"></i>
						</a>
					</div>
					<div class="mt-1">Сортировка</div>
				</div>
				<div class="card-body" v-if="sortShow">
					<div class="input-group mb-3" v-for="(si, index) in sort">
						<label class="input-group-text">{{getStructParam(si.field).label}}</label>
						<select class="form-select" v-model="si.direction" @change="page = 1; loadData();">
							<option value="asc">а-я</option>
							<option value="desc">я-а</option>
						</select>
						<div class="btn btn-danger" :class="{'btn-block m-t': $device.isMobile}" @click="onDelSortParam(index)" content="Удалить параметр сортировки" v-tippy="{placement: 'top'}">
							<i class="fa fa-times"></i>
						</div>
					</div>
					<div class="input-group">
						<label class="input-group-text">Сортировка по</label>
						<select class="form-select" v-model="sortAddField" @change="onAddSortParam">
							<option value="" disabled="disabled"></option>
							<option :value="elem.field" v-for="elem in structParamsSort">{{elem.label}}</option>
						</select>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="alert alert-warning alert-dismissible fade show mt-2" role="alert" v-if="error">
		{{error}}
		<button type="button" class="btn-close" aria-label="Close" @click="error = ''"></button>
	</div>
	<div class="row mt-3">
		<div class="col-sm-12">
			<div class="card">
				<div class="card-body">
					<div class="text-center" v-if="!type_id">Выберите тип задач</div>
					<div class="kanban-wrapper" v-else>
						<div class="card kanban-column mw-100 mb-3" v-for="status in tasksStatuses">
							<div class="card-header">
								<div class="btn btn-sm btn-primary float-end py-0" @click="onShowAddModal(status.id)" content="Добавить задачу" v-tippy="{placement: 'top'}">
									<i class="fa fa-plus"></i>
								</div>
								<span class="badge bg-secondary me-2">{{columns[status.id].length}}</span>
								<span>{{status.name}}</span>
							</div>
							<div class="card-body kanban-body pb-0">
								<div class="kanban-content-wrapper h-100" tabindex="0" role="region" aria-label="scrollable content">
									<!--
									<div class="d-grid mb-3">
										<div class="btn btn-sm btn-secondary" @click="onShowAddModal(status.id)">Добавить задачу</div>
									</div>
									-->
									<draggable class="kanban-cards px-0" :list="columns[status.id]" group="tasks" :data-status-id="status.id" :move="onDragMoveTask" @change="onDragChangeTask" @start="dragmove_handle = true" @end="dragmove_handle = false" handle=".handle" ghost-class="ghost">
										<div class="card bg-light border mb-3 cursor-pointer handle" v-for="task in columns[status.id]" :key="task.id" @click="onShowEditModal(task.id)">
											<div class="card-body">
												<div class="float-end small multi-user" v-if="task.users_assigned.length > 0">
													<img :src="user.avatar" class="avatar avatar-xs rounded-circle" v-for="user in task.users_assigned" :key="user.id" :content="user.fname+' '+user.sname+' @'+user.login" v-tippy="{placement: 'top'}" />
												</div>
												<span class="badge" :style="{backgroundColor: task.priority_color}">{{task.priority_name}}</span>
												<div class="text-body mt-1 mb-2">
													<span class="me-2">#{{task.id}}.</span>
													<span>{{task.title}}</span>
												</div>
												<div class="small">
													<i class="fa fa-briefcase text-muted me-1"></i>
													<span>Для: {{task.for_project_name}}</span>
												</div>
												<div class="small">
													<i class="fa fa-briefcase text-muted me-1"></i>
													<span>В: {{task.project_name}}</span>
												</div>
												<div class="small" v-if="task.date_start_plan != '0000-00-00' || task.date_end_plan != '0000-00-00'">
													<i class="far fa-calendar-alt me-2" content="Дата по плану" v-tippy="{placement: 'top'}"></i>
													<span v-if="task.date_start_plan != '0000-00-00'">{{formatDate(task.date_start_plan, 'DD.MM.YYYY')}}</span>
													<span v-if="task.date_start_plan == '0000-00-00'">
														<i class="fa fa-sm fa-infinity"></i>
													</span>
													<span> - </span>
													<span v-if="task.date_end_plan != '0000-00-00'">{{formatDate(task.date_end_plan, 'DD.MM.YYYY')}}</span>
													<span v-if="task.date_end_plan == '0000-00-00'">
														<i class="fa fa-sm fa-infinity"></i>
													</span>
												</div>
												<div class="small" v-if="task.date_start_fact != '0000-00-00' || task.date_end_fact != '0000-00-00'">
													<i class="far fa-calendar-check me-2" content="Дата по факту" v-tippy="{placement: 'top'}"></i>
													<span v-if="task.date_start_fact != '0000-00-00'">{{formatDate(task.date_start_fact, 'DD.MM.YYYY')}}</span>
													<span v-if="task.date_start_fact == '0000-00-00'">
														<i class="fa fa-sm fa-infinity"></i>
													</span>
													<span> - </span>
													<span v-if="task.date_end_fact != '0000-00-00'">{{formatDate(task.date_end_fact, 'DD.MM.YYYY')}}</span>
													<span v-if="task.date_end_fact == '0000-00-00'">
														<i class="fa fa-sm fa-infinity"></i>
													</span>
												</div>
												<div class="small mt-2">
													<div class="progress border border-white" style="height: 10px;" :content="task.progress+'%'" v-tippy="{placement: 'top'}">
														<div class="progress-bar" role="progressbar" aria-label="Прогресс" :style="{width: task.progress+'%'}" :aria-valuenow="task.progress" aria-valuemin="0" aria-valuemax="100"></div>
													</div>
												</div>
											</div>
										</div>
									</draggable>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.kanban-wrapper{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
	
	&::-webkit-scrollbar{
		height: 6px;
		width: 6px;
		opacity: 0;
	}
	&::-webkit-scrollbar-corner {
		display: none;
	}
	&::-webkit-scrollbar:hover{
		opacity: 1;
	}
	&::-webkit-scrollbar-track{
		background: 0 0;
	}
	&::-webkit-scrollbar-thumb{
		background: #dde3e6;
	}
	&::-webkit-scrollbar-thumb:hover{
		background: #bac0ca;
		border-radius: 8px;
	}
	.kanban-column{
		width: 300px;
		flex: 0 0 auto;
		
		&:not(:last-child){
			margin-right: var(--bs-gutter-x);
		}
	}
	.kanban-body{
		height: calc(100vh - 314px);
		
		.kanban-content-wrapper{
			overflow: auto;
			
			&::-webkit-scrollbar{
				height: 6px;
				width: 6px;
			}
			&::-webkit-scrollbar-corner {
				display: none;
			}
			&:hover::-webkit-scrollbar-thumb{
				background: #bac0ca;
				border-radius: 8px;
			}
			&::-webkit-scrollbar-thumb{
				/*background-color: #000;*/
				background: transparent;
			}
			.ghost{
				opacity: 0.3;
				background: #c8ebfb;
			}
		}
	}
}
</style>

<script>
import lib from '@/lib';
import moment from 'moment-timezone';
import { VueDraggableNext } from 'vue-draggable-next';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import TaskAddModal from '@/components/modals/tasks/board/TaskAddModal.vue';
import TaskEditModal from '@/components/modals/tasks/board/TaskEditModal.vue';

export default {
	mixins: lib.mixins,
	data: () => ({
		type_id: '',
		
		dragmove_from_status_id: '',
		dragmove_to_status_id: '',
		dragmove_handle: false,
		columns: {},
		
		structParams: [],
		
		projects: [],
		tasksTypes: [],
		tasksStatuses: [],
		users: [],
		targets: [],
		tags: [],
		
		tasks: [],
		tasks_count: 0,
		
		filter: [],
		filterAddField: '',
		filterShow: false,
		
		sort: [
			{field: 't.pos', direction: 'asc'},
			{field: 't.id', direction: 'desc'},
		],
		sortAddField: '',
		sortShow: false,
		
		page: 1,
		limit: 25,
		loading: true,
		error: '',
		
		paginationAlpha: 3,
	}),
	computed: {
		tasksStatusesByType(){
			return this.tasksStatuses.filter((el) => el.status_type_id == this.type_id);
		},
		
		structParamsFilter(){
			return this.structParams.filter((el) => el.filter);
		},
		structParamsSort(){
			return this.structParams.filter((el) => el.sort && !this.sort.map(el => el.field).includes(el.field));
		},
		
		paginationA1(){
			return Math.max(this.page - this.paginationAlpha, 2);
		},
		paginationA2(){
			return Math.min(this.page + this.paginationAlpha, this.getPagesCount() - 1);
		},
	},
	watch: {
		async type_id(val, oldVal){
			this.$router.push({query: Object.assign({}, this.$route.query, {
				type_id: val,
			})});
			await this.loadTasksStatuses();
			await this.loadData();
		},
	},
	methods: {
		range: lib.range,
		
		async getAvatar(user_id){
			let ret = await this.appModel.doGetUserAvatar(user_id);
			return ret.avatar.originalAvaLink;
		},
		async loadProjects(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetProjects(this.$route.params.virtualspace_id);
			if(ret.success){
				this.projects = ret.list;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadTasksTypes(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetTasksTypes(this.$route.params.virtualspace_id);
			if(ret.success){
				this.tasksTypes = ret.list;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadTasksStatuses(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetTasksStatusesByTasksType(this.$route.params.virtualspace_id, this.type_id);
			if(ret.success){
				this.tasksStatuses = ret.list;
				this.tasksStatuses.forEach((status) => {
					this.columns[status.id] = [];
				});
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadTasksPriorities(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetTasksPriorities(this.$route.params.virtualspace_id);
			if(ret.success){
				this.tasksPriorities = ret.list;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadUsers(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetUsers(this.$route.params.virtualspace_id);
			if(ret.success){
				this.users = ret.list;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadTags(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetTags(this.$route.params.virtualspace_id);
			if(ret.success){
				this.tags = ret.list;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		async loadTargets(){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetTargets(this.$route.params.virtualspace_id);
			if(ret.success){
				this.targets = ret.list;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		async loadData(){
			this.tasksStatuses.forEach((status) => {
				this.columns[status.id] = [];
			});
			this.loading = true;
			this.error = '';
			this.$router.push({query: Object.assign({}, this.$route.query, {
				filter: JSON.stringify(this.filter),
				sort: JSON.stringify(this.sort),
				page: this.page,
			})});
			let params = {
				start: (this.page - 1) * this.limit,
				limit: this.limit,
			};
			for(let [index, el] of Object.entries(this.filter)){
				let el_val = el.value;
				if(el.operator == 'like') el_val = '%'+el.value+'%';
				params['filter['+index+'][field]'] = el.field;
				params['filter['+index+'][operator]'] = el.operator;
				params['filter['+index+'][value]'] = el_val;
			}
			params['filter['+this.filter.length+'][field]'] = 't.type_id';
			params['filter['+this.filter.length+'][operator]'] = '=';
			params['filter['+this.filter.length+'][value]'] = this.type_id;
			for(let [index, el] of Object.entries(this.sort)){
				params['sort['+index+'][field]'] = el.field;
				params['sort['+index+'][direction]'] = el.direction;
			}
			let ret = await this.appModel.doGetTasks(this.$route.params.virtualspace_id, params);
			if(ret.success){
				this.tasks = ret.list;
				this.tasks_count = ret.count;
				
				this.tasksStatuses.forEach((status) => {
					this.columns[status.id] = this.tasks.filter((el) => el.status_id == status.id);
				});
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		onShowAddModal(status_id){
			let that = this;
			this.$vfm.show({
				component: TaskAddModal,
				bind: {
					styles: {
						display: 'block',
					},
				},
				on: {
					add(close){
						that.loadData();
						close();
					},
					cancel(close){
						close();
					},
				},
				params: {
					virtualspace_id: this.$route.params.virtualspace_id,
					type_id: this.type_id,
					type_name: this.tasksTypes.find((el) => el.id == this.type_id).name,
					status_id,
					status_name: this.tasksStatuses.find((el) => el.id == status_id).name,
					status_type_color: this.tasksStatuses.find((el) => el.id == status_id).status_type_color,
				},
			});
		},
		onShowEditModal(id){
			if(!this.dragmove_handle){
				let that = this;
				this.$vfm.show({
					component: TaskEditModal,
					bind: {
						styles: {
							display: 'block',
						},
					},
					on: {
						edit(close){
							that.loadData();
							close();
						},
						delete(close){
							that.loadData();
							close();
						},
						reload(){
							that.loadData();
						},
						cancel(close){
							close();
						},
					},
					params: {
						virtualspace_id: this.$route.params.virtualspace_id,
						task: that.tasks.find((el) => el.id == id),
					},
				});
			}
		},
		
		onDragMoveTask(evt){
			/*console.log(
				'move from section: ' +
				evt.from.dataset.statusId +
				' index: ' +
				evt.draggedContext.index +
				' to section: ' +
				evt.to.dataset.statusId +
				' index: ' +
				evt.draggedContext.futureIndex
			);*/
			this.dragmove_from_status_id = parseInt(evt.from.dataset.statusId);
			this.dragmove_to_status_id = parseInt(evt.to.dataset.statusId);
		},
		async onDragChangeTask(evt){
			if(Object.keys(evt).includes('added')){
				evt.added.element.status_id = this.dragmove_to_status_id;
				// update status_id
				let ret = await this.appModel.doEditTask(this.$route.params.virtualspace_id, evt.added.element.id, {
					status_id: this.dragmove_to_status_id,
				})
				if(ret.success){
					// update poses for from and to columns
					let ret1 = await this.onDragChangeTaskPoses(this.dragmove_from_status_id);
					let ret2 = await this.onDragChangeTaskPoses(this.dragmove_to_status_id);
					if(!ret1 || !ret2){
						await this.loadData();
					}
				} else {
					await this.loadData();
				}
				this.dragmove_from_status_id = '';
				this.dragmove_to_status_id = '';
			} else if(Object.keys(evt).includes('moved')){
				// update poses for to column
				this.onDragChangeTaskPoses(this.dragmove_to_status_id);
				this.dragmove_from_status_id = '';
				this.dragmove_to_status_id = '';
			}
			//console.log(evt);
		},
		// update poses for column
		async onDragChangeTaskPoses(status_id){
			if(this.columns[status_id].length > 0){
				let ids_pos = {};
				this.columns[status_id].forEach((el, index) => {
					ids_pos[el.id] = index;
				});
				let ret = await this.appModel.doEditTasksPos(this.$route.params.virtualspace_id, ids_pos);
				return (ret || {}).success || false;
			}
			return true;
		},
		
		getStructParam(field){
			return this.structParams.find((el) => el.field == field);
		},
		getFilterOperatorsByType(type){
			let operators = [], def = '';
			if(type == 'input' || type == 'text' || type == 'email' || type == 'phonenumber'){
				operators = [
					{operator: 'like', label: 'Содержит'},
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
				];
				if(type == 'phonenumber'){
					def = '=';
				} else {
					def = 'like';
				}
			} else if(type == 'select' || type == 'boolean'){
				operators = [
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
				];
				def = '=';
			} else if(type == 'number' || type == 'datepicker'){
				operators = [
					{operator: '=', label: 'Равно'},
					{operator: '!=', label: 'Не равно'},
					{operator: '>', label: 'Больше'},
					{operator: '>=', label: 'Больше или равно'},
					{operator: '<', label: 'Меньше'},
					{operator: '<=', label: 'Меньше или равно'},
				];
				def = '=';
			} else if(type == 'date'){
				operators = [
					{operator: 'like', label: 'Равно'},
					{operator: 'not like', label: 'Не равно'},
					{operator: '>', label: 'Больше'},
					{operator: '>=', label: 'Больше или равно'},
					{operator: '<', label: 'Меньше'},
					{operator: '<=', label: 'Меньше или равно'},
				];
				def = 'like';
			}
			return {
				operators,
				def,
			};
		},
		onAddFilterParam(){
			if(this.filterAddField != ''){
				this.filter.push({
					field: this.filterAddField,
					operator: this.getFilterOperatorsByType(this.getStructParam(this.filterAddField).type).def,
					value: '',
				});
				this.filterAddField = '';
			}
		},
		onDelFilterParam(index){
			this.filter.splice(index, 1);
		},
		onAddSortParam(){
			if(this.sortAddField != ''){
				this.sort.push({
					field: this.sortAddField,
					direction: 'asc',
				});
				this.sortAddField = '';
				this.page = 1;
				this.loadData();
			}
		},
		onDelSortParam(index){
			this.sort.splice(index, 1);
			this.page = 1;
			this.loadData();
		},
		
		gotoPage(page){
			if(page >= 1 && page <= this.getPagesCount() && page != this.page){
				this.page = page;
				this.loadData();
				window.scrollTo(0, 0);
			}
		},
		// получение количества страниц
		getPagesCount(){
			return Math.ceil(this.tasks_count / this.limit);
		},
		
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	async mounted(){
		window.scrollTo(0, 0);
		
		this.appModel.titleCabinet.icon.type = '';
		this.appModel.titleCabinet.icon.icon = '';
		this.appModel.titleCabinet.title = 'Задачи (доска)';
		this.appModel.titleCabinet.buttons = [
			{
				type: 'group-btn', // btn, group-btn
				buttons: [
					{
						type: 'btn', // btn, group-btn
						title: '<i class="fa fa-list"></i>',
						tooltip: 'Список',
						link_type: 'router-link', // router-link, a, div
						onclick: () => {},
						href: {path: '/vs/'+this.$route.params.virtualspace_id+'/tasks', query: {view: 'list'}},
						target_blank: false,
						visible: true,
						selected: false,
						btnclass: 'btn-light',
						class: '',
						subitems: [],
					},
					{
						type: 'btn', // btn, group-btn
						title: '<i class="fa fa-columns"></i>',
						tooltip: 'Доска',
						link_type: 'router-link', // router-link, a, div
						onclick: () => {},
						href: {path: '/vs/'+this.$route.params.virtualspace_id+'/tasks', query: {view: 'board'}},
						target_blank: false,
						visible: true,
						selected: true,
						btnclass: 'btn-light',
						class: '',
						subitems: [],
					},
					{
						type: 'btn', // btn, group-btn
						title: '<i class="fa fa-calendar-week"></i>',
						tooltip: 'Неделя',
						link_type: 'router-link', // router-link, a, div
						onclick: () => {},
						href: {path: '/vs/'+this.$route.params.virtualspace_id+'/tasks', query: {view: 'week'}},
						target_blank: false,
						visible: true,
						selected: false,
						btnclass: 'btn-light',
						class: '',
						subitems: [],
					},
					{
						type: 'btn', // btn, group-btn
						title: '<i class="fa fa-calendar-alt"></i>',
						tooltip: 'Месяц',
						link_type: 'router-link', // router-link, a, div
						onclick: () => {},
						href: {path: '/vs/'+this.$route.params.virtualspace_id+'/tasks', query: {view: 'month'}},
						target_blank: false,
						visible: true,
						selected: false,
						btnclass: 'btn-light',
						class: '',
						subitems: [],
					},
					{
						type: 'btn', // btn, group-btn
						title: '<svg width="16" height="16" viewBox="0 0 16 16" focusable="false" role="presentation"><path fill="currentColor" fill-rule="evenodd" d="M0,2.4C0,2.4,0.1,2.4,0,2.4c0.1,0,0.1,0,0.2,0c2,0,4.1,0,6.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1 c0-0.7,0-1.4,0-2.1c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0C7,0,7.4,0,7.9,0C7.9,0,8,0,8,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1 c0,5.2,0,10.5,0,15.7c0,0,0,0.1,0,0.1c0,0,0,0,0,0c-0.2,0-1.5,0-1.5,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-1,0-1.9,0-2.9c0,0,0-0.1,0-0.1 c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-1,0-1.9,0-2.9,0c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c0-0.3,0-0.6,0-1c0-0.4,0-0.8,0-1.2 c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c1,0,2,0,2.9,0c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3 c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-1.5,0-3,0-4.5,0c0,0-0.1,0-0.1,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1 c0-0.7,0-1.4,0-2.2c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c1.5,0,3,0,4.5,0c0,0,0.1,0,0.1,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1 c0-0.5,0-0.9,0-1.4c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-2,0-4.1,0-6.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0-0.1 C0,4.4,0,2.5,0,2.4z"/><path fill="currentColor" fill-rule="evenodd" d="M9.6,12.8c0-0.1,0-1.7,0-2.3c0,0,0,0,0-0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0c2,0,4.1,0,6.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1 c0,0,0,0,0,0.1c0,0.7,0,1.4,0,2.1c0,0,0,0.1,0,0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-2,0-4.1,0-6.1,0C9.7,12.8,9.7,12.8,9.6,12.8z"/><path fill="currentColor" fill-rule="evenodd" d="M13.6,8.8C13.6,8.8,13.6,8.8,13.6,8.8c-1.4,0-2.7,0-4,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0-0.7,0-1.4,0-2.1 c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0c0.3,0,3.8,0,3.9,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.7,0,1.4,0,2.2C13.6,8.7,13.6,8.7,13.6,8.8z"/><path fill="currentColor" fill-rule="evenodd" d="M12,4.8c-0.1,0-2,0-2.3,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.7,0-1.5,0-2.2c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0 c0,0,0,0,0.1,0c0.7,0,1.4,0,2.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.7,0,1.4,0,2.2C12,4.7,12,4.8,12,4.8z"/></svg>',
						tooltip: 'Гант',
						link_type: 'router-link', // router-link, a, div
						onclick: () => {},
						href: {path: '/vs/'+this.$route.params.virtualspace_id+'/tasks', query: {view: 'gantt'}},
						target_blank: false,
						visible: true,
						selected: false,
						btnclass: 'btn-light',
						class: '',
						subitems: [],
					},
				],
			},
		];
		
		await this.loadProjects();
		await this.loadTasksTypes();
		await this.loadTasksPriorities();
		await this.loadUsers();
		await this.loadTags();
		await this.loadTargets();
		
		this.structParams = [
			{
				type: 'number',
				field: 't.id',
				label: 'ID задачи',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 't.pos',
				label: 'Позиция',
				filter: false,
				sort: true,
			},
			{
				type: 'select',
				field: 't.project_id',
				label: 'Проект',
				options: this.projects.map((el) => ({
					value: el.id,
					label: el.name,
				})),
				filter: true,
				sort: true,
			},
			{
				type: 'select',
				field: 't.for_project_id',
				label: 'Для проекта',
				options: this.projects.map((el) => ({
					value: el.id,
					label: el.name,
				})),
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 't.title',
				label: 'Заголовок задачи',
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 't.description',
				label: 'Описание задачи',
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 't.solutions',
				label: 'Пути решения',
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 't.result_plan',
				label: 'Планируемый результат',
				filter: true,
				sort: true,
			},
			{
				type: 'input',
				field: 't.result_fact',
				label: 'Фактический результат',
				filter: true,
				sort: true,
			},
			{
				type: 'select',
				field: 't.priority_id',
				label: 'Приоритет задачи',
				options: this.tasksPriorities.map((el) => ({
					value: el.id,
					label: el.name,
				})),
				filter: true,
				sort: true,
			},
			{
				type: 'select',
				field: 'tua.user_id',
				label: 'Исполнитель',
				options: this.users.map((el) => ({
					value: el.user_id,
					label: el.fname + ' ' + el.sname + ' @' + el.login,
				})),
				filter: true,
				sort: true,
			},
			{
				type: 'select',
				field: 't.user_created_id',
				label: 'Создатель',
				options: this.users.map((el) => ({
					value: el.user_id,
					label: el.fname + ' ' + el.sname + ' @' + el.login,
				})),
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 't.estimate',
				label: 'Оценка времени (в часах)',
				filter: true,
				sort: true,
			},
			{
				type: 'number',
				field: 't.progress',
				label: 'Прогресс выполнения в % (0-100)',
				filter: true,
				sort: true,
			},
			{
				type: 'boolean',
				field: 't.is_archived',
				label: 'В архиве',
				filter: true,
				sort: true,
			},
			{
				type: 'date',
				field: 't.date_start_plan',
				label: 'Дата начала по плану',
				filter: true,
				sort: true,
			},
			{
				type: 'date',
				field: 't.date_start_fact',
				label: 'Дата начала по факту',
				filter: true,
				sort: true,
			},
			{
				type: 'date',
				field: 't.date_end_plan',
				label: 'Дата завершения по плану (дедлайн)',
				filter: true,
				sort: true,
			},
			{
				type: 'date',
				field: 't.date_end_fact',
				label: 'Дата завершения по факту',
				filter: true,
				sort: true,
			},
			{
				type: 'select',
				field: 'tt.tag_id',
				label: 'Тег',
				options: this.tags.map((el) => ({
					value: el.id,
					label: el.name,
				})),
				filter: true,
				sort: true,
			},
			{
				type: 'select',
				field: 'ttr.target_id',
				label: 'Цель',
				options: this.targets.map((el) => ({
					value: el.id,
					label: el.name,
				})),
				filter: true,
				sort: true,
			},
		];
		
		if(this.$route.query.type_id) this.type_id = parseInt(this.$route.query.type_id) || '';
		if(this.$route.query.page) this.page = parseInt(this.$route.query.page || 1);
		if(this.$route.query.filter) this.filter = JSON.parse(this.$route.query.filter || '[]');
		if(this.$route.query.sort) this.sort = JSON.parse(this.$route.query.sort || '[]');
	},
	components: {
		LoadingSpinner,
		draggable: VueDraggableNext,
	},
}
</script>
<template>
	<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
		<h1 class="h2">Проекты</h1>
		<div class="btn-toolbar mb-2 mb-md-0">
			<button type="button" class="btn btn-primary">Добавить проект</button>
			<!--
			<div class="btn-group me-2">
				<button type="button" class="btn btn-sm btn-outline-secondary">Share</button>
				<button type="button" class="btn btn-sm btn-outline-secondary">Export</button>
			</div>
			<button type="button" class="btn btn-sm btn-outline-secondary dropdown-toggle">
				<span data-feather="calendar"></span>
				This week
			</button>
			-->
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<button type="button" class="btn btn-primary me-1">Primary <span class="badge">42</span></button>
			<button type="button" class="btn btn-secondary me-1">Secondary <span class="badge">42</span></button>
			<button type="button" class="btn btn-success me-1">Success <span class="badge">42</span></button>
			<button type="button" class="btn btn-danger me-1">Danger <span class="badge">42</span></button>
			<button type="button" class="btn btn-warning me-1">Warning <span class="badge">42</span></button>
			<button type="button" class="btn btn-info me-1">Info <span class="badge">42</span></button>
			<button type="button" class="btn btn-light me-1">Light <span class="badge">42</span></button>
			<button type="button" class="btn btn-dark me-1">Dark <span class="badge">42</span></button>
			<button type="button" class="btn btn-link me-1">Link <span class="badge">42</span></button>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<button type="button" class="btn btn-outline-primary me-1">Primary <span class="badge">42</span></button>
			<button type="button" class="btn btn-outline-secondary me-1">Secondary <span class="badge">42</span></button>
			<button type="button" class="btn btn-outline-success me-1">Success <span class="badge">42</span></button>
			<button type="button" class="btn btn-outline-danger me-1">Danger <span class="badge">42</span></button>
			<button type="button" class="btn btn-outline-warning me-1">Warning <span class="badge">42</span></button>
			<button type="button" class="btn btn-outline-info me-1">Info <span class="badge">42</span></button>
			<button type="button" class="btn btn-outline-light me-1">Light <span class="badge">42</span></button>
			<button type="button" class="btn btn-outline-dark me-1">Dark <span class="badge">42</span></button>
			<button type="button" class="btn btn-outline-link me-1">Link <span class="badge">42</span></button>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<button type="button" class="btn btn-primary disabled me-1">Primary <span class="badge">42</span></button>
			<button type="button" class="btn btn-secondary disabled me-1">Secondary <span class="badge">42</span></button>
			<button type="button" class="btn btn-success disabled me-1">Success <span class="badge">42</span></button>
			<button type="button" class="btn btn-danger disabled me-1">Danger <span class="badge">42</span></button>
			<button type="button" class="btn btn-warning disabled me-1">Warning <span class="badge">42</span></button>
			<button type="button" class="btn btn-info disabled me-1">Info <span class="badge">42</span></button>
			<button type="button" class="btn btn-light disabled me-1">Light <span class="badge">42</span></button>
			<button type="button" class="btn btn-dark disabled me-1">Dark <span class="badge">42</span></button>
			<button type="button" class="btn btn-link disabled me-1">Link <span class="badge">42</span></button>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<div class="btn-group me-1">
				<a href="#" class="btn btn-primary">Primary</a>
				<a href="#" class="btn btn-primary dropdown-toggle" data-toggle="dropdown"><span class="caret"></span></a>
				<ul class="dropdown-menu float-end">
					<li><a href="#">Action</a></li>
					<li><a href="#">Another action</a></li>
					<li><a href="#">Something else here</a></li>
					<li class="divider"></li>
					<li><a href="#">Separated link</a></li>
				</ul>
			</div>
			<div class="btn-group me-1">
				<a href="#" class="btn btn-secondary">Secondary</a>
				<a href="#" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown"><span class="caret"></span></a>
				<ul class="dropdown-menu float-end">
					<li><a href="#">Action</a></li>
					<li><a href="#">Another action</a></li>
					<li><a href="#">Something else here</a></li>
					<li class="divider"></li>
					<li><a href="#">Separated link</a></li>
				</ul>
			</div>
			<div class="btn-group me-1">
				<a href="#" class="btn btn-success">Success</a>
				<a href="#" class="btn btn-success dropdown-toggle" data-toggle="dropdown"><span class="caret"></span></a>
				<ul class="dropdown-menu float-end">
					<li><a href="#">Action</a></li>
					<li><a href="#">Another action</a></li>
					<li><a href="#">Something else here</a></li>
					<li class="divider"></li>
					<li><a href="#">Separated link</a></li>
				</ul>
			</div>
			<div class="btn-group me-1">
				<a href="#" class="btn btn-link">Warning</a>
				<a href="#" class="btn btn-link dropdown-toggle" data-toggle="dropdown"><span class="caret"></span></a>
				<ul class="dropdown-menu float-end">
					<li><a href="#">Action</a></li>
					<li><a href="#">Another action</a></li>
					<li><a href="#">Something else here</a></li>
					<li class="divider"></li>
					<li><a href="#">Separated link</a></li>
				</ul>
			</div>
			<div class="btn-group me-1">
				<a href="#" class="btn btn-warning">Warning</a>
				<a href="#" class="btn btn-warning dropdown-toggle" data-toggle="dropdown"><span class="caret"></span></a>
				<ul class="dropdown-menu float-end">
					<li><a href="#">Action</a></li>
					<li><a href="#">Another action</a></li>
					<li><a href="#">Something else here</a></li>
					<li class="divider"></li>
					<li><a href="#">Separated link</a></li>
				</ul>
			</div>
			<div class="btn-group me-1">
				<a href="#" class="btn btn-info">Info</a>
				<a href="#" class="btn btn-info dropdown-toggle" data-toggle="dropdown"><span class="caret"></span></a>
				<ul class="dropdown-menu float-end">
					<li><a href="#">Action</a></li>
					<li><a href="#">Another action</a></li>
					<li><a href="#">Something else here</a></li>
					<li class="divider"></li>
					<li><a href="#">Separated link</a></li>
				</ul>
			</div>
			<div class="btn-group me-1">
				<a href="#" class="btn btn-light">Light</a>
				<a href="#" class="btn btn-light dropdown-toggle" data-toggle="dropdown"><span class="caret"></span></a>
				<ul class="dropdown-menu float-end">
					<li><a href="#">Action</a></li>
					<li><a href="#">Another action</a></li>
					<li><a href="#">Something else here</a></li>
					<li class="divider"></li>
					<li><a href="#">Separated link</a></li>
				</ul>
			</div>
			<div class="btn-group me-1">
				<a href="#" class="btn btn-dark">Dark</a>
				<a href="#" class="btn btn-dark dropdown-toggle" data-toggle="dropdown"><span class="caret"></span></a>
				<ul class="dropdown-menu float-end">
					<li><a href="#">Action</a></li>
					<li><a href="#">Another action</a></li>
					<li><a href="#">Something else here</a></li>
					<li class="divider"></li>
					<li><a href="#">Separated link</a></li>
				</ul>
			</div>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<a href="#" class="btn btn-primary btn-lg me-1">Large button <span class="badge">42</span></a>
			<a href="#" class="btn btn-primary me-1">Default button <span class="badge">42</span></a>
			<a href="#" class="btn btn-primary btn-sm me-1">Small button <span class="badge">42</span></a>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<a href="#" class="btn btn-default btn-lg btn-block">Block level button</a>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<div class="btn-group btn-group-justified">
				<a href="#" class="btn btn-default">Left</a>
				<a href="#" class="btn btn-default">Middle</a>
				<a href="#" class="btn btn-default">Right</a>
			</div>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<div class="btn-toolbar">
				<div class="btn-group">
					<a href="#" class="btn btn-default">1</a>
					<a href="#" class="btn btn-default">2</a>
					<a href="#" class="btn btn-default">3</a>
					<a href="#" class="btn btn-default">4</a>
				</div>
				<div class="btn-group">
					<a href="#" class="btn btn-default">5</a>
					<a href="#" class="btn btn-default">6</a>
					<a href="#" class="btn btn-default">7</a>
				</div>
				<div class="btn-group">
					<a href="#" class="btn btn-default">8</a>
					<div class="btn-group">
						<a href="#" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
							Dropdown
							<span class="caret"></span>
						</a>
						<ul class="dropdown-menu">
							<li><a href="#">Dropdown link</a></li>
							<li><a href="#">Dropdown link</a></li>
							<li><a href="#">Dropdown link</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<div class="btn-group-vertical">
				<a href="#" class="btn btn-default">Button</a>
				<a href="#" class="btn btn-default">Button</a>
				<a href="#" class="btn btn-default">Button</a>
				<a href="#" class="btn btn-default">Button</a>
			</div>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<h1>Heading 1</h1>
			<h2>Heading 2</h2>
			<h3>Heading 3</h3>
			<h4>Heading 4</h4>
			<h5>Heading 5</h5>
			<h6>Heading 6</h6>
			<p class="lead">Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<h2>Example body text</h2>
			<p>Nullam quis risus eget <a href="#">urna mollis ornare</a> vel eu leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam id dolor id nibh ultricies vehicula.</p>
			<p><small>This line of text is meant to be treated as fine print.</small></p>
			<p>The following snippet of text is <strong>rendered as bold text</strong>.</p>
			<p>The following snippet of text is <em>rendered as italicized text</em>.</p>
			<p>An abbreviation of the word attribute is <abbr title="attribute">attr</abbr>.</p>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<h2>Emphasis classes</h2>
			<p class="text-muted">Fusce dapibus, tellus ac cursus commodo, tortor mauris nibh.</p>
			<p class="text-primary">Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
			<p class="text-warning">Etiam porta sem malesuada magna mollis euismod.</p>
			<p class="text-danger">Donec ullamcorper nulla non metus auctor fringilla.</p>
			<p class="text-success">Duis mollis, est non commodo luctus, nisi erat porttitor ligula.</p>
			<p class="text-info">Maecenas sed diam eget risus varius blandit sit amet non magna.</p>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<blockquote>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
				<small>Someone famous in <cite title="Source Title">Source Title</cite></small>
			</blockquote>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<blockquote class="blockquote-reverse">
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
				<small>Someone famous in <cite title="Source Title">Source Title</cite></small>
			</blockquote>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<table class="table table-striped table-hover">
				<thead>
					<tr>
						<th>#</th>
						<th>Column heading</th>
						<th>Column heading</th>
						<th>Column heading</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>1</td>
						<td>Column content</td>
						<td>Column content</td>
						<td>Column content</td>
					</tr>
					<tr>
						<td>2</td>
						<td>Column content</td>
						<td>Column content</td>
						<td>Column content</td>
					</tr>
					<tr class="info">
						<td>3</td>
						<td>Column content</td>
						<td>Column content</td>
						<td>Column content</td>
					</tr>
					<tr class="success">
						<td>4</td>
						<td>Column content</td>
						<td>Column content</td>
						<td>Column content</td>
					</tr>
					<tr class="danger">
						<td>5</td>
						<td>Column content</td>
						<td>Column content</td>
						<td>Column content</td>
					</tr>
					<tr class="warning">
						<td>6</td>
						<td>Column content</td>
						<td>Column content</td>
						<td>Column content</td>
					</tr>
					<tr class="active">
						<td>7</td>
						<td>Column content</td>
						<td>Column content</td>
						<td>Column content</td>
					</tr>
					<tr>
						<td colspan="4">
							<div class="label label-default">.table-striped</div>
							<div class="label label-default">.table-hover</div>
							<div class="label label-default">.table-responsive</div>
							<div class="label label-default">.table-condensed</div>
							<div class="label label-default">.table-bordered</div>
							<div class="label label-default">.table-no-tborder</div>
							<div class="label label-default">.table-no-thead-border</div>
							<div class="label label-default">.table-no-tbody-border</div>
							<div class="label label-default">.table-no-tfoot-border</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<form class="form-horizontal">
				<fieldset>
					<legend>Legend</legend>
					<div class="form-group">
						<label for="inputEmail" class="col-lg-2 control-label">Email</label>
						<div class="col-lg-10">
							<input type="text" class="form-control" id="inputEmail" placeholder="Email">
						</div>
					</div>
					<div class="form-group">
						<label for="inputPassword" class="col-lg-2 control-label">Password</label>
						<div class="col-lg-10">
							<input type="password" class="form-control" id="inputPassword" placeholder="Password">
							<div class="checkbox">
								<label>
									<input type="checkbox"> Checkbox
								</label>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label for="textArea" class="col-lg-2 control-label">Textarea</label>
						<div class="col-lg-10">
							<textarea class="form-control" rows="3" id="textArea"></textarea>
							<span class="help-block">A longer block of help text that breaks onto a new line and may extend beyond one line.</span>
						</div>
					</div>
					<div class="form-group">
						<label class="col-lg-2 control-label">Radios</label>
						<div class="col-lg-10">
							<div class="radio">
								<label>
									<input type="radio" name="optionsRadios" id="optionsRadios1" value="option1" checked="">
									Option one is this
								</label>
							</div>
							<div class="radio">
								<label>
									<input type="radio" name="optionsRadios" id="optionsRadios2" value="option2">
									Option two can be something else
								</label>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label for="select" class="col-lg-2 control-label">Selects</label>
						<div class="col-lg-10">
							<select class="form-control" id="select">
								<option>1</option>
								<option>2</option>
								<option>3</option>
								<option>4</option>
								<option>5</option>
							</select>
							<br>
							<select multiple="" class="form-control">
								<option>1</option>
								<option>2</option>
								<option>3</option>
								<option>4</option>
								<option>5</option>
							</select>
						</div>
					</div>
					<div class="form-group">
						<div class="col-lg-10 col-lg-offset-2">
							<button type="reset" class="btn btn-default">Cancel</button>
							<button type="submit" class="btn btn-primary">Submit</button>
						</div>
					</div>
				</fieldset>
			</form>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<div class="form-group">
				<label class="control-label" for="focusedInput">Focused input</label>
				<input class="form-control" id="focusedInput" type="text" value="This is focused...">
			</div>
			<div class="form-group">
				<label class="control-label" for="disabledInput">Disabled input</label>
				<input class="form-control" id="disabledInput" type="text" placeholder="Disabled input here..." disabled="">
			</div>
			<div class="form-group has-warning">
				<label class="control-label" for="inputWarning">Input warning</label>
				<input type="text" class="form-control" id="inputWarning">
			</div>
			<div class="form-group has-error">
				<label class="control-label" for="inputError">Input error</label>
				<input type="text" class="form-control" id="inputError">
			</div>
			<div class="form-group has-success">
				<label class="control-label" for="inputSuccess">Input success</label>
				<input type="text" class="form-control" id="inputSuccess">
			</div>
			<div class="form-group">
				<label class="control-label" for="inputLarge">Large input</label>
				<input class="form-control input-lg" type="text" id="inputLarge">
			</div>
			<div class="form-group">
				<label class="control-label" for="inputDefault">Default input</label>
				<input type="text" class="form-control" id="inputDefault">
			</div>
			<div class="form-group">
				<label class="control-label" for="inputSmall">Small input</label>
				<input class="form-control input-sm" type="text" id="inputSmall">
			</div>
			<div class="form-group">
				<label class="control-label">Input addons</label>
				<div class="input-group">
					<span class="input-group-addon">$</span>
					<input type="text" class="form-control">
					<span class="input-group-btn">
						<button class="btn btn-default" type="button">Button</button>
					</span>
				</div>
			</div>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<ul class="nav nav-tabs">
				<li class="active"><a href="#home" data-toggle="tab">Home</a></li>
				<li><a href="#profile" data-toggle="tab">Profile</a></li>
				<li class="disabled"><a>Disabled</a></li>
				<li class="dropdown">
					<a class="dropdown-toggle" data-toggle="dropdown" href="#">
						Dropdown <span class="caret"></span>
					</a>
					<ul class="dropdown-menu">
						<li><a href="#dropdown1" data-toggle="tab">Action</a></li>
						<li class="divider"></li>
						<li><a href="#dropdown2" data-toggle="tab">Another action</a></li>
					</ul>
				</li>
			</ul>
			<div id="myTabContent" class="tab-content">
				<div class="tab-pane fade active in" id="home">
					<p>Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
				</div>
				<div class="tab-pane fade" id="profile">
					<p>Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid. Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit.</p>
				</div>
				<div class="tab-pane fade" id="dropdown1">
					<p>Etsy mixtape wayfarers, ethical wes anderson tofu before they sold out mcsweeney's organic lomo retro fanny pack lo-fi farm-to-table readymade. Messenger bag gentrify pitchfork tattooed craft beer, iphone skateboard locavore carles etsy salvia banksy hoodie helvetica. DIY synth PBR banksy irony. Leggings gentrify squid 8-bit cred pitchfork.</p>
				</div>
				<div class="tab-pane fade" id="dropdown2">
					<p>Trust fund seitan letterpress, keytar raw denim keffiyeh etsy art party before they sold out master cleanse gluten-free squid scenester freegan cosby sweater. Fanny pack portland seitan DIY, art party locavore wolf cliche high life echo park Austin. Cred vinyl keffiyeh DIY salvia PBR, banh mi before they sold out farm-to-table VHS viral locavore cosby sweater.</p>
				</div>
			</div>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<ul class="nav nav-pills">
				<li class="active"><a href="#">Home</a></li>
				<li><a href="#">Profile</a></li>
				<li class="disabled"><a href="#">Disabled</a></li>
				<li class="dropdown">
					<a class="dropdown-toggle" data-toggle="dropdown" href="#">
						Dropdown <span class="caret"></span>
					</a>
					<ul class="dropdown-menu">
						<li><a href="#">Action</a></li>
						<li><a href="#">Another action</a></li>
						<li><a href="#">Something else here</a></li>
						<li class="divider"></li>
						<li><a href="#">Separated link</a></li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<ul class="nav nav-pills nav-stacked">
				<li class="active"><a href="#">Home</a></li>
				<li><a href="#">Profile</a></li>
				<li class="disabled"><a href="#">Disabled</a></li>
				<li class="dropdown">
					<a class="dropdown-toggle" data-toggle="dropdown" href="#">
						Dropdown <span class="caret"></span>
					</a>
					<ul class="dropdown-menu">
						<li><a href="#">Action</a></li>
						<li><a href="#">Another action</a></li>
						<li><a href="#">Something else here</a></li>
						<li class="divider"></li>
						<li><a href="#">Separated link</a></li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<ul class="breadcrumb">
				<li class="active">Home</li>
			</ul>
			<ul class="breadcrumb">
				<li><a href="#">Home</a></li>
				<li class="active">Library</li>
			</ul>
			<ul class="breadcrumb">
				<li><a href="#">Home</a></li>
				<li><a href="#">Library</a></li>
				<li class="active">Data</li>
			</ul>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<ul class="pagination">
				<li class="disabled"><a href="#">&laquo;</a></li>
				<li class="active"><a href="#">1</a></li>
				<li><a href="#">2</a></li>
				<li><a href="#">3</a></li>
				<li><a href="#">4</a></li>
				<li><a href="#">5</a></li>
				<li><a href="#">&raquo;</a></li>
			</ul>
			<ul class="pagination pagination-lg">
				<li class="disabled"><a href="#">&laquo;</a></li>
				<li class="active"><a href="#">1</a></li>
				<li><a href="#">2</a></li>
				<li><a href="#">3</a></li>
				<li><a href="#">&raquo;</a></li>
			</ul>
			<ul class="pagination pagination-sm">
				<li class="disabled"><a href="#">&laquo;</a></li>
				<li class="active"><a href="#">1</a></li>
				<li><a href="#">2</a></li>
				<li><a href="#">3</a></li>
				<li><a href="#">4</a></li>
				<li><a href="#">5</a></li>
				<li><a href="#">&raquo;</a></li>
			</ul>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<ul class="pager">
				<li class="disabled"><a href="#">Previous</a></li>
				<li><a href="#">Next</a></li>
			</ul>
			<ul class="pager">
				<li class="previous disabled"><a href="#">&larr; Older</a></li>
				<li class="next"><a href="#">Newer &rarr;</a></li>
			</ul>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<div class="alert alert-dismissible alert-warning">
				<button type="button" class="close" data-dismiss="alert">&times;</button>
				<h4>Warning!</h4>
				<p>Best check yo self, you're not looking too good. Nulla vitae elit libero, a pharetra augue. Praesent commodo cursus magna, <a href="#" class="alert-link">vel scelerisque nisl consectetur et</a>.</p>
			</div>
			<div class="alert alert-dismissible alert-danger">
				<button type="button" class="close" data-dismiss="alert">&times;</button>
				<strong>Oh snap!</strong> <a href="#" class="alert-link">Change a few things up</a> and try submitting again.
			</div>
			<div class="alert alert-dismissible alert-success">
				<button type="button" class="close" data-dismiss="alert">&times;</button>
				<strong>Well done!</strong> You successfully read <a href="#" class="alert-link">this important alert message</a>.
			</div>
			<div class="alert alert-dismissible alert-info">
				<button type="button" class="close" data-dismiss="alert">&times;</button>
				<strong>Heads up!</strong> This <a href="#" class="alert-link">alert needs your attention</a>, but it's not super important.
			</div>
			
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<span class="label label-default">Default</span>
			<span class="label label-primary">Primary</span>
			<span class="label label-success">Success</span>
			<span class="label label-warning">Warning</span>
			<span class="label label-danger">Danger</span>
			<span class="label label-info">Info</span>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<ul class="nav nav-pills">
				<li class="active"><a href="#">Home <span class="badge">42</span></a></li>
				<li><a href="#">Profile <span class="badge"></span></a></li>
				<li><a href="#">Messages <span class="badge">3</span></a></li>
			</ul>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<div class="progress">
				<div class="progress-bar" style="width: 60%;"></div>
			</div>
			<div class="progress">
				<div class="progress-bar progress-bar-info" style="width: 20%"></div>
			</div>
			<div class="progress">
				<div class="progress-bar progress-bar-success" style="width: 40%"></div>
			</div>
			<div class="progress">
				<div class="progress-bar progress-bar-warning" style="width: 60%"></div>
			</div>
			<div class="progress">
				<div class="progress-bar progress-bar-danger" style="width: 80%"></div>
			</div>
			<div class="progress progress-striped">
				<div class="progress-bar progress-bar-info" style="width: 20%"></div>
			</div>
			<div class="progress progress-striped">
				<div class="progress-bar progress-bar-success" style="width: 40%"></div>
			</div>
			<div class="progress progress-striped">
				<div class="progress-bar progress-bar-warning" style="width: 60%"></div>
			</div>
			<div class="progress progress-striped">
				<div class="progress-bar progress-bar-danger" style="width: 80%"></div>
			</div>
			<div class="progress progress-striped active">
				<div class="progress-bar" style="width: 45%"></div>
			</div>
			<div class="progress">
				<div class="progress-bar progress-bar-success" style="width: 35%"></div>
				<div class="progress-bar progress-bar-warning" style="width: 20%"></div>
				<div class="progress-bar progress-bar-danger" style="width: 10%"></div>
			</div>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<div class="jumbotron">
				<h1>Jumbotron</h1>
				<p>This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
				<p><a class="btn btn-primary btn-lg">Learn more</a></p>
			</div>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<ul class="list-group">
				<li class="list-group-item">
					<span class="badge">14</span>
					Cras justo odio
				</li>
				<li class="list-group-item">
					<span class="badge">2</span>
					Dapibus ac facilisis in
				</li>
				<li class="list-group-item">
					<span class="badge">1</span>
					Morbi leo risus
				</li>
			</ul>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<div class="list-group">
				<a href="#" class="list-group-item active">Cras justo odio</a>
				<a href="#" class="list-group-item">Dapibus ac facilisis in</a>
				<a href="#" class="list-group-item">Morbi leo risus</a>
			</div>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<div class="list-group">
				<a href="#" class="list-group-item active">
					<h4 class="list-group-item-heading">List group item heading</h4>
					<p class="list-group-item-text">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>
				</a>
				<a href="#" class="list-group-item">
					<h4 class="list-group-item-heading">List group item heading</h4>
					<p class="list-group-item-text">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>
				</a>
				<a href="#" class="list-group-item">
					<h4 class="list-group-item-heading">List group item heading</h4>
					<p class="list-group-item-text">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>
				</a>
			</div>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<div class="panel panel-default">
				<div class="panel-body">
					Basic panel
				</div>
			</div>
			<div class="panel panel-default">
				<div class="panel-body">
					Panel content
					<div class="line pull-in"></div>
					Panel content
					<div class="line line-default pull-in"></div>
					Panel content
					<div class="line line-white pull-in"></div>
					Panel content
					<div class="line line-primary pull-in"></div>
					Panel content
					<div class="line line-success pull-in"></div>
					Panel content
					<div class="line line-warning pull-in"></div>
					Panel content
					<div class="line line-danger pull-in"></div>
					Panel content
					<div class="line line-info pull-in"></div>
					Panel content
				</div>
			</div>
			<div class="panel panel-default">
				<div class="panel-heading">Panel heading</div>
				<div class="panel-body">
					Panel content
					<div class="line pull-in"></div>
					Panel content
				</div>
			</div>
			<div class="panel panel-default">
				<div class="panel-body">
					Panel content
					<div class="line pull-in"></div>
					Panel content
				</div>
				<div class="panel-footer">Panel footer</div>
			</div>
			<div class="panel panel-white">
				<div class="panel-heading">
					<h3 class="panel-title">Panel white</h3>
				</div>
				<div class="panel-body">
					Panel content
					<div class="line pull-in"></div>
					Panel content
				</div>
				<div class="panel-footer">Panel footer</div>
			</div>
			<div class="panel panel-primary">
				<div class="panel-heading">
					<h3 class="panel-title">Panel primary</h3>
				</div>
				<div class="panel-body">
					Panel content
					<div class="line pull-in"></div>
					Panel content
				</div>
			</div>
			<div class="panel panel-success">
				<div class="panel-heading">
					<h3 class="panel-title">Panel success</h3>
				</div>
				<div class="panel-body">
					Panel content
					<div class="line pull-in"></div>
					Panel content
				</div>
			</div>
			<div class="panel panel-warning">
				<div class="panel-heading">
					<h3 class="panel-title">Panel warning</h3>
				</div>
				<div class="panel-body">
					Panel content
					<div class="line pull-in"></div>
					Panel content
				</div>
			</div>
			<div class="panel panel-danger">
				<div class="panel-heading">
					<h3 class="panel-title">Panel danger</h3>
				</div>
				<div class="panel-body">
					Panel content
					<div class="line pull-in"></div>
					Panel content
				</div>
			</div>
			<div class="panel panel-info">
				<div class="panel-heading">
					<h3 class="panel-title">Panel info</h3>
				</div>
				<div class="panel-body">
					Panel content
					<div class="line pull-in"></div>
					Panel content
				</div>
			</div>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<div class="well">
				Look, I'm in a well!
			</div>
			<div class="well well-sm">
				Look, I'm in a small well!
			</div>
			<div class="well well-lg">
				Look, I'm in a large well!
			</div>
		</div>
	</div>
	<div class="row mb-4">
		<div class="col-md-12">
			<ul class="nav nav-tabs" role="tablist">
				<li role="presentation" class="active">
					<a href="#tabHome" role="tab" data-toggle="tab">Home</a>
				</li>
				<li role="presentation">
					<a href="#tabProfile" role="tab" data-toggle="tab">Profile</a>
				</li>
				<li role="presentation" class="dropdown">
					<a href="#" class="dropdown-toggle" data-toggle="dropdown">
						Dropdown <span class="caret"></span>
					</a>
					<ul class="dropdown-menu">
						<li>
							<a href="#tabDropdown1" role="tab" data-toggle="tab">@fat</a>
						</li>
						<li>
							<a href="#tabDropdown2" role="tab" data-toggle="tab">@mdo</a>
						</li>
					</ul>
				</li>
			</ul>
			<div class="tab-content">
				<div class="tab-pane fade in active" role="tabpanel" id="tabHome">
					<p>Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
				</div>
				<div class="tab-pane fade" role="tabpanel" id="tabProfile">
					<p>Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid. Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar helvetica VHS salvia yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson 8-bit, sustainable jean shorts beard ut DIY ethical culpa terry richardson biodiesel. Art party scenester stumptown, tumblr butcher vero sint qui sapiente accusamus tattooed echo park.</p>
				</div>
				<div class="tab-pane fade" role="tabpanel" id="tabDropdown1">
					<p>Etsy mixtape wayfarers, ethical wes anderson tofu before they sold out mcsweeney's organic lomo retro fanny pack lo-fi farm-to-table readymade. Messenger bag gentrify pitchfork tattooed craft beer, iphone skateboard locavore carles etsy salvia banksy hoodie helvetica. DIY synth PBR banksy irony. Leggings gentrify squid 8-bit cred pitchfork. Williamsburg banh mi whatever gluten-free, carles pitchfork biodiesel fixie etsy retro mlkshk vice blog. Scenester cred you probably haven't heard of them, vinyl craft beer blog stumptown. Pitchfork sustainable tofu synth chambray yr.</p>
				</div>
				<div class="tab-pane fade" role="tabpanel" id="tabDropdown2">
					<p>Trust fund seitan letterpress, keytar raw denim keffiyeh etsy art party before they sold out master cleanse gluten-free squid scenester freegan cosby sweater. Fanny pack portland seitan DIY, art party locavore wolf cliche high life echo park Austin. Cred vinyl keffiyeh DIY salvia PBR, banh mi before they sold out farm-to-table VHS viral locavore cosby sweater. Lomo wolf viral, mustache readymade thundercats keffiyeh craft beer marfa ethical. Wolf salvia freegan, sartorial keffiyeh echo park vegan.</p>
				</div>
			</div>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<div class="panel-group" id="accordion" role="tablist">
				<div class="panel panel-default">
					<div class="panel-heading" role="tab" id="headingOne">
						<h4 class="panel-title">
							<a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">Collapsible Group Item #1</a>
						</h4>
					</div>
					<div id="collapseOne" class="panel-collapse collapse in" role="tabpanel">
						<div class="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</div>
					</div>
				</div>
				<div class="panel panel-default">
					<div class="panel-heading" role="tab" id="headingTwo">
						<h4 class="panel-title">
							<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">Collapsible Group Item #2</a>
						</h4>
					</div>
					<div id="collapseTwo" class="panel-collapse collapse" role="tabpanel">
						<div class="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</div>
					</div>
				</div>
				<div class="panel panel-default">
					<div class="panel-heading" role="tab" id="headingThree">
						<h4 class="panel-title">
							<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">Collapsible Group Item #3</a>
						</h4>
					</div>
					<div id="collapseThree" class="panel-collapse collapse" role="tabpanel">
						<div class="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<button type="button" class="btn btn-default" data-toggle="tooltip" data-placement="left" title="" data-original-title="Tooltip on left">Left</button>
			<button type="button" class="btn btn-default" data-toggle="tooltip" data-placement="top" title="" data-original-title="Tooltip on top">Top</button>
			<button type="button" class="btn btn-default" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Tooltip on bottom">Bottom</button>
			<button type="button" class="btn btn-default" data-toggle="tooltip" data-placement="right" title="" data-original-title="Tooltip on right">Right</button>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<button type="button" class="btn btn-default" data-container="body" data-toggle="popover" data-placement="left" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." data-original-title="" title="">Left</button>
			<button type="button" class="btn btn-default" data-container="body" data-toggle="popover" data-placement="top" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." data-original-title="" title="">Top</button>
			<button type="button" class="btn btn-default" data-container="body" data-toggle="popover" data-placement="bottom" data-content="Vivamus
			sagittis lacus vel augue laoreet rutrum faucibus." data-original-title="" title="">Bottom</button>
			<button type="button" class="btn btn-default" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." data-original-title="" title="">Right</button>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-12">
			<div class="modal">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
							<h4 class="modal-title">Modal title</h4>
						</div>
						<div class="modal-body">
							<p>One fine body…</p>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
							<button type="button" class="btn btn-primary">Save changes</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-6">
			<div class="callout callout-default">
				<h4>Default Callout</h4>
				This is a default callout.
			</div>
			<div class="callout callout-primary">
				<h4>Primary Callout</h4>
				This is a primary callout.
			</div>
			<div class="callout callout-success">
				<h4>Success Callout</h4>
				This is a success callout.
			</div>
			<div class="callout callout-info">
				<h4>Info Callout</h4>
				This is an info callout.
			</div>
			<div class="callout callout-warning">
				<h4>Warning Callout</h4>
				This is a warning callout.
			</div>
			<div class="callout callout-danger">
				<h4>Danger Callout</h4>
				This is a danger callout.
			</div>
		</div>
		<div class="col-md-6">
			<div class="callout callout-default callout-border">
				<h4>Default Callout</h4>
				This is a default callout.
			</div>
			<div class="callout callout-primary callout-border">
				<h4>Primary Callout</h4>
				This is a primary callout.
			</div>
			<div class="callout callout-success callout-border">
				<h4>Success Callout</h4>
				This is a success callout.
			</div>
			<div class="callout callout-info callout-border">
				<h4>Info Callout</h4>
				This is an info callout.
			</div>
			<div class="callout callout-warning callout-border">
				<h4>Warning Callout</h4>
				This is a warning callout.
			</div>
			<div class="callout callout-danger callout-border">
				<h4>Danger Callout</h4>
				This is a danger callout.
			</div>
		</div>
	</div>
	<div class="row mb-1">
		<div class="col-md-6">
			<div class="callout callout-default callout-nobg">
				<h4>Default Callout</h4>
				This is a default callout.
			</div>
			<div class="callout callout-primary callout-nobg">
				<h4>Primary Callout</h4>
				This is a primary callout.
			</div>
			<div class="callout callout-success callout-nobg">
				<h4>Success Callout</h4>
				This is a success callout.
			</div>
			<div class="callout callout-info callout-nobg">
				<h4>Info Callout</h4>
				This is an info callout.
			</div>
			<div class="callout callout-warning callout-nobg">
				<h4>Warning Callout</h4>
				This is a warning callout.
			</div>
			<div class="callout callout-danger callout-nobg">
				<h4>Danger Callout</h4>
				This is a danger callout.
			</div>
		</div>
		<div class="col-md-6">
			<div class="callout callout-default callout-nobg callout-border">
				<h4>Default Callout</h4>
				This is a default callout.
			</div>
			<div class="callout callout-primary callout-nobg callout-border">
				<h4>Primary Callout</h4>
				This is a primary callout.
			</div>
			<div class="callout callout-success callout-nobg callout-border">
				<h4>Success Callout</h4>
				This is a success callout.
			</div>
			<div class="callout callout-info callout-nobg callout-border">
				<h4>Info Callout</h4>
				This is an info callout.
			</div>
			<div class="callout callout-warning callout-nobg callout-border">
				<h4>Warning Callout</h4>
				This is a warning callout.
			</div>
			<div class="callout callout-danger callout-nobg callout-border">
				<h4>Danger Callout</h4>
				This is a danger callout.
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Typography | Tasker'});
	},
	data: () => ({
		projects: [],
		overlay: true,
	}),
	computed: {},
	methods: {},
	mounted(){
		window.scrollTo(0, 0);
		
		this.appModel.enableMainLeftMenu = true;
		this.appModel.titleMainLeftMenu.logo = '<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="32" height="32" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" viewBox="0 0 1224 1637" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path fill="#fff" d="M681 169c7,-50 3,-130 -16,-169 -14,31 -13,52 -38,98 -142,266 -572,436 -606,787 -5,56 5,127 18,166 24,73 23,72 58,147 -29,-14 -75,-32 -97,-11 63,30 102,76 150,121 101,95 214,179 356,191 54,4 99,6 151,-13 6,-2 12,-5 18,-7 -13,38 -20,52 -15,89 2,19 15,53 35,69 10,-29 18,-60 34,-87 14,-24 31,-42 49,-63 36,-37 95,-77 136,-110 73,-62 143,-112 174,-208 20,-58 20,-115 -5,-167 -4,-10 -6,-12 -6,-23 43,24 94,63 147,53 -63,-52 -79,-118 -104,-191 -78,-231 -155,-198 -172,-228 52,16 99,21 140,-19 -45,-5 -92,-23 -139,-36 -123,-35 -259,-45 -368,6 -155,73 -251,272 -204,436 29,-75 26,-123 107,-196 39,-35 113,-80 165,-79 -8,0 -37,18 -48,25 -54,35 -98,84 -117,149 8,-4 11,-7 18,-12l52 -37c31,-15 52,-22 86,-28 55,-11 128,1 172,37 -61,-22 -161,-24 -197,15 15,6 33,4 50,7 168,30 165,180 139,208 -10,-27 -18,-85 -69,-102 22,39 26,147 7,164 -26,-6 -31,-44 -38,-65 -13,42 -8,80 -2,93 -22,24 -55,21 -137,-23 8,27 41,55 65,78 -20,5 -28,7 -54,-5 -16,-7 -33,-20 -47,-32 -29,-25 -43,-47 -59,-73 -32,74 1,144 2,177 -113,-73 -209,-227 -205,-365 4,-129 42,-219 127,-315l131 -148c73,-88 142,-190 156,-304z"/></g></svg>';
		this.appModel.titleMainLeftMenu.link_type = 'a'; // router-link, a, div
		this.appModel.titleMainLeftMenu.onclick = () => {};
		this.appModel.titleMainLeftMenu.href = '#';
		this.appModel.titleMainLeftMenu.tooltip = 'byLex Platform';
		this.appModel.mainLeftMenu = [
			{
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z" fill="currentColor" fill-rule="evenodd"></path></svg>',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'xxx',
				class: '',
				visible: true,
			},
			{
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M5 15h14v2H5zm0-8h14v2H5zm0 4h14v2H5z" fill="currentColor" fill-rule="evenodd"></path></svg>',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'xxx',
				class: '',
				visible: true,
			},
			{
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M13 11V3.993A.997.997 0 0 0 12 3c-.556 0-1 .445-1 .993V11H3.993A.997.997 0 0 0 3 12c0 .557.445 1 .993 1H11v7.007c0 .548.448.993 1 .993.556 0 1-.445 1-.993V13h7.007A.997.997 0 0 0 21 12c0-.556-.445-1-.993-1H13z" fill="currentColor" fill-rule="evenodd"></path></svg>',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '/options',
				target_blank: false,
				tooltip: 'xxx',
				class: '',
				visible: true,
			},
			{
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'fa fa-envelope',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'Форум',
				class: '',
				visible: true,
			},
			{
				icon: {
					type: 'img', // icon, img, svg
					icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAyAAAAMgAEB7AknAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAKnSURBVHja7JhdSFNhHMZ/Ro42RafOdVZObeTHVMqhRankKEuURWAEhdBVwfCmoBUIFbvrot0YYV5IF4okREElq4hGhRPSVX5QWtlUFF1LmRO/0Iu68rCDH7mo9OI8d+d9X97zO8//ff4vnIi8S49/som1hU0uGVAGlAFlQBlQBpQBN1Zb15rURiuoOpaGpcAgjs3OL+J6P8yLzlHc3gCFhjhqqooY8k1x0vEabbQCp71UfP5ngNpoBQ0XD6FRKyXjqm2RWAoMCPEq3N6OjXPwyoksNGolvYMT3LjfxafvMwAUGuI4mrsDIV4FgNsbIN/25P8D7tLFAlDn7BXhloDc3oDEaae9FE+fj0ZXPzVVRQCkCDF4HMcBJOW2laVTkpcsVsbT5+N6cxf+6YXwQjIwFgQgJ1n919xwVOZy+kiG5NjkZwrcsR4M30FX9yhmk57zlhyKcnR8Gw2KwVhNbm+AcvvzFUNyZn8SZpN+2ZFxVOZiNumxlaXjePpl/YDOHj/B2las5UaMqQkYUxPENL/6MIytqTMs9/LSNAASOABbUydvjAK7d8aG32bc3gDu222iAxlJsRzI0mE26alTRmKtX3+Kl850w+XDK84nqlXhA4bqXvsItI/Aw488u1pCfqaANlqxsY36d9JERTI+s7iutQNjQVKEGM7edElK/MdX3QNbMfaKbLK2Ry1LokatZHxybsUX+acXmJ1fJFGtlDj87us4ANWn9lJoiJO0KXtFNnXn9oXnYKJaiaXAILnmQnXrUfeqX/1jco4UIQanvVTSB4v36MjPFMReGSpPny88B6vvvqWlzcvs/OKyjS7UtuLs8a8KeK3RQ+/gRAjwLADW+g6aX35myDclWd/S5qXR1b/iXhHyrw8ZUAaUAWVAGVAGlAHX0q8BAFFC80NTjcTYAAAAAElFTkSuQmCC',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '/options',
				target_blank: false,
				tooltip: 'Форум',
				class: '',
				visible: true,
			},
		];
		
		this.appModel.enableLeftMenu = true;
		this.appModel.titleLeftMenu.title = 'Управление сайтом';
		this.appModel.titleLeftMenu.link_type = 'a'; // router-link, a, div
		this.appModel.titleLeftMenu.onclick = () => {};
		this.appModel.titleLeftMenu.href = '#';
		this.appModel.titleLeftMenu.img = '/assets/img/typography/php.png';
		this.appModel.titleLeftMenu.tooltip = '';
		this.appModel.titleLeftMenu.supicon.type = 'svg';
		this.appModel.titleLeftMenu.supicon.icon = '<svg height="100%" version="1.1" viewBox="0 0 8 8" width="100%" xmlns="http://www.w3.org/2000/svg"><circle class="cKJIje" cx="4" cy="4" r="4"></circle><path class="ctvqXy" d="M4.13074827,1.21766493 L4.10368158,1.21766493 C3.36340745,1.21766493 2.76388015,1.80793503 2.76388015,2.5367787 L2.76388015,3.21632216 L3.44054754,3.21632216 L3.44054754,2.54344089 C3.44054754,2.17901906 3.74031119,1.88388401 4.11044825,1.88388401 L4.1239816,1.88388401 C4.49411866,1.88388401 4.79388232,2.17901906 4.79388232,2.54344089 L4.79388232,3.21632216 L5.47054971,3.21632216 L5.47054971,2.5367787 C5.47054971,1.80793503 4.8710224,1.21766493 4.13074827,1.21766493 M2.76388015,3.21632216 L3.44054754,3.21632216 L3.44054754,3.88254123 L2.76388015,3.88254123 L2.76388015,3.21632216 Z M4.79388232,3.21632216 L5.47054971,3.21632216 L5.47054971,3.88254123 L4.79388232,3.88254123 L4.79388232,3.21632216 Z M4.79401765,3.88254123 L3.44068287,3.88254123 L2.76401548,3.88254123 C2.39049508,3.88254123 2.08734809,4.18100738 2.08734809,4.54876031 L2.08734809,5.54808892 C2.08734809,6.10000287 2.53735205,6.54741753 3.09094491,6.54741753 L5.14375561,6.54741753 C5.69802683,6.54741753 6.14735243,6.10385072 6.14735243,5.54808892 L6.14735243,4.54876031 C6.14735243,4.18100738 5.84420544,3.88254123 5.47068504,3.88254123 L4.79401765,3.88254123 Z"></path></svg>';
		this.appModel.leftMenu = [
			{
				form: 'title',
				title: 'Ресурсы',
				visible: true,
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M10.208 7.308a1.09 1.09 0 0 1 0 1.483l-3.515 3.71 3.515 3.708a1.09 1.09 0 0 1 0 1.483.957.957 0 0 1-1.405 0l-3.866-4.08a1.635 1.635 0 0 1 0-2.225l3.866-4.08a.957.957 0 0 1 1.405 0zm3.584 0a.957.957 0 0 1 1.405 0l3.866 4.08c.583.614.583 1.61 0 2.225l-3.866 4.08a.957.957 0 0 1-1.405 0 1.09 1.09 0 0 1 0-1.484l3.515-3.708-3.515-3.71a1.09 1.09 0 0 1 0-1.483z" fill="currentColor" fill-rule="evenodd"></path></svg>',
					class: '',
				},
				icon2: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><g fill="currentColor"><path d="M16 11V9h-2V7.002A2.001 2.001 0 0 0 12 5c-1.102 0-2 .898-2 2.002V9H8v2H7v8h10v-8h-1zm-2 0h-4V9h4v2zM8 9V7.002A4.004 4.004 0 0 1 12 3a4 4 0 0 1 4 4.002V9h.994A2.01 2.01 0 0 1 19 11.009v7.982c0 1.11-.897 2.009-2.006 2.009H7.006A2.009 2.009 0 0 1 5 18.991V11.01C5 9.899 5.897 9 7.006 9H8zm0 0h2v2H8V9zm6 0h2v2h-2V9z"></path><circle cx="12" cy="15" r="2"></circle></g></svg>',
					class: '',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '#',
				title: 'Код',
				tooltip: '',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
				is_anchor: false,
				anchor_num: '',
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M16 12c0-1.9-1.3-3.4-3-3.9V4c0-.6-.4-1-1-1s-1 .4-1 1v4.1c-1.7.4-3 2-3 3.9s1.3 3.4 3 3.9V20c0 .6.4 1 1 1s1-.4 1-1v-4.1c1.7-.5 3-2 3-3.9zm-4 2c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" fill="currentColor" fill-rule="evenodd"></path></svg>',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '#',
				title: 'Изменения',
				tooltip: '',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
				is_anchor: false,
				anchor_num: '',
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M19 11c0 1.3-.8 2.4-2 2.8V15c0 2.2-1.8 4-4 4h-2.2c-.4 1.2-1.5 2-2.8 2-1.7 0-3-1.3-3-3 0-1.3.8-2.4 2-2.8V8.8C5.9 8.4 5 7.3 5 6c0-1.7 1.3-3 3-3s3 1.3 3 3c0 1.3-.8 2.4-2.1 2.8v6.4c.9.3 1.6.9 1.9 1.8H13c1.1 0 2-.9 2-2v-1.2c-1.2-.4-2-1.5-2-2.8 0-1.7 1.3-3 3-3s3 1.3 3 3zM8 5c-.5 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1zm8 7c.6 0 1-.4 1-1s-.4-1-1-1-1 .4-1 1 .4 1 1 1zm-8 7c.6 0 1-.4 1-1s-.4-1-1-1-1 .4-1 1 .4 1 1 1z" fill="currentColor" fill-rule="evenodd"></path></svg>',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '#',
				title: 'Ветки',
				tooltip: '',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
				is_anchor: false,
				anchor_num: '',
			},
			{
				form: 'title',
				title: 'Прочее',
				visible: true,
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M18 15.2V11c0-1.7-1.3-3-3-3h-1.9l.8-.8c.3-.3.3-.8 0-1.1-.3-.3-.8-.3-1.1 0l-2.3 2.3c-.3.4-.3.9 0 1.2l2.3 2.3c.2.2.3.2.5.2s.4-.1.6-.3c.3-.3.3-.8 0-1.1l-.7-.7H15c.6 0 1 .4 1 1v4.2c-1.2.4-2 1.5-2 2.8 0 1.7 1.3 3 3 3s3-1.3 3-3c0-1.3-.8-2.4-2-2.8zM17 19c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zM7 3C5.4 3 4 4.3 4 6c0 1.3.8 2.4 2 2.8V20c0 .6.4 1 1 1s1-.4 1-1V8.8c1.2-.4 2-1.5 2-2.8 0-1.7-1.3-3-3-3zm0 4c-.5 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" fill="currentColor" fill-rule="evenodd"></path></svg>',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '#',
				title: 'Pull-запросы',
				tooltip: '',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
				is_anchor: false,
				anchor_num: '',
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><g fill="currentColor" fill-rule="evenodd"><path d="M17 10.005V19H7V5h5.99v2.49a1.5 1.5 0 0 0 1.5 1.5h4.5v-.522c0-.297-.132-.578-.359-.768l-5.074-4.236c-.36-.3-.813-.464-1.282-.464H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-8.995h-2z"></path><rect x="8" y="12" width="8" height="2" rx="1"></rect><rect x="8" y="15" width="4" height="2" rx="1"></rect></g></svg>',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '#',
				title: 'Загрузки',
				tooltip: '',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
				is_anchor: false,
				anchor_num: '',
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'img', // icon, img, svg
					icon: '/assets/img/typography/74331a31-ecef-45a9-842f-35baef2f79b5.svg',
					class: 'css-1mc7p6t-IconImg e6ct6941',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '#',
				title: 'Graphs',
				tooltip: '',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
				is_anchor: false,
				anchor_num: '',
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'fa fa-signal fa-xs',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '#',
				title: 'Graph',
				tooltip: '',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
				is_anchor: false,
				anchor_num: '',
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M11.701 16.7a5.002 5.002 0 1 1 0-10.003 5.002 5.002 0 0 1 0 10.004m8.368-3.117a1.995 1.995 0 0 1-1.346-1.885c0-.876.563-1.613 1.345-1.885a.48.48 0 0 0 .315-.574 8.947 8.947 0 0 0-.836-1.993.477.477 0 0 0-.598-.195 2.04 2.04 0 0 1-1.29.08 1.988 1.988 0 0 1-1.404-1.395 2.04 2.04 0 0 1 .076-1.297.478.478 0 0 0-.196-.597 8.98 8.98 0 0 0-1.975-.826.479.479 0 0 0-.574.314 1.995 1.995 0 0 1-1.885 1.346 1.994 1.994 0 0 1-1.884-1.345.482.482 0 0 0-.575-.315c-.708.2-1.379.485-2.004.842a.47.47 0 0 0-.198.582A2.002 2.002 0 0 1 4.445 7.06a.478.478 0 0 0-.595.196 8.946 8.946 0 0 0-.833 1.994.48.48 0 0 0 .308.572 1.995 1.995 0 0 1 1.323 1.877c0 .867-.552 1.599-1.324 1.877a.479.479 0 0 0-.308.57 8.99 8.99 0 0 0 .723 1.79.477.477 0 0 0 .624.194c.595-.273 1.343-.264 2.104.238.117.077.225.185.302.3.527.8.512 1.58.198 2.188a.473.473 0 0 0 .168.628 8.946 8.946 0 0 0 2.11.897.474.474 0 0 0 .57-.313 1.995 1.995 0 0 1 1.886-1.353c.878 0 1.618.567 1.887 1.353a.475.475 0 0 0 .57.313 8.964 8.964 0 0 0 2.084-.883.473.473 0 0 0 .167-.631c-.318-.608-.337-1.393.191-2.195.077-.116.185-.225.302-.302.772-.511 1.527-.513 2.125-.23a.477.477 0 0 0 .628-.19 8.925 8.925 0 0 0 .728-1.793.478.478 0 0 0-.314-.573" fill="currentColor" fill-rule="evenodd"></path></svg>',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '#',
				title: 'Настройки',
				tooltip: '',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: true,
				is_anchor: false,
				anchor_num: '',
			},
			{
				form: 'item', // item, title
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'fa fa-dollar-sign fa-xs ms-2',
					class: '',
				},
				icon2: {
					type: '', // icon, img, svg
					icon: '',
					class: '',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '#',
				title: 'Расширенное',
				tooltip: 'Расширенные возможности',
				badge: {
					type: '', // primary, secondary, success, danger, warning, info, light, dark
					value: '',
					class: '',
				},
				target_blank: false,
				visible: true,
				selected: false,
				is_anchor: false,
				anchor_num: '',
			},
		];
		this.appModel.footerLeftMenu.enable = true;
		
		this.appModel.enableSidebar = true;
		this.appModel.sidebarMenu = [
			{
				icon: {
					type: 'img', // icon, img, svg
					icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAyAAAAMgAEB7AknAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAKnSURBVHja7JhdSFNhHMZ/Ro42RafOdVZObeTHVMqhRankKEuURWAEhdBVwfCmoBUIFbvrot0YYV5IF4okREElq4hGhRPSVX5QWtlUFF1LmRO/0Iu68rCDH7mo9OI8d+d9X97zO8//ff4vnIi8S49/som1hU0uGVAGlAFlQBlQBpQBN1Zb15rURiuoOpaGpcAgjs3OL+J6P8yLzlHc3gCFhjhqqooY8k1x0vEabbQCp71UfP5ngNpoBQ0XD6FRKyXjqm2RWAoMCPEq3N6OjXPwyoksNGolvYMT3LjfxafvMwAUGuI4mrsDIV4FgNsbIN/25P8D7tLFAlDn7BXhloDc3oDEaae9FE+fj0ZXPzVVRQCkCDF4HMcBJOW2laVTkpcsVsbT5+N6cxf+6YXwQjIwFgQgJ1n919xwVOZy+kiG5NjkZwrcsR4M30FX9yhmk57zlhyKcnR8Gw2KwVhNbm+AcvvzFUNyZn8SZpN+2ZFxVOZiNumxlaXjePpl/YDOHj/B2las5UaMqQkYUxPENL/6MIytqTMs9/LSNAASOABbUydvjAK7d8aG32bc3gDu222iAxlJsRzI0mE26alTRmKtX3+Kl850w+XDK84nqlXhA4bqXvsItI/Aw488u1pCfqaANlqxsY36d9JERTI+s7iutQNjQVKEGM7edElK/MdX3QNbMfaKbLK2Ry1LokatZHxybsUX+acXmJ1fJFGtlDj87us4ANWn9lJoiJO0KXtFNnXn9oXnYKJaiaXAILnmQnXrUfeqX/1jco4UIQanvVTSB4v36MjPFMReGSpPny88B6vvvqWlzcvs/OKyjS7UtuLs8a8KeK3RQ+/gRAjwLADW+g6aX35myDclWd/S5qXR1b/iXhHyrw8ZUAaUAWVAGVAGlAHX0q8BAFFC80NTjcTYAAAAAElFTkSuQmCC',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'Форум',
				class: '',
				visible: true,
				selected: false,
			},
			{
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'fa fa-envelope',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'Форум',
				class: '',
				visible: true,
				selected: false,
			},
			{
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><g fill-rule="evenodd"><path d="M2 12c0 5.523 4.477 10 10 10s10-4.477 10-10S17.523 2 12 2 2 6.477 2 12z" fill="currentColor"></path><rect fill="inherit" x="11" y="10" width="2" height="7" rx="1"></rect><circle fill="inherit" cx="12" cy="8" r="1"></circle></g></svg>',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'Форум',
				class: '',
				visible: true,
				selected: false,
			},
			{
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><g fill="currentColor" fill-rule="evenodd"><path d="M14 17h4V7h-4v10zM12 6.007C12 5.45 12.453 5 12.997 5h6.006c.55 0 .997.45.997 1.007v11.986c0 .556-.453 1.007-.997 1.007h-6.006c-.55 0-.997-.45-.997-1.007V6.007z" fill-rule="nonzero"></path><rect x="4" y="5" width="6" height="2" rx="1"></rect><rect x="4" y="9" width="6" height="2" rx="1"></rect><rect x="4" y="13" width="6" height="2" rx="1"></rect><rect x="4" y="17" width="6" height="2" rx="1"></rect></g></svg>',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'Help',
				class: '',
				visible: true,
				selected: false,
			},
			{
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><g fill="currentColor" fill-rule="evenodd"><path d="M8 6.003v2.995a1 1 0 1 0 2 0V5.102C10 4.494 9.507 4 8.9 4H5a1 1 0 0 0 0 2.003h3z" fill-rule="nonzero"></path><path d="M9.428 18.018C7.351 16.989 6 14.807 6 12.37c0-2.266 1.167-4.319 3.02-5.425.48-.286.646-.922.371-1.421a.979.979 0 0 0-1.364-.386C5.557 6.611 4 9.35 4 12.37c0 3.248 1.802 6.158 4.57 7.529.498.247 1.095.026 1.332-.493.237-.52.025-1.141-.474-1.388z" fill-rule="nonzero"></path><path d="M14 15.002v3.896c0 .608.493 1.102 1.1 1.102H19a1 1 0 0 0 0-2.003h-3v-2.995a1 1 0 1 0-2 0z"></path><path d="M14.097 4.596c-.237.52-.025 1.14.474 1.387 2.077 1.029 3.428 3.21 3.428 5.647 0 2.266-1.167 4.32-3.021 5.425a1.063 1.063 0 0 0-.37 1.42c.274.5.885.673 1.364.387 2.47-1.473 4.027-4.212 4.027-7.232 0-3.248-1.802-6.158-4.57-7.528A.957.957 0 0 0 15.002 4a1 1 0 0 0-.905.596z"></path></g></svg>',
				},
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'Help',
				class: '',
				visible: true,
				selected: false,
			},
		];
		
		this.appModel.enableBreadcrumbs = true;
		this.appModel.breadcrumbs = [
			{
				title: 'CMS',
				tooltip: '',
				link_type: 'a', // router-link, a, span
				onclick: () => {},
				href: '#',
				icon: '',
			},
			{
				title: 'Разделы',
				tooltip: 'Разделы',
				link_type: 'a', // router-link, a, span
				onclick: () => {},
				href: '#',
				icon: '',
			},
			{
				title: 'Typography',
				tooltip: '',
				link_type: 'a', // router-link, a, span
				onclick: () => {},
				href: '#',
				icon: '',
			},
		];
		
		this.appModel.titleCabinet.icon.type = 'icon';
		this.appModel.titleCabinet.icon.icon = 'fa fa-tachometer-alt';
		this.appModel.titleCabinet.title = 'Typography';
		this.appModel.titleCabinet.buttons = [
			{
				type: 'btn', // btn, group-btn
				title: 'К сайту',
				tooltip: 'Перейти на главную сайта',
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '/',
				target_blank: false,
				visible: true,
				selected: false,
				btnclass: 'btn-light',
				class: '',
				subitems: [],
			},
			{
				type: 'group-btn', // btn, group-btn
				buttons: [
					{
						type: 'btn', // btn, group-btn
						title: 'Добавить',
						tooltip: 'Добавить новую задачу',
						link_type: 'a', // router-link, a, div
						onclick: () => {},
						href: '/',
						target_blank: false,
						visible: true,
						selected: false,
						btnclass: 'btn-light',
						class: '',
						subitems: [],
					},
				],
			},
			{
				type: 'btn', // btn, group-btn
				title: '',
				tooltip: '',
				link_type: 'a', // router-link, a, div
				onclick: () => {},
				href: '',
				target_blank: false,
				visible: true,
				selected: false,
				btnclass: 'btn-light',
				class: '',
				subitems: [
					{
						type: 'item',
						title: 'Скопировать сайт',
						tooltip: 'Создать копию сайта',
						link_type: 'a', // router-link, a, div
						onclick: () => {},
						href: '/options/copy',
						target_blank: false,
						visible: true,
						selected: false,
						class: '',
					},
					{
						type: 'item',
						title: 'Удалить сайт',
						tooltip: '',
						link_type: 'a', // router-link, a, div
						onclick: () => {},
						href: '/options/delete',
						target_blank: false,
						visible: true,
						selected: false,
						class: '',
					},
				],
			},
		];
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {},
}
</script>
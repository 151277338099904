<template>
	<div class="bb-sidebar">
		<div class="sidebar-container">
			<section class="sidebar">
				<div tabindex="-1" class="css-1loow23">
					<div class="css-549t14">
						<div class="css-1s1y6m7">
							<div class="css-1z0at8b" v-for="item in visibleItems(appModel.sidebarMenu)">
								<a :href="item.href" :target="item.target_blank ? '_blank' : '_self'" rel="nofollow noopener noreferrer" v-if=" item.link_type == 'a'">
									<button type="button" class="css-1akzpg4" :class="[item.class, {'active': item.selected}]" :content="item.tooltip" v-tippy="{placement: 'left'}">
										<span class="css-j8fq0c">
											<span v-if="item.icon && item.icon.icon != ''">
												<i :class="[item.icon.icon]" v-if="item.icon.type == 'icon'"></i>
												<span class="css-noix33" v-if="item.icon.type == 'svg'">
													<div>
														<span class="hWgvbO" v-html="item.icon.icon"></span>
													</div>
												</span>
												<img :src="item.icon.icon" style="width: 100%; height: 100%;" v-if="item.icon.type == 'img'" />
											</span>
											<i class="fa fa-check-square m-l-mini m-r-mini" v-else></i>
										</span>
									</button>
								</a>
								<router-link :to="item.href" v-if=" item.link_type == 'router-link'">
									<button type="button" class="css-1akzpg4" :class="[item.class, {'active': item.selected}]" :content="item.tooltip" v-tippy="{placement: 'left'}">
										<span class="css-j8fq0c">
											<span v-if="item.icon && item.icon.icon != ''">
												<i :class="[item.icon.icon]" v-if="item.icon.type == 'icon'"></i>
												<span class="css-noix33" v-if="item.icon.type == 'svg'">
													<div>
														<span class="hWgvbO" v-html="item.icon.icon"></span>
													</div>
												</span>
												<img :src="item.icon.icon" style="width: 100%; height: 100%;" v-if="item.icon.type == 'img'" />
											</span>
											<i class="fa fa-check-square m-l-mini m-r-mini" v-else></i>
										</span>
									</button>
								</router-link>
								<div @click="item.onclick" v-if=" item.link_type == 'div'">
									<button type="button" class="css-1akzpg4" :class="[item.class, {'active': item.selected}]" :content="item.tooltip" v-tippy="{placement: 'left'}">
										<span class="css-j8fq0c">
											<span v-if="item.icon && item.icon.icon != ''">
												<i :class="[item.icon.icon]" v-if="item.icon.type == 'icon'"></i>
												<span class="css-noix33" v-if="item.icon.type == 'svg'">
													<div>
														<span class="hWgvbO" v-html="item.icon.icon"></span>
													</div>
												</span>
												<img :src="item.icon.icon" style="width: 100%; height: 100%;" v-if="item.icon.type == 'img'" />
											</span>
											<i class="fa fa-check-square m-l-mini m-r-mini" v-else></i>
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
	computed: {},
	methods: {
		visibleItems(arr){
			return arr.length > 0 ? arr.filter(i => i.visible) : [];
		},
	},
}
</script>
import { createRouter, createWebHistory } from 'vue-router';

import lib from '@/lib';

import Layout from '@/layouts/Layout.vue';
//import LayoutMain from '@/layouts/LayoutMain.vue';
//import LayoutProject from '@/layouts/LayoutProject.vue';
//import LayoutSettings from '@/layouts/LayoutSettings.vue';
import LayoutEmpty from '@/layouts/LayoutEmpty.vue';

import Main from '@/pages/Main.vue';
import Login from '@/pages/Login.vue';
import Virtualspaces from '@/pages/Virtualspaces.vue';
import Projects from '@/pages/Projects.vue';
import Tasks from '@/pages/Tasks.vue';
//import Project from '@/pages/Project.vue';
//import ProjectTasks from '@/pages/ProjectTasks.vue';
import Settings from '@/pages/Settings.vue';
import SettingsTasksTypes from '@/pages/settings/TasksTypes.vue';
import SettingsDocumentTemplates from '@/pages/settings/DocumentTemplates.vue';
import SettingsTasksCustomFields from '@/pages/settings/TasksCustomFields.vue';
import SettingsTasksPriorities from '@/pages/settings/TasksPriorities.vue';
import SettingsTasksStatuses from '@/pages/settings/TasksStatuses.vue';
import SettingsTasksStatusesTypes from '@/pages/settings/TasksStatusesTypes.vue';
import Targets from '@/pages/Targets.vue';
import Tags from '@/pages/Tags.vue';

import Typography from '@/pages/Typography.vue';
import Test from '@/pages/Test.vue';

import PageNotFound from '@/pages/PageNotFound.vue';

const routes = [
	/*{
		path: '/',
		redirect: '/projects',
	},*/
	{
		path: '/vs/:virtualspace_id',
		redirect: (to) => {
			return {path: '/vs/' + to.params.virtualspace_id + '/tasks'};
		},
	},
	{
		path: '/',
		component: Layout,
		children: [
			{
				path: '',
				component: Main,
			},
			{
				path: '/vs',
				component: Virtualspaces,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/vs/:virtualspace_id/settings',
				component: Settings,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/vs/:virtualspace_id/settings/tasks-types',
				component: SettingsTasksTypes,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/vs/:virtualspace_id/settings/document-templates',
				component: SettingsDocumentTemplates,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/vs/:virtualspace_id/settings/tasks-custom-fields',
				component: SettingsTasksCustomFields,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/vs/:virtualspace_id/settings/tasks-priorities',
				component: SettingsTasksPriorities,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/vs/:virtualspace_id/settings/tasks-statuses',
				component: SettingsTasksStatuses,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/vs/:virtualspace_id/settings/tasks-statuses-types',
				component: SettingsTasksStatusesTypes,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/vs/:virtualspace_id/tags',
				component: Tags,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/vs/:virtualspace_id/targets',
				component: Targets,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/vs/:virtualspace_id/projects',
				component: Projects,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/vs/:virtualspace_id/tasks',
				component: Tasks,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/login',
				component: Login,
				meta: {
					guest: true,
				},
			},
			
			{
				path: '/typography',
				component: Typography,
			},
		],
	},
	/*{
		path: '/',
		component: LayoutProject,
		children: [
			{
				path: '/projects/:project_id',
				component: Project,
			},
			{
				path: '/projects/:project_id/tasks',
				component: ProjectTasks,
			},
		],
	},*/
	/*{
		path: '/settings',
		component: LayoutSettings,
		children: [
			{
				path: '/settings',
				component: Settings,
			},
			{
				path: '/settings/taskTypes',
				component: SettingsTaskTypes,
			},
			{
				path: '/settings/documentTemplates',
				component: SettingsDocumentTemplates,
			},
			{
				path: '/settings/customFields',
				component: SettingsCustomFields,
			},
			{
				path: '/settings/priorities',
				component: SettingsPriorities,
			},
			{
				path: '/settings/statuses',
				component: SettingsStatuses,
			},
			{
				path: '/settings/tags',
				component: SettingsTags,
			},
		],
	},*/
	{
		path: '/test',
		component: Test,
	},
	{
		path: '/logout',
		component: LayoutEmpty,
		beforeEnter(to, from, next){
			storeInstance.state.app.auth.doLogout().then(() => {
				next({path: '/'});
			});
		},
	},
	{
		path: '/:pathMatch(.*)*',
		component: Layout,
		children: [
			{
				path: '',
				component: PageNotFound,
			},
		],
	},
	{
		path: '/:pathMatch(.*)',
		component: Layout,
		children: [
			{
				path: '',
				component: PageNotFound,
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	storeInstance.state.app.auth.doCheckAuth().then(() => {
		if(to.matched.some(record => record.meta.requiresAuth)){
			if(!storeInstance.state.app.auth.isAuth()){
				next({
					path: '/login',
					params: {
						nextUrl: to.fullPath,
					},
				})
			} else {
				if(to.matched.some(record => record.meta.is_admin)){
					if(storeInstance.state.app.auth.isAdmin()){
						next()
					} else {
						next('/')
					}
				} else {
					next()
				}
			}
		} else if(to.matched.some(record => record.meta.guest)){
			if(storeInstance.state.app.auth.isAuth()){
				next('/')
			} else {
				next()
			}
		} else {
			next() 
		}
	});
});
/*// Не позволяет авторизованному пользователю перейти на страницу логина и перекидывает его на главную страницу
router.beforeEach((to, from, next) => {
	if (to.path == '/login' && storeInstance.state.app.auth.isAuth()) next({path: '/'})
  	else next()
})*/

export default router;
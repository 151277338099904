<template>
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-body">
					<div>Главная страница</div>
					<router-link :to="{path: '/vs'}" class="btn btn-primary mt-3">GO TO SELECT VIRTUAL SPACE</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Tasker'});
	},
	data: () => ({
		
	}),
	computed: {},
	methods: {},
	mounted(){
		window.scrollTo(0, 0);
		
		this.appModel.enableMainLeftMenu = true;
		this.appModel.enableLeftMenu = false;
		this.appModel.enableSidebar = false;
		this.appModel.enableBreadcrumbs = false;
		
		this.appModel.titleCabinet.icon.type = '';
		this.appModel.titleCabinet.icon.icon = '';
		this.appModel.titleCabinet.title = '';
		this.appModel.titleCabinet.buttons = [];
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {},
}
</script>
<template>
	<div class="card bg-light border-0 mb-2">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb mx-3 my-2" v-if="appModel.breadcrumbs.length > 0">
				<li v-for="(breadcrumb, index) in appModel.breadcrumbs" class="breadcrumb-item" :class="{'active': index == appModel.breadcrumbs.length - 1}">
					<span v-if="index != appModel.breadcrumbs.length - 1">
						<a :href="breadcrumb.href" :target="breadcrumb.target_blank ? '_blank' : '_self'" rel="nofollow noopener noreferrer" :content="breadcrumb.tooltip" v-tippy="{placement: 'bottom'}" v-if="breadcrumb.link_type == 'a'">
							<i :class="[breadcrumb.icon]" v-if="breadcrumb.icon != ''"></i>
							{{breadcrumb.title}}
						</a>
						<router-link :to="breadcrumb.href" :content="breadcrumb.tooltip" v-tippy="{placement: 'bottom'}" v-if="breadcrumb.link_type == 'router-link'">
							<i :class="[breadcrumb.icon]" v-if="breadcrumb.icon != ''"></i>
							{{breadcrumb.title}}
						</router-link>
						<span @click="breadcrumb.onclick" :content="breadcrumb.tooltip" v-tippy="{placement: 'bottom'}" v-if="breadcrumb.link_type == 'span'">
							<i :class="[breadcrumb.icon]" v-if="breadcrumb.icon != ''"></i>
							{{breadcrumb.title}}
						</span>
					</span>
					<span v-else :content="breadcrumb.tooltip" v-tippy="{placement: 'bottom'}">
						<i :class="[breadcrumb.icon]" v-if="breadcrumb.icon != ''"></i>
						{{breadcrumb.title}}
					</span>
				</li>
			</ol>
		</nav>
	</div>
</template>

<style lang="css" scoped>
.breadcrumb-item a {
	text-decoration: none;
}
.breadcrumb-item a:hover {
	text-decoration: underline;
}
</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		
	}),
}
</script>
<template>
	<div>
		<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
			<h1 class="h2">Авторизация</h1>
			<div class="btn-toolbar mb-2 mb-md-0">
				<!--
				<div class="btn-group me-2">
					<button type="button" class="btn btn-sm btn-outline-secondary">Share</button>
					<button type="button" class="btn btn-sm btn-outline-secondary">Export</button>
				</div>
				<button type="button" class="btn btn-sm btn-outline-secondary dropdown-toggle">
					<span data-feather="calendar"></span>
					This week
				</button>
				-->
			</div>
		</div>
		<div class="row">
			<div class="col-md-6 offset-md-3">
				<div class="list-group">
					<a href="javascript://" @click="onAuthBylex()" class="list-group-item list-group-item-action">Войти</a>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import { Bylex } from '@/assets/plugins/socials';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Авторизация | Tasker'});
	},
	data: () => ({
		projects: [],
		overlay: true,
	}),
	computed: {},
	methods: {
		async onAuthBylex(){
			await this.$Oauth.authenticate('bylex', Bylex).then(async (response) => {
				this.authModel.authChecked = false;
				await this.authModel.doCheckAuth().then(() => {
					this.$router.push('/');
				});
			}).catch((err) => {
				console.log(err);
			});
		},
	},
	mounted(){
		window.scrollTo(0, 0);
		
		this.appModel.enableMainLeftMenu = true;
		this.appModel.enableLeftMenu = false;
		this.appModel.enableSidebar = false;
		this.appModel.enableBreadcrumbs = false;
		
		this.appModel.titleCabinet.icon.type = '';
		this.appModel.titleCabinet.icon.icon = '';
		this.appModel.titleCabinet.title = '';
		this.appModel.titleCabinet.buttons = [];
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	created(){
		this.$http.interceptors.response.use(undefined, function(err){
			return new Promise(function(resolve, reject){
				if(err.status === 401 && err.config && !err.config.__isRetryRequest){
					this.authModel.doLogout();
				}
				throw err;
			});
		});
	},
	components: {},
}
</script>
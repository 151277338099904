<template>
	<div class="page-wrapper">
		<div class="page-navigation-content">
			<main-left-menu v-if="appModel.enableMainLeftMenu"></main-left-menu>
			<div class="content">
				<div class="css-1gbe3q2">
					<div class="css-8ypwyz">
						<div class="css-i3pbo">
							<header class="page-header-wrapper" v-if="appModel.enableBreadcrumbs || (appModel.titleCabinet.title != '')">
								<div class="hHAKil">
									<breadcrumbs v-if="appModel.enableBreadcrumbs"></breadcrumbs>
									<div class="row" v-if="appModel.titleCabinet.title != ''">
										<div class="col-md-6">
											<div class="kNyrtr">
												<i class="me-2" :class="[appModel.titleCabinet.icon.icon]" v-if="appModel.titleCabinet.icon.icon != '' && appModel.titleCabinet.icon.type == 'icon'"></i>
												<span style="height: 35px; width: 35px; fill: #fff; float: left; line-height: 2.5;" class="me-2" v-html="appModel.titleCabinet.icon.icon" v-if="appModel.titleCabinet.icon.icon != '' && appModel.titleCabinet.icon.type == 'svg'"></span>
												<!--
												<svg width="35" height="35" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M18 15.2V11c0-1.7-1.3-3-3-3h-1.9l.8-.8c.3-.3.3-.8 0-1.1-.3-.3-.8-.3-1.1 0l-2.3 2.3c-.3.4-.3.9 0 1.2l2.3 2.3c.2.2.3.2.5.2s.4-.1.6-.3c.3-.3.3-.8 0-1.1l-.7-.7H15c.6 0 1 .4 1 1v4.2c-1.2.4-2 1.5-2 2.8 0 1.7 1.3 3 3 3s3-1.3 3-3c0-1.3-.8-2.4-2-2.8zM17 19c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zM7 3C5.4 3 4 4.3 4 6c0 1.3.8 2.4 2 2.8V20c0 .6.4 1 1 1s1-.4 1-1V8.8c1.2-.4 2-1.5 2-2.8 0-1.7-1.3-3-3-3zm0 4c-.5 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" fill="currentColor" fill-rule="evenodd"></path></svg>
												-->
												<img :src="appModel.titleCabinet.icon.icon" class="me-2" style="width: 35px; height: 35px;" v-if="appModel.titleCabinet.icon.icon != '' && appModel.titleCabinet.icon.type == 'img'" />
												{{appModel.titleCabinet.title}}
											</div>
										</div>
										<div class="col-md-6 text-end">
											<span v-for="btnitem in appModel.titleCabinet.buttons">
												<span class="btn-group ms-1" v-if="btnitem.type == 'group-btn' && btnitem.buttons.length > 0">
													<span v-for="btn2item in visibleItems(btnitem.buttons)">
														<span class="btn-group" v-if="btn2item.subitems.length > 0">
															<a :href="btn2item.href" class="btn" :class="[btn2item.btnclass||'btn-light', btn2item.class, {'active': btn2item.selected}]" :target="btn2item.target_blank ? '_blank' : '_self'" rel="nofollow noopener noreferrer" :content="btn2item.tooltip" v-tippy="{placement: 'bottom'}" v-html="btn2item.title" v-if="btn2item.href != '' && btn2item.link_type == 'a'"></a>
															<router-link :to="btn2item.href" class="btn" :class="[btn2item.btnclass||'btn-light', btn2item.class, {'active': btn2item.selected}]" :content="btn2item.tooltip" v-tippy="{placement: 'bottom'}" v-html="btn2item.title" v-if="btn2item.href != '' && btn2item.link_type == 'router-link'"></router-link>
															<div @click="btn2item.onclick" class="btn" :class="[btn2item.btnclass||'btn-light', btn2item.class, {'active': btn2item.selected}]" :content="btn2item.tooltip" v-tippy="{placement: 'bottom'}" v-html="btn2item.title" v-if="btn2item.href != '' && btn2item.link_type == 'div'"></div>
															<a href="javascript://" class="btn dropdown-toggle" :class="[btn2item.btnclass||'btn-light']" data-bs-toggle="dropdown" v-if="btn2item.href != ''"></a>
															<a href="javascript://" class="btn" :class="[btn2item.btnclass||'btn-light']" data-bs-toggle="dropdown" v-if="btn2item.href == ''">
																<span v-html="btn2item.title" v-if="btn2item.title"></span>
																<i class="fa fa-ellipsis-h" v-else></i>
															</a>
															<ul class="dropdown-menu float-end">
																<li v-for="btn2subitem in visibleItems(btn2item.subitems)">
																	<h6 class="dropdown-header" v-html="btn2subitem.title" v-if="btn2subitem.type == 'header'"></h6>
																	<hr class="dropdown-divider" v-if="btn2subitem.type == 'divider'" />
																	<a :href="btn2subitem.href" class="dropdown-item" :class="[btn2subitem.class, {'active': btn2subitem.selected}]" :target="btn2subitem.target_blank ? '_blank' : '_self'" rel="nofollow noopener noreferrer" :content="btn2subitem.tooltip" v-tippy="{placement: 'bottom'}" v-html="btn2subitem.title" v-if="btn2subitem.type == 'item' && btn2subitem.link_type == 'a'"></a>
																	<router-link :to="btn2subitem.href" class="dropdown-item" :class="[btn2subitem.class, {'active': btn2subitem.selected}]" :content="btn2subitem.tooltip" v-tippy="{placement: 'bottom'}" v-html="btn2subitem.title" v-if="btn2subitem.type == 'item' && btn2subitem.link_type == 'router-link'"></router-link>
																	<div @click="btn2subitem.onclick" class="dropdown-item" :class="[btn2subitem.class, {'active': btn2subitem.selected}]" :content="btn2subitem.tooltip" v-tippy="{placement: 'bottom'}" v-html="btn2subitem.title" v-if="btn2subitem.type == 'item' && btn2subitem.link_type == 'div'"></div>
																</li>
															</ul>
														</span>
														<span v-else>
															<a :href="btn2item.href" class="btn" :class="[btn2item.btnclass||'btn-light', btn2item.class, {'active': btn2item.selected}]" :target="btn2item.target_blank ? '_blank' : '_self'" rel="nofollow noopener noreferrer" :content="btn2item.tooltip" v-tippy="{placement: 'bottom'}" v-html="btn2item.title" v-if="btn2item.link_type == 'a'"></a>
															<router-link :to="btn2item.href" class="btn" :class="[btn2item.btnclass||'btn-light', btn2item.class, {'active': btn2item.selected}]" :content="btn2item.tooltip" v-tippy="{placement: 'bottom'}" v-html="btn2item.title" v-if="btn2item.link_type == 'router-link'"></router-link>
															<div @click="btn2item.onclick" class="btn" :class="[btn2item.btnclass||'btn-light', btn2item.class, {'active': btn2item.selected}]" :content="btn2item.tooltip" v-tippy="{placement: 'bottom'}" v-html="btn2item.title" v-if="btn2item.link_type == 'div'"></div>
														</span>
													</span>
												</span>
												<span v-if="btnitem.type == 'btn' && btnitem.visible">
													<span class="btn-group ms-1" v-if="btnitem.subitems.length > 0">
														<a :href="btnitem.href" class="btn" :class="[btnitem.btnclass||'btn-light', btnitem.class, {'active': btnitem.selected}]" :target="btnitem.target_blank ? '_blank' : '_self'" rel="nofollow noopener noreferrer" :content="btnitem.tooltip" v-tippy="{placement: 'bottom'}" v-html="btnitem.title" v-if="btnitem.href != '' && btnitem.link_type == 'a'"></a>
														<router-link :to="btnitem.href" class="btn" :class="[btnitem.btnclass||'btn-light', btnitem.class, {'active': btnitem.selected}]" :content="btnitem.tooltip" v-tippy="{placement: 'bottom'}" v-html="btnitem.title" v-if="btnitem.href != '' && btnitem.link_type == 'router-link'"></router-link>
														<div @click="btnitem.onclick" class="btn" :class="[btnitem.btnclass||'btn-light', btnitem.class, {'active': btnitem.selected}]" :content="btnitem.tooltip" v-tippy="{placement: 'bottom'}" v-html="btnitem.title" v-if="btnitem.href != '' && btnitem.link_type == 'div'"></div>
														<a href="javascript://" class="btn dropdown-toggle" :class="[btnitem.btnclass||'btn-light']" data-bs-toggle="dropdown" v-if="btnitem.href != ''"></a>
														<a href="javascript://" class="btn" :class="[btnitem.btnclass||'btn-light']" data-bs-toggle="dropdown" v-if="btnitem.href == ''">
															<span v-html="btnitem.title" v-if="btnitem.title"></span>
															<i class="fa fa-ellipsis-h" v-else></i>
														</a>
														<ul class="dropdown-menu float-end">
															<li v-for="btnsubitem in visibleItems(btnitem.subitems)">
																<h6 class="dropdown-header" v-html="btnsubitem.title" v-if="btnsubitem.type == 'header'"></h6>
																<hr class="dropdown-divider" v-if="btnsubitem.type == 'divider'" />
																<a :href="btnsubitem.href" class="dropdown-item" :class="[btnsubitem.class, {'active': btnsubitem.selected}]" :target="btnsubitem.target_blank ? '_blank' : '_self'" rel="nofollow noopener noreferrer" :content="btnsubitem.tooltip" v-tippy="{placement: 'bottom'}" v-html="btnsubitem.title" v-if="btnsubitem.type == 'item' && btnsubitem.link_type == 'a'"></a>
																<router-link :to="btnsubitem.href" class="dropdown-item" :class="[btnsubitem.class, {'active': btnsubitem.selected}]" :content="btnsubitem.tooltip" v-tippy="{placement: 'bottom'}" v-html="btnsubitem.title" v-if="btnsubitem.type == 'item' && btnsubitem.link_type == 'router-link'"></router-link>
																<div @click="btnsubitem.onclick" class="dropdown-item" :class="[btnsubitem.class, {'active': btnsubitem.selected}]" :content="btnsubitem.tooltip" v-tippy="{placement: 'bottom'}" v-html="btnsubitem.title" v-if="btnsubitem.type == 'item' && btnsubitem.link_type == 'div'"></div>
															</li>
														</ul>
													</span>
													<span v-else>
														<a :href="btnitem.href" class="btn ms-1" :class="[btnitem.btnclass||'btn-light', btnitem.class, {'active': btnitem.selected}]" :target="btnitem.target_blank ? '_blank' : '_self'" rel="nofollow noopener noreferrer" :content="btnitem.tooltip" v-tippy="{placement: 'bottom'}" v-html="btnitem.title" v-if="btnitem.link_type == 'a'"></a>
														<router-link :to="btnitem.href" class="btn ms-1" :class="[btnitem.btnclass||'btn-light', btnitem.class, {'active': btnitem.selected}]" :content="btnitem.tooltip" v-tippy="{placement: 'bottom'}" v-html="btnitem.title" v-if="btnitem.link_type == 'router-link'"></router-link>
														<div @click="btnitem.onclick" class="btn ms-1" :class="[btnitem.btnclass||'btn-light', btnitem.class, {'active': btnitem.selected}]" :content="btnitem.tooltip" v-tippy="{placement: 'bottom'}" v-html="btnitem.title" v-if="btnitem.link_type == 'div'"></div>
													</span>
												</span>
											</span>
										</div>
									</div>
								</div>
							</header>
							<div class="dOwywj" spacing="comfortable">
								<div class="jrYImm">
									<router-view/>
								</div>
							</div>
						</div>
					</div>
					<sidebar v-if="appModel.enableSidebar"></sidebar>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import MainLeftMenu from '@/components/MainLeftMenu.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Sidebar from '@/components/Sidebar.vue';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Tasker'});
	},
	data: () => ({
		
	}),
	computed: {},
	methods: {
		visibleItems(arr){
			return arr.length > 0 ? arr.filter(i => i.visible) : [];
		},
	},
	mounted(){
		this.appModel.enableMainLeftMenu = true;
		this.appModel.titleMainLeftMenu.logo = '<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="32" height="32" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" viewBox="0 0 1224 1637" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path fill="#fff" d="M681 169c7,-50 3,-130 -16,-169 -14,31 -13,52 -38,98 -142,266 -572,436 -606,787 -5,56 5,127 18,166 24,73 23,72 58,147 -29,-14 -75,-32 -97,-11 63,30 102,76 150,121 101,95 214,179 356,191 54,4 99,6 151,-13 6,-2 12,-5 18,-7 -13,38 -20,52 -15,89 2,19 15,53 35,69 10,-29 18,-60 34,-87 14,-24 31,-42 49,-63 36,-37 95,-77 136,-110 73,-62 143,-112 174,-208 20,-58 20,-115 -5,-167 -4,-10 -6,-12 -6,-23 43,24 94,63 147,53 -63,-52 -79,-118 -104,-191 -78,-231 -155,-198 -172,-228 52,16 99,21 140,-19 -45,-5 -92,-23 -139,-36 -123,-35 -259,-45 -368,6 -155,73 -251,272 -204,436 29,-75 26,-123 107,-196 39,-35 113,-80 165,-79 -8,0 -37,18 -48,25 -54,35 -98,84 -117,149 8,-4 11,-7 18,-12l52 -37c31,-15 52,-22 86,-28 55,-11 128,1 172,37 -61,-22 -161,-24 -197,15 15,6 33,4 50,7 168,30 165,180 139,208 -10,-27 -18,-85 -69,-102 22,39 26,147 7,164 -26,-6 -31,-44 -38,-65 -13,42 -8,80 -2,93 -22,24 -55,21 -137,-23 8,27 41,55 65,78 -20,5 -28,7 -54,-5 -16,-7 -33,-20 -47,-32 -29,-25 -43,-47 -59,-73 -32,74 1,144 2,177 -113,-73 -209,-227 -205,-365 4,-129 42,-219 127,-315l131 -148c73,-88 142,-190 156,-304z"/></g></svg>';
		this.appModel.titleMainLeftMenu.link_type = 'router-link';
		this.appModel.titleMainLeftMenu.onclick = () => {};
		this.appModel.titleMainLeftMenu.href = {path: '/'};
		this.appModel.titleMainLeftMenu.tooltip = 'byLex Platform';
		this.appModel.mainLeftMenu = [
			{
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z" fill="currentColor" fill-rule="evenodd"></path></svg>',
				},
				link_type: 'a', // router-link, a, span
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'xxx',
				class: '',
				visible: true,
			},
			{
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M5 15h14v2H5zm0-8h14v2H5zm0 4h14v2H5z" fill="currentColor" fill-rule="evenodd"></path></svg>',
				},
				link_type: 'a', // router-link, a, span
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'xxx',
				class: '',
				visible: true,
			},
			{
				icon: {
					type: 'svg', // icon, img, svg
					icon: '<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M13 11V3.993A.997.997 0 0 0 12 3c-.556 0-1 .445-1 .993V11H3.993A.997.997 0 0 0 3 12c0 .557.445 1 .993 1H11v7.007c0 .548.448.993 1 .993.556 0 1-.445 1-.993V13h7.007A.997.997 0 0 0 21 12c0-.556-.445-1-.993-1H13z" fill="currentColor" fill-rule="evenodd"></path></svg>',
				},
				link_type: 'a', // router-link, a, span
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'xxx',
				class: '',
				visible: true,
			},
			{
				icon: {
					type: 'icon', // icon, img, svg
					icon: 'fa fa-envelope',
				},
				link_type: 'a', // router-link, a, span
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'Форум',
				class: '',
				visible: true,
			},
			{
				icon: {
					type: 'img', // icon, img, svg
					icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAyAAAAMgAEB7AknAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAKnSURBVHja7JhdSFNhHMZ/Ro42RafOdVZObeTHVMqhRankKEuURWAEhdBVwfCmoBUIFbvrot0YYV5IF4okREElq4hGhRPSVX5QWtlUFF1LmRO/0Iu68rCDH7mo9OI8d+d9X97zO8//ff4vnIi8S49/som1hU0uGVAGlAFlQBlQBpQBN1Zb15rURiuoOpaGpcAgjs3OL+J6P8yLzlHc3gCFhjhqqooY8k1x0vEabbQCp71UfP5ngNpoBQ0XD6FRKyXjqm2RWAoMCPEq3N6OjXPwyoksNGolvYMT3LjfxafvMwAUGuI4mrsDIV4FgNsbIN/25P8D7tLFAlDn7BXhloDc3oDEaae9FE+fj0ZXPzVVRQCkCDF4HMcBJOW2laVTkpcsVsbT5+N6cxf+6YXwQjIwFgQgJ1n919xwVOZy+kiG5NjkZwrcsR4M30FX9yhmk57zlhyKcnR8Gw2KwVhNbm+AcvvzFUNyZn8SZpN+2ZFxVOZiNumxlaXjePpl/YDOHj/B2las5UaMqQkYUxPENL/6MIytqTMs9/LSNAASOABbUydvjAK7d8aG32bc3gDu222iAxlJsRzI0mE26alTRmKtX3+Kl850w+XDK84nqlXhA4bqXvsItI/Aw488u1pCfqaANlqxsY36d9JERTI+s7iutQNjQVKEGM7edElK/MdX3QNbMfaKbLK2Ry1LokatZHxybsUX+acXmJ1fJFGtlDj87us4ANWn9lJoiJO0KXtFNnXn9oXnYKJaiaXAILnmQnXrUfeqX/1jco4UIQanvVTSB4v36MjPFMReGSpPny88B6vvvqWlzcvs/OKyjS7UtuLs8a8KeK3RQ+/gRAjwLADW+g6aX35myDclWd/S5qXR1b/iXhHyrw8ZUAaUAWVAGVAGlAHX0q8BAFFC80NTjcTYAAAAAElFTkSuQmCC',
				},
				link_type: 'a', // router-link, a, span
				onclick: () => {},
				href: '#',
				target_blank: false,
				tooltip: 'Форум',
				class: '',
				visible: true,
			},
		];
	},
	components: {
		MainLeftMenu,
		Breadcrumbs,
		Sidebar,
	},
}
</script>
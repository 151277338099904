<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal fade show" content-class="modal-dialog modal-lg" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onAdd(close)">
			<div class="modal-header py-2">
				<h1 class="modal-title fs-5">Добавление типа задач</h1>
				<button type="button" class="btn-close" @click="$emit('cancel', close)" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="text-center" v-if="loading">
					<loading-spinner
						:overlay="loading"
						text="ЗАГРУЗКА ДАННЫХ..."
					></loading-spinner>
				</div>
				<div v-else>
					<div class="mb-3">
						<label for="input-pos" class="form-label">Позиция:</label>
						<input id="input-pos" ref="pos" type="number" class="form-control" v-model.number="pos" />
					</div>
					<div class="mb-3">
						<label for="input-name" class="form-label">Наименование:</label>
						<input id="input-name" ref="name" type="text" placeholder="Например: Тип задачи" class="form-control" v-model="name" required />
					</div>
					<div class="mb-3">
						<div class="form-check form-switch">
							<input id="checkbox-is-default" ref="is_default" type="checkbox" class="form-check-input" v-model="is_default" />
							<label for="checkbox-is-default" class="form-check-label">Тип задач по умолчанию</label>
						</div>
					</div>
					<div class="mb-3">
						<label for="input-default-title" class="form-label">Заголовок задач по умолчанию:</label>
						<input id="input-default-title" ref="default_title" type="text" class="form-control" v-model="default_title" />
					</div>
					<div class="mb-3">
						<label for="input-default-description" class="form-label">Описание задач по умолчанию:</label>
						<textarea id="input-default-description" ref="default_description" rows="3" class="form-control" v-model="default_description"></textarea>
					</div>
					<div class="mb-3">
						<div class="form-check form-switch">
							<input id="checkbox-required-user-assigned" ref="required_user_assigned" type="checkbox" class="form-check-input" v-model="required_user_assigned" />
							<label for="checkbox-required-user-assigned" class="form-check-label">Назначенный пользователь обязателен для заполнения</label>
						</div>
					</div>
					<div class="mb-3">
						<div class="form-check form-switch">
							<input id="checkbox-required-estimate" ref="required_estimate" type="checkbox" class="form-check-input" v-model="required_estimate" />
							<label for="checkbox-required-estimate" class="form-check-label">Оценка времени обязательна для заполнения</label>
						</div>
					</div>
					<div class="mb-3">
						<div class="form-check form-switch">
							<input id="checkbox-required-date-deadline" ref="required_date_deadline" type="checkbox" class="form-check-input" v-model="required_date_deadline" />
							<label for="checkbox-required-date-deadline" class="form-check-label">Дата дедлайна обязательна для заполнения</label>
						</div>
					</div>
				</div>
				<div class="alert alert-warning alert-dismissible fade show mt-2 mb-0" v-if="error">
					{{error}}
					<button type="button" class="btn-close" aria-label="Close" @click="error = ''"></button>
				</div>
			</div>
			<div class="modal-footer p-1">
				<button type="submit" class="btn btn-primary" :disabled="loading">Добавить</button>
				<button type="button" class="btn btn-light" @click="$emit('cancel', close)">Отмена</button>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';
import autosize from 'autosize';

import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		pos: 0,
		name: '',
		is_default: 0,
		default_title: '',
		default_description: '',
		required_user_assigned: 0,
		required_estimate: 0,
		required_date_deadline: 0,
		
		error: '',
		loading: false,
	}),
	methods: {
		async onAdd(close){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doAddTasksType(this.$vfm.modals[0].params._value.virtualspace_id, {
				pos: this.pos,
				name: this.name,
				is_default: this.is_default,
				default_title: this.default_title,
				default_description: this.default_description,
				required_user_assigned: this.required_user_assigned,
				required_estimate: this.required_estimate,
				required_date_deadline: this.required_date_deadline,
			});
			if(ret.success){
				this.$emit('add', close);
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
	},
	async mounted(){
		setTimeout(() => {
			this.$refs.name.focus();
			
			autosize(this.$refs.default_description);
		}, 300);
	},
	components: {
		LoadingSpinner,
	},
}
</script>
<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal fade show" content-class="modal-dialog modal-xl" :click-to-close="false">
		<form class="modal-content" role="form" @submit.prevent="onAdd(close)">
			<div class="modal-header py-2">
				<h1 class="modal-title fs-5">Новая задача</h1>
				<button type="button" class="btn-close" @click="$emit('cancel', close)" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="text-center" v-if="loading">
					<loading-spinner
						:overlay="loading"
						text="ЗАГРУЗКА ДАННЫХ..."
					></loading-spinner>
				</div>
				<div v-else>
					<div class="row">
						<div class="col-8">
							<div class="mb-3">
								<textarea ref="title" placeholder="Заголовок задачи" class="form-control" v-model="title" rows="1" required></textarea>
							</div>
							<div class="mb-3">
								<div class="card">
									<div class="card-header bg-white">
										<ul class="nav nav-tabs card-header-tabs">
											<li class="nav-item">
												<a class="nav-link" :class="{'active': tab1 == 'description'}" @click="tab1 = 'description'" href="javascript://">Описание</a>
											</li>
											<li class="nav-item">
												<a class="nav-link" :class="{'active': tab1 == 'solutions'}" @click="tab1 = 'solutions'" href="javascript://">
													<span>Пути решения</span>
													<i class="far ms-1" :class="{'fa-circle': !solutions, 'fa-check-circle': solutions}"></i>
												</a>
											</li>
											<li class="nav-item">
												<a class="nav-link" :class="{'active': tab1 == 'result_plan'}" @click="tab1 = 'result_plan'" href="javascript://">
													<span>Результат по плану</span>
													<i class="far ms-1" :class="{'fa-circle': !result_plan, 'fa-check-circle': result_plan}"></i>
												</a>
											</li>
											<li class="nav-item">
												<a class="nav-link" :class="{'active': tab1 == 'result_fact'}" @click="tab1 = 'result_fact'" href="javascript://">
													<span>Результат по факту</span>
													<i class="far ms-1" :class="{'fa-circle': !result_fact, 'fa-check-circle': result_fact}"></i>
												</a>
											</li>
										</ul>
									</div>
									<div class="card-body" v-show="tab1 == 'description'">
										<textarea ref="description" rows="6" class="form-control" v-model="description"></textarea>
									</div>
									<div class="card-body" v-show="tab1 == 'solutions'">
										<textarea ref="solutions" rows="6" class="form-control" v-model="solutions"></textarea>
									</div>
									<div class="card-body" v-show="tab1 == 'result_plan'">
										<textarea ref="result_plan" rows="6" class="form-control" v-model="result_plan"></textarea>
									</div>
									<div class="card-body" v-show="tab1 == 'result_fact'">
										<textarea ref="result_fact" rows="6" class="form-control" v-model="result_fact"></textarea>
									</div>
								</div>
							</div>
							<div class="mb-3" v-for="cf in custom_fields" :key="cf.id">
								<div class="input-group" v-if="cf.type == 'text'">
									<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
									<input type="text" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values[cf.id]" />
								</div>
								<div class="input-group" v-if="cf.type == 'textarea'">
									<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
									<textarea :id="'item-custom-field-'+cf.id" rows="3" class="form-control" v-model="custom_fields_values[cf.id]"></textarea>
								</div>
								<div class="input-group" v-if="cf.type == 'checkbox'">
									<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
									<input type="checkbox" :id="'item-custom-field-'+cf.id" class="form-check-input" v-model="custom_fields_values[cf.id]" />
								</div>
								<div v-if="cf.type == 'number'">
									<!-- cf.data.decimal_places -->
									<div class="input-group">
										<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
										<input type="number" :id="'item-custom-field-'+cf.id" class="form-control" v-model.number="custom_fields_values[cf.id]" />
									</div>
								</div>
								<div v-if="cf.type == 'currency'">
									<!-- cf.data.decimal_places -->
									<div class="input-group">
										<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
										<span class="input-group-text">{{cf.data.symbol}}</span>
										<input type="number" :id="'item-custom-field-'+cf.id" class="form-control" v-model.number="custom_fields_values[cf.id]" />
									</div>
								</div>
								<div v-if="cf.type == 'percentage'">
									<!-- data.decimal_places -->
									<div class="input-group">
										<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
										<input type="number" :id="'item-custom-field-'+cf.id" class="form-control" v-model.number="custom_fields_values[cf.id]" />
									</div>
								</div>
								<div class="input-group" v-if="cf.type == 'date'">
									<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
									<input type="date" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values[cf.id]" />
								</div>
								<div class="input-group" v-if="cf.type == 'dropdown'">
									<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
									<select :id="'item-custom-field-'+cf.id" class="form-select" v-model="custom_fields_values[cf.id]">
										<option :value="option.text" :style="{backgroundColor: '#'+option.color}" v-for="option in sortedByPos(cf.data)">{{option.text}}</option>
									</select>
								</div>
								<div class="input-group" v-if="cf.type == 'multiselect'">
									<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
									<select :id="'item-custom-field-'+cf.id" multiple class="form-select" v-model="custom_fields_values[cf.id]">
										<option :value="option.text" :style="{backgroundColor: '#'+option.color}" v-for="option in sortedByPos(cf.data)">{{option.text}}</option>
									</select>
								</div>
								<div class="input-group" v-if="cf.type == 'rating'">
									<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}} ({{custom_fields_values[cf.id]}}):</label>
									<input type="range" :id="'item-custom-field-'+cf.id" min="0" :max="cf.data.scale" class="form-control form-range" v-model.number="custom_fields_values[cf.id]" />
								</div>
								<div class="input-group" v-if="cf.type == 'email'">
									<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
									<input type="email" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values[cf.id]" />
								</div>
								<div class="input-group" v-if="cf.type == 'phone'">
									<div class="input-group">
										<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
										<input type="text" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values[cf.id]" />
									</div>
								</div>
								<div class="input-group" v-if="cf.type == 'link'">
									<div class="input-group">
										<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
										<input type="text" :id="'item-custom-field-'+cf.id" class="form-control" v-model="custom_fields_values[cf.id]" />
									</div>
								</div>
								<div v-if="cf.type == 'user'">
									<div class="input-group">
										<label :for="'item-custom-field-'+cf.id" class="input-group-text">{{cf.name}}:</label>
										<input type="number" :id="'item-custom-field-'+cf.id" class="form-control" v-model.number="custom_fields_values[cf.id]" />
									</div>
								</div>
							</div>
							<div class="mb-3">
								<div class="card">
									<div class="card-header">
										<div class="float-end" v-if="checklistEnable">
											<div class="form-check form-switch m-0 p-0">
												<input class="form-check-input ms-0" type="checkbox" role="switch" content="Скрывать отмеченные пункты" v-tippy="{placement: 'left'}" v-model="checklistHideChecked" />
											</div>
										</div>
										<span>Чек-лист</span>
										<small class="text-dark-gray ms-1" v-if="checklistEnable"> ({{checklist_checked.length}} из {{checklist.length}})</small>
									</div>
									<draggable class="list-group list-group-flush" :list="checklist" handle=".handle">
										<div class="list-group-item handle" :class="{'py-1': cli.edit}" v-for="cli in checklist_filtered" :key="cli.id">
											<div class="row align-items-center">
												<div class="col-1">
													<input :id="'checklist_item_'+cli.id" type="checkbox" class="form-check-input" v-model="cli.checked" />
												</div>
												<div :class="{'col-9': !cli.edit, 'col-11': cli.edit, 'px-0': cli.edit}">
													<label :for="'checklist_item_'+cli.id" v-if="!cli.edit">
														<span v-if="!cli.checked">{{cli.name}}</span>
														<s v-if="cli.checked">{{cli.name}}</s>
													</label>
													<form v-if="cli.edit" @submit.prevent="onEditChecklistItem(cli.id)">
														<div class="input-group">
															<input class="form-control" v-model="cli.edit_name" required />
															<button class="btn btn-primary" type="submit" content="Сохранить изменения" v-tippy="{placement: 'top'}">
																<i class="fa fa-save"></i>
															</button>
															<button class="btn btn-secondary" type="button" @click="cli.edit = false" content="Отменить изменения" v-tippy="{placement: 'top'}">
																<i class="fa fa-times"></i>
															</button>
														</div>
													</form>
												</div>
												<div class="col-2 text-end" v-if="!cli.edit">
													<div class="btn-group btn-group-sm">
														<button type="button" class="btn btn-primary" content="Изменить пункт" v-tippy="{placement: 'top'}" @click="cli.edit_name = cli.name; cli.edit = true;">
															<i class="fa fa-edit"></i>
														</button>
														<button type="button" class="btn btn-danger" content="Удалить пункт" v-tippy="{placement: 'top'}" @click="onDelChecklistItem(cli.id)">
															<i class="fa fa-trash"></i>
														</button>
													</div>
												</div>
											</div>
										</div>
										<li class="list-group-item">
											<form @submit.prevent="onAddChecklistItem()">
												<div class="row align-items-center">
													<div class="col-1">
														<i class="fa fa-plus"></i>
													</div>
													<div class="col-11">
														<div class="input-group input-group-sm">
															<input type="text" class="form-control" placeholder="Название пункта" v-model="checklist_add_name" required />
															<button class="btn btn-primary" type="submit" content="Добавить пункт" v-tippy="{placement: 'top'}">
																<i class="fa fa-plus"></i>
															</button>
														</div>
													</div>
												</div>
											</form>
										</li>
									</draggable>
								</div>
							</div>
						</div>
						<div class="col-4">
							<div class="mb-3">
								<label for="select-project-id" class="form-label">Проект, в котором выполняется:</label>
								<select id="select-project-id" ref="project_id" class="form-select" v-model="project_id" required>
									<option :value="p.id" :key="p.id" v-for="p in projects">{{p.name}}</option>
								</select>
							</div>
							<div class="mb-3">
								<label for="select-for-project-id" class="form-label">Проект, для выполнения которого создана:</label>
								<select id="select-for-project-id" ref="for_project_id" class="form-select" v-model="for_project_id" required>
									<option :value="p.id" :key="p.id" v-for="p in projects">{{p.name}}</option>
								</select>
							</div>
							<div class="input-group mb-3">
								<span class="input-group-text">Тип:</span>
								<input type="text" class="form-control" v-model="type_name" disabled>
							</div>
							<div class="input-group mb-3">
								<span class="input-group-text">Статус:</span>
								<input :style="{backgroundColor: status_type_color}" type="text" class="form-control text-white" v-model="status_name" disabled>
							</div>
							<div class="input-group mb-3">
								<label for="select-priority-id" class="input-group-text">Приоритет:</label>
								<select id="select-priority-id" ref="priority_id" class="form-select text-white" :style="{backgroundColor: priority_color}" v-model="priority_id" required>
									<option class="text-white" :style="{backgroundColor: p.color}" :value="p.id" :key="p.id" v-for="p in priorities">{{p.weight}}: {{p.name}}</option>
								</select>
							</div>
							<div class="input-group mb-3">
								<label for="input-estimate" class="input-group-text">Оценка времени в часах:</label>
								<input id="input-estimate" ref="estimate" type="number" min="0" class="form-control" required v-model.number="estimate" />
							</div>
							<div class="mb-3" v-if="!checklistEnable">
								<label for="input-progress" class="form-label">Прогресс выполнения ({{progress}}%):</label>
								<input id="input-progress" ref="progress" type="range" min="0" max="100" step="5" class="form-range" v-model.number="progress" />
							</div>
							<div class="mb-3" v-if="checklistEnable">
								<label class="form-label">Прогресс выполнения ({{checklist_progress}}%):</label>
								<div class="progress">
									<div class="progress-bar" role="progressbar" aria-label="Task progress" :style="{width: checklist_progress+'%'}" :aria-valuenow="checklist_progress" aria-valuemin="0" aria-valuemax="100"></div>
								</div>
							</div>
							<div class="mb-3">
								<label class="form-label">Даты по плану (начало / дедлайн):</label>
								<div class="input-group">
									<input ref="date_start_plan" type="date" class="form-control" v-model="date_start_plan" />
									<input ref="date_end_plan" type="date" class="form-control" v-model="date_end_plan" />
								</div>
							</div>
							<div class="mb-3">
								<label class="form-label">Даты по факту (начато / завершено):</label>
								<div class="input-group">
									<input ref="date_start_fact" type="date" class="form-control" v-model="date_start_fact" />
									<input ref="date_end_fact" type="date" class="form-control" v-model="date_end_fact" />
								</div>
							</div>
							<div class="mb-3">
								<div class="card">
									<div class="input-group">
										<label for="user_assigned_add_id" class="input-group-text">Исполнители ({{users_assigned.length}}):</label>
										<select id="user_assigned_add_id" ref="user_assigned_add_id" class="form-select" v-model="user_assigned_add_id" :disabled="users_not_assigned.length <= 0">
											<option value=""></option>
											<option :value="user.id" v-for="user in users_not_assigned" :key="user.id">{{user.fname}} {{user.sname}} @{{user.login}}</option>
										</select>
									</div>
									<ul class="list-group list-group-flush">
										<li class="list-group-item" v-if="users_assigned.length <= 0">Не указаны</li>
										<li class="list-group-item d-flex align-items-center" v-for="user in users_assigned" :key="user.id">
											<div class="d-flex align-items-center w-100 small multi-user">
												<img :src="user.avatar" class="avatar avatar-xs rounded-circle me-2" />
												<span>{{user.fname}} {{user.sname}} @{{user.login}}</span>
											</div>
											<div class="flex-shrink-1" style="margin-top: -4px; margin-right: -12px; margin-bottom: -4px;">
												<button type="button" class="btn btn-sm btn-danger" content="Убрать исполнителя" v-tippy="{placement: 'top'}" @click="onDelUserAssigned(user.id)">
													<i class="fa fa-times"></i>
												</button>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<div class="mb-3" v-if="tags_not_added.length > 0">
								<div class="card">
									<div class="input-group">
										<label for="tag_add_id" class="input-group-text">Теги ({{task_tags.length}}):</label>
										<select id="tag_add_id" ref="tag_add_id" class="form-select" v-model="tag_add_id" :disabled="tags_not_added.length <= 0">
											<option value=""></option>
											<option :value="tag.id" v-for="tag in tags_not_added" :key="tag.id">{{tag.name}}</option>
										</select>
									</div>
									<ul class="list-group list-group-flush">
										<li class="list-group-item" v-if="task_tags.length <= 0">Не добавлены</li>
										<li class="list-group-item d-flex align-items-center" v-for="tag in task_tags" :key="tag.id">
											<div class="w-100">
												<i class="fa fa-hashtag me-2"></i>
												<span>{{tag.name}}</span>
											</div>
											<div class="flex-shrink-1" style="margin-top: -4px; margin-right: -12px; margin-bottom: -4px;">
												<button type="button" class="btn btn-sm btn-danger" content="Убрать тег" v-tippy="{placement: 'top'}" @click="onDelTag(tag.id)">
													<i class="fa fa-times"></i>
												</button>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<div class="mb-3"  v-if="targets_not_added.length > 0">
								<div class="card">
									<div class="input-group">
										<label for="target_add_id" class="input-group-text">Цели ({{task_targets.length}}):</label>
										<select id="target_add_id" ref="target_add_id" class="form-select" v-model="target_add_id" :disabled="targets_not_added.length <= 0">
											<option value=""></option>
											<option :value="target.id" v-for="target in targets_not_added" :key="target.id">{{target.name}}</option>
										</select>
									</div>
									<ul class="list-group list-group-flush">
										<li class="list-group-item" v-if="task_targets.length <= 0">Не добавлены</li>
										<li class="list-group-item d-flex align-items-center" v-for="target in task_targets" :key="target.id">
											<div class="w-100">
												<i class="fa fa-crosshairs me-2"></i>
												<span>{{target.name}}</span>
											</div>
											<div class="flex-shrink-1" style="margin-top: -4px; margin-right: -12px; margin-bottom: -4px;">
												<button type="button" class="btn btn-sm btn-danger" content="Убрать цель" v-tippy="{placement: 'top'}" @click="onDelTarget(target.id)">
													<i class="fa fa-times"></i>
												</button>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="alert alert-warning alert-dismissible fade show mt-2 mb-0" v-if="error">
					{{error}}
					<button type="button" class="btn-close" aria-label="Close" @click="error = ''"></button>
				</div>
			</div>
			<div class="modal-footer p-1">
				<button type="submit" class="btn btn-primary" :disabled="loading || loading2">Добавить</button>
				<button type="button" class="btn btn-light" @click="$emit('cancel', close)">Отмена</button>
			</div>
		</form>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';
import autosize from 'autosize';
import moment from 'moment-timezone';
import { VueDraggableNext } from 'vue-draggable-next';

import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
	mixins: lib.mixins,
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
	data: () => ({
		tab1: 'description',
		
		checklistHideChecked: false,
		
		id: '',
		parent_id: '',
		project_id: '',
		for_project_id: '',
		pos: '',
		title: '',
		description: '',
		solutions: '',
		result_plan: '',
		result_fact: '',
		type_id: '',
		type_name: '',
		status_id: '',
		status_name: '',
		status_type_color: 'inherit',
		priority_id: '',
		estimate: 0,
		progress: 0,
		date_start_plan: '',
		date_start_fact: '',
		date_end_plan: '',
		date_end_fact: '',
		
		users_assigned: [],
		user_assigned_add_id: '',
		
		task_targets: [],
		target_add_id: '',
		
		task_tags: [],
		tag_add_id: '',
		
		checklist: [],
		checklist_add_name: '',
		
		projects: [],
		users: [],
		priorities: [],
		custom_fields: [],
		custom_fields_values: {},
		tags: [],
		targets: [],
		
		error: '',
		loading: false,
		loading2: false,
	}),
	computed: {
		users_not_assigned(){
			return this.users.filter((el) => !this.users_assigned.map(el2 => el2.user_id).includes(el.id));
		},
		tags_not_added(){
			return this.tags.filter((el) => !this.task_tags.map(el2 => el2.tag_id).includes(el.id));
		},
		targets_not_added(){
			return this.targets.filter((el) => !this.task_targets.map(el2 => el2.target_id).includes(el.id));
		},
		checklist_progress(){
			return this.checklist.length > 0 ? Math.floor((this.checklist.filter((el) => el.checked).length / this.checklist.length) * 100) : 0;
		},
		checklist_filtered(){
			return this.checklistHideChecked ? this.checklist.filter((el) => !el.checked) : this.checklist;
		},
		checklist_checked(){
			return this.checklist.filter((el) => el.checked);
		},
		checklist_not_checked(){
			return this.checklist.filter((el) => !el.checked);
		},
		checklistEnable(){
			return this.checklist.length > 0;
		},
		priority_color(){
			return this.priority_id && this.priorities.length > 0 ? this.priorities.find((el) => el.id == this.priority_id).color || 'inherit' : 'inherit';
		},
	},
	watch: {
		async user_assigned_add_id(val, oldVal){
			if(val != ''){
				// добавление исполнителя
				let item = this.users.find((el) => el.id == val);
				this.users_assigned.push({
					id: val,
					user_id: val,
					sname: item.sname,
					fname: item.fname,
					lname: item.lname,
					login: item.login,
					avatar: item.avatar,
				});
				this.user_assigned_add_id = '';
			}
		},
		async tag_add_id(val, oldVal){
			if(val != ''){
				// добавление тега
				let item = this.tags.find((el) => el.id == val);
				this.task_tags.push({
					id: val,
					tag_id: val,
					name: item.name,
				});
				this.tag_add_id = '';
			}
		},
		async target_add_id(val, oldVal){
			if(val != ''){
				// добавление цели
				let item = this.targets.find((el) => el.id == val);
				this.task_targets.push({
					id: val,
					target_id: val,
					name: item.name,
				});
				this.target_add_id = '';
			}
		},
	},
	methods: {
		contains: lib.contains,
		
		sortedByPos(arr){
			return arr.sort((a, b) => {
				return a.pos > b.pos ? 1 : (a.pos < b.pos ? -1 : 0);
			});
		},
		
		async loadProjects(){
			this.error = '';
			let ret = await this.appModel.doGetProjects(this.$vfm.modals[0].params._value.virtualspace_id);
			if(ret.success){
				this.projects = ret.list;
			} else {
				this.error = ret.message;
			}
		},
		async loadPriorities(){
			this.error = '';
			let ret = await this.appModel.doGetTasksPriorities(this.$vfm.modals[0].params._value.virtualspace_id);
			if(ret.success){
				this.priorities = ret.list;
			} else {
				this.error = ret.message;
			}
		},
		async loadCustomFields(){
			this.error = '';
			let ret = await this.appModel.doGetTasksCustomFieldsByTasksType(this.$vfm.modals[0].params._value.virtualspace_id, this.type_id);
			if(ret.success){
				this.custom_fields = ret.list.map((cf) => {
					cf.data = JSON.parse(cf.data||'[]');
					return cf;
				});
			} else {
				this.error = ret.message;
			}
		},
		async loadUsers(){
			this.error = '';
			let ret = await this.appModel.doGetUsers(this.$vfm.modals[0].params._value.virtualspace_id);
			if(ret.success){
				this.users = ret.list.map((el) => {
					el.id = el.user_id;
					delete el.user_id;
					return el;
				});
			} else {
				this.error = ret.message;
			}
		},
		async loadTags(){
			this.error = '';
			let ret = await this.appModel.doGetTags(this.$vfm.modals[0].params._value.virtualspace_id);
			if(ret.success){
				this.tags = ret.list;
			} else {
				this.error = ret.message;
			}
		},
		async loadTargets(){
			this.error = '';
			let ret = await this.appModel.doGetTargets(this.$vfm.modals[0].params._value.virtualspace_id);
			if(ret.success){
				this.targets = ret.list;
			} else {
				this.error = ret.message;
			}
		},
		
		// добавление пункта чек-листа
		async onAddChecklistItem(){
			this.checklist.push({
				id: lib.genId(),
				name: this.checklist_add_name,
				checked: false,
			});
			this.checklist_add_name = '';
		},
		// изменение названия пункта чек-листа
		async onEditChecklistItem(checklist_item_id){
			let item = this.checklist.find((el) => el.id == checklist_item_id);
			item.name = item.edit_name;
			item.edit = false;
		},
		// удаление пункта чек-листа
		async onDelChecklistItem(checklist_item_id){
			if(confirm('Вы уверены?')){
				let index = this.checklist.findIndex((el) => el.id == checklist_item_id);
				this.checklist.splice(index, 1);
			}
		},
		
		// удаление исполнителя
		async onDelUserAssigned(task_user_id){
			let index = this.users_assigned.findIndex((el) => el.id == task_user_id);
			this.users_assigned.splice(index, 1);
		},
		
		// удаление тега
		async onDelTag(task_tag_id){
			let index = this.task_tags.findIndex((el) => el.id == task_tag_id);
			this.task_tags.splice(index, 1);
		},
		
		// удаление цели
		async onDelTarget(task_target_id){
			let index = this.task_targets.findIndex((el) => el.id == task_target_id);
			this.task_targets.splice(index, 1);
		},
		
		async onAdd(close){
			this.loading2 = true;
			this.error = '';
			let ret = await this.appModel.doAddTask(this.$vfm.modals[0].params._value.virtualspace_id, {
				//parent_id: this.parent_id,
				project_id: this.project_id,
				for_project_id: this.for_project_id,
				//pos: this.pos,
				title: this.title,
				description: this.description,
				solutions: this.solutions,
				result_plan: this.result_plan,
				result_fact: this.result_fact,
				type_id: this.type_id,
				status_id: this.status_id,
				priority_id: this.priority_id,
				estimate: this.estimate,
				progress: this.checklist.length > 0 ? this.checklist_progress : this.progress,
				date_start_plan: this.date_start_plan ? moment.tz(this.date_start_plan, 'Asia/Almaty').utc().format('YYYY-MM-DD') : '',
				date_start_fact: this.date_start_fact ? moment.tz(this.date_start_fact, 'Asia/Almaty').utc().format('YYYY-MM-DD') : '',
				date_end_plan: this.date_end_plan ? moment.tz(this.date_end_plan, 'Asia/Almaty').utc().format('YYYY-MM-DD') : '',
				date_end_fact: this.date_end_fact ? moment.tz(this.date_end_fact, 'Asia/Almaty').utc().format('YYYY-MM-DD') : '',
				custom_fields_values: Object.entries(this.custom_fields_values).map((el) => ({
					custom_field_id: el[0],
					value: el[1],
				})),
				checklist: this.checklist,
				users_assigned: this.users_assigned,
				tags: this.task_tags,
				targets: this.task_targets,
			});
			if(ret.success){
				this.$emit('add', close);
			} else {
				this.error = ret.message;
			}
			this.loading2 = false;
		},
		
		formatDate(date, format){
			return moment.utc(date).tz('Asia/Almaty').format(format);
		},
	},
	async mounted(){
		this.type_id = this.$vfm.modals[0].params._value.type_id;
		this.type_name = this.$vfm.modals[0].params._value.type_name;
		this.status_id = this.$vfm.modals[0].params._value.status_id;
		this.status_name = this.$vfm.modals[0].params._value.status_name;
		this.status_type_color = this.$vfm.modals[0].params._value.status_type_color;
		
		this.loading = true;
		await this.loadCustomFields();
		await this.loadProjects();
		await this.loadPriorities();
		await this.loadUsers();
		await this.loadTags();
		await this.loadTargets();
		this.loading = false;
		
		setTimeout(() => {
			let default_priority = this.priorities.find((el) => el.is_default);
			if(default_priority){
				this.priority_id = default_priority.id;
			}
			
			this.$refs.title.focus();
			
			autosize(this.$refs.title);
			autosize(this.$refs.description);
			autosize(this.$refs.solutions);
			autosize(this.$refs.result_plan);
			autosize(this.$refs.result_fact);
			autosize(this.$refs.comment);
		}, 300);
	},
	components: {
		LoadingSpinner,
		draggable: VueDraggableNext,
	},
}
</script>